import * as React from "react"

export enum Themes {
    "dark",
    "light"
}

interface IThemeContext {
    theme: Themes
    themeAsAGGridIdentifer
    toggleTheme: any
    changeColor: any
    accentColor: any
}

export const ThemeServiceContext = React.createContext<IThemeContext | undefined>(undefined)

export const useThemeServiceContext = () => {
    return React.useContext<IThemeContext | undefined>(undefined)
}

export const ThemeService = ({ children }: any) => {
    const [theme, setTheme] = React.useState<Themes>(Themes[localStorage.getItem("theme") ?? "dark"])
    const [accentColor, setAccentColor] = React.useState<any>(JSON.parse(localStorage.getItem("color")))
    const [themeAsAGGridIdentifer, setThemeAsAGGridIdentifier] = React.useState<string>(theme === Themes.dark ? "ag-theme-alpine-dark" : "ag-theme-alpine")

    const toggleTheme = () => {
        setTheme(theme === Themes.dark ? Themes.light : Themes.dark)
    }

    const changeColor = (color) => {
        setAccentColor(color.rgb)
        localStorage.setItem("color", JSON.stringify(color.rgb))
    }

    React.useEffect(() => {
        if (accentColor) {
            document.documentElement.style.setProperty("--color-theme-accent", `rgba(${accentColor?.r}, ${accentColor?.g}, ${accentColor?.b}, ${accentColor?.a})`)
            document.documentElement.style.setProperty("--color-theme-accent-rgb", `${accentColor?.r}, ${accentColor?.g}, ${accentColor?.b}`)
            document.documentElement.style.setProperty("--color-theme-accent-r", accentColor?.r)
            document.documentElement.style.setProperty("--color-theme-accent-g", accentColor?.g)
            document.documentElement.style.setProperty("--color-theme-accent-b", accentColor?.b)
            document.documentElement.style.setProperty("--color-theme-accent-a", accentColor?.a)
        }
    }, [accentColor])

    React.useEffect(() => {
        switch (theme) {
            case Themes.light:
                document.documentElement.setAttribute("data-theme", "light")
                localStorage.setItem("theme", "light")
                setThemeAsAGGridIdentifier("ag-theme-alpine")
                break
            case Themes.dark:
            default:
                document.documentElement.setAttribute("data-theme", "dark")
                localStorage.setItem("theme", "dark")
                setThemeAsAGGridIdentifier("ag-theme-alpine-dark")
                break
        }
    }, [theme])

    return (
        <ThemeServiceContext.Provider value={{ themeAsAGGridIdentifer: themeAsAGGridIdentifer, theme: theme, toggleTheme: toggleTheme, changeColor: changeColor, accentColor: accentColor }}>
            {children}
        </ThemeServiceContext.Provider>
    )
}