import * as React from "react"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../../app/hooks"
import { TextField, TextFieldType } from "../../../elements/TextField"
import { ProfileServiceContext } from "./reducer/ProfileService"

export const ChangePasswordFragment = () => {
    const profileService = React.useContext(ProfileServiceContext)
    const profileState = useAppSelector((state) => state.profileState)

    const [newPassword, setNewPassword] = useState("")
    const [oldPassword, setOldPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [hasInputErrorOldPassword, setHasInputErrorOldPassword] = useState(true)
    const [hasInputErrorNewPassword, setHasInputErrorNewPassword] = useState(true)
    const [hasInputConfirmPassword, setHasInputErrorConfirmPassword] = useState(true)
    const [hasPasswordMatchError, setHasPasswordMatchError] = useState(true)

    const [hasInputError, setHasInputError] = useState(true)

    React.useMemo(() => {
        setHasPasswordMatchError(newPassword !== confirmPassword)
        setHasInputError(hasInputErrorNewPassword || hasInputConfirmPassword || hasPasswordMatchError || (hasInputErrorOldPassword && profileState.hasPassword))
    }, [hasPasswordMatchError, hasInputErrorNewPassword, hasInputConfirmPassword, hasPasswordMatchError, hasInputErrorOldPassword, newPassword])

    const onSave = () => {
        if (hasPasswordMatchError) {
            return
        }
        profileService.changePassword(oldPassword, newPassword, confirmPassword)
    }
    const { t } = useTranslation()
    return (
        <div>
            <If condition={!profileState.hasPassword}>
                <h3>{t("PROFILE_SetPassword")}</h3>
            </If>
            <If condition={profileState.hasPassword}>
                <h3>{t("PROFILE_ChangePassword")}</h3>
            </If>
            <div className="form-floating">
                <TextField label={t("PROFILE_OldPassword")} onChange={setOldPassword} type={TextFieldType.Password} callback={setHasInputErrorOldPassword} value={oldPassword} disabled={!profileState.hasPassword} submitCallback={onSave } />
            </div>
            <div className="form-floating">
                <TextField label={t("PROFILE_NewPassword")} onChange={setNewPassword} type={TextFieldType.Password} callback={setHasInputErrorNewPassword} value={newPassword} submitCallback={onSave} />
            </div>
            <div className="form-floating">
                <TextField label={t("PROFILE_ConfirmPassword")} onChange={setConfirmPassword} type={TextFieldType.Password} callback={setHasInputErrorConfirmPassword} value={confirmPassword} submitCallback={onSave} />
            </div>
            <If condition={hasPasswordMatchError}>
                <span className="text-danger">{t("errors:SPA_VALIDATION_PasswordsDoNotMatch")}</span>
            </If>
            <Button variant="primary" onClick={onSave} disabled={hasInputError} >{t("GENERIC_Ok")}</Button>
        </div>
    )
}