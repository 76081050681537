import * as React from "react"
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { SwipeEventData, useSwipeable } from "react-swipeable"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { toggleNotificationCenter } from "../../app/reducer/appStateReducer"
import { NotificationCenterEntry } from "./reducer/notficationCenterReducer"
import { PartnerlistNotificationPayload } from "../../@types/PartnerlistNotificationPayload"
import { PartnerlistNotificationType } from "../../@types/PartnerlistNotificationType"
import { PartnerlistItemModel } from "../../@types/PartnerlistItemModel"
import { PartnerlistServiceContext } from "../partnerlist/reducer/PartnerlistService"
import { PersonCheck } from "react-bootstrap-icons"

export const NotificationCenter = () => {
    const notificationCenterWidth = 400
    const { t } = useTranslation()
    const dispatch = useAppDispatch() as any
    const partnerlistService = React.useContext(PartnerlistServiceContext)
    const isNotificationCenterShown = useAppSelector((state) => state.appState.isNotificationCenterShown)
    const notifications = useAppSelector((state) => state.notificationCenterState.notifications)
    const closeCanvas = () => {
        dispatch(toggleNotificationCenter())
    }

    const { ref: handlersNotificationCenter } = useSwipeable({
        onSwipedLeft: (eventData: SwipeEventData) => {
            if (!isNotificationCenterShown && eventData.initial[0] > window.innerWidth - notificationCenterWidth) {
                dispatch(toggleNotificationCenter())
            }
        },
        onSwipedRight: (eventData: SwipeEventData) => {
            if (isNotificationCenterShown && eventData.initial[0] > window.innerWidth - notificationCenterWidth) {
                dispatch(toggleNotificationCenter())
            }
        }
    })

    // attach swipeable to document
    React.useEffect(() => {
        handlersNotificationCenter(document as any)
    })

    const confirmPartner = (model: PartnerlistItemModel) => {
        partnerlistService.confirmPartner(model)
    }

    const buildFriendRequest = (payload: PartnerlistNotificationPayload) => {
        return (
            <div>
                <span onClick={() => confirmPartner(payload.partner)} className="pointer">{payload.partner.displayName} {t("NOTIFICATIONCENTER_FRIENDREQUEST")}</span>
                <PersonCheck onClick={() => confirmPartner(payload.partner)} color="var(--bs-light)" className="pointer" size="2rem" />
                <hr />
            </div>
        )
    }
    const buildNotificationBody = (notification: NotificationCenterEntry) => {
        switch (notification.typeName) {
            case "PartnerlistNotificationPayload": {
                const current = notification.payload as PartnerlistNotificationPayload
                switch (current.type) {
                    case PartnerlistNotificationType.AddPartnerlistPartnerRequestReceived: {
                        return buildFriendRequest(current)
                    }
                    case PartnerlistNotificationType.ReadPartnerlist: {
                        return buildFriendRequest(current)
                    }
                }
                break
            }
        }
        return null
    }

    const notificationCount = notifications.length

    const bodyElements = React.useMemo(() => {
        return notifications?.map((val) => buildNotificationBody(val))
    }, [notifications])

    return (
        <Offcanvas className="bg-dark" show={isNotificationCenterShown} onHide={closeCanvas} placement="end">
            <OffcanvasHeader closeButton>
                {t("GENERIC_Notifications")}: {notificationCount}
            </OffcanvasHeader>
            <OffcanvasBody>
                {bodyElements.map(elem => (
                    <div key={elem.key}>{elem}</div>
                ))}
            </OffcanvasBody>
        </Offcanvas>
    )
}