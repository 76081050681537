export const translationData = {
    de: {
        translations: {
            "TestKey": "TestWert",
            "EMAILSERVICE_HEADER_ConfirmEmail": "Bitte bestätige deine Email.",
            "EMAILSERVICE_HEADER_ResetPassword": "Passwort Reset: Suitamoi.com",
            "EMAILSERVICE_HEADER_ChangeEmail": "Email Änderung: Suitamoi.com",
            "EMAILSERVICE_BODY_ConfirmEmail": "Bitte bestätige deinen Account {0} indem du auf den Link klickst: {1}",
            "EMAILSERVICE_BODY_ResetPassword": "Bitte setze dein Password für den Account {0} zurück indem du auf den link klickst: {1}",
            "EMAILSERVICE_BODY_ChangeEmail": "Bitte ändere deine Email für den Account: {0} indem du den Link klickst: {1}",
            "HOME_Welcome": "Willkommen",
            "HOME_TechStack": "Technologien im Einsatz:",
            "HOME_TECHSTACK_BODY_1": "Jetzt mit Lila Kreis Power",
            "HOME_TECHSTACK_BODY_2": "WebApp auf Azure gehostet",
            "HOME_TECHSTACK_BODY_3": "Duende Identity Server + Anmelden mit Google",
            "HOME_TECHSTACK_BODY_4": "SQL Datenbank für Identity Server und Benutzer Daten",
            "HOME_TECHSTACK_BODY_5": "Single Page Anwendung basierend auf react-redux, typescript und es 6",
            "HOME_TECHSTACK_BODY_6": "Backend C# ASP.NET Core",
            "HOME_TECHSTACK_BODY_7": "Zustandslose Sitzungen ermöglichen dem Backend freie Skalierung",
            "HOME_TECHSTACK_BODY_8": "Mehre Sitzungen pro Nutzer möglich und synchronisiert durch autorisierte Web-Sockets",
            "HOME_TECHSTACK_BODY_9": "Tabellen mithilfe von AG Grid",
            "HOME_TECHSTACK_BODY_10": "Design mit Bootstrap realisiert",
            "HOME_TECHSTACK_BODY_11": "Deutsch und Englisch unterstützt",
            "HOME_TECHSTACK_BODY_12": "Clientseitige Sitzungsüberprüfung - sehr schnelles neu laden der Seite (F5)",
            "HOME_Features": "Funktionen",
            "HOME_FEATURES_BODY_1": "Ask me, Senpai: Gib ein paar Fragen ein und lass dich testen. Super toll!",
            "HOME_FEATURES_BODY_2": "Neu: Kategorien und direktes editieren für Ask me, Senpai",
            "HOME_FEATURES_BODY_3": "Neu: Zwei-Faktor Authentifizierung, Cookie Einwilligung, Datenschutz Funktionen, Benutzer Verwaltung",
            "HOME_FEATURES_BODY_4": "Neu: Power User Funktionen: Enter Taste zum absenden und 'Warnungen ignorieren'",
            "HOME_SecurityDataProtection": "Sicherheit und Datenschutz",
            "HOME_SECURITYDATAPROTECTION_BODY_1": "Azure Tresor zur sicheren Ablage von Konfigurations Geheimnissen",
            "HOME_SECURITYDATAPROTECTION_BODY_2": "HSTS",
            "HOME_SECURITYDATAPROTECTION_BODY_3": "Herunterladen aller persönlichen Daten und Accountlöschung",
            "HOME_Development": "Entwicklungsunterstützung, CI/CD, Automatisierung und Testing",
            "HOME_Upcomming": "Demnächst",
            "HOME_DEVELOPMENT_BODY_1": "Azure DevOps zur Verwaltung und zum Release Management",
            "HOME_DEVELOPMENT_BODY_2": "Swagger Endpunkt für OpenAPI & Swagger UI",
            "HOME_DEVELOPMENT_BODY_3": "Automatische Unit- und Integrationstests vor jedem Release",
            "HOME_DEVELOPMENT_BODY_4": "Log4Net Logdateien und Azure Application Insights zur Überwachung",
            "HOME_DEVELOPMENT_BODY_5": "Resourcen (resx) and Api definitionen transpiliert zu Typescript.",
            "GENERIC_Error": "Fehler",
            "GENERIC_Warning": "Warnung",
            "GENERIC_Message": "Nachricht",
            "GENERIC_CONFIRM_Title": "Bitte bestätige deine Auswahl",
            "GENERIC_CONFIRM_Body": "Bist du sicher?",
            "GENERIC_Ok": "Ok",
            "GENERIC_Cancel": "Abbrechen",
            "GENERIC_Delete": "Löschen",
            "GENERIC_Remember": "Nicht mehr anzeigen",
            "GENERIC_None": "Keine",
            "GENERIC_Category": "Kategorie",
            "GENERIC_Description": "Beschreibung",
            "GENERIC_Question": "Frage",
            "SENPAI_AddQuestion": "Eine neue Frage hinzufügen",
            "SENPAI_AddCategory": "Eine neue Kategorie hinzufügen",
            "SENPAI_Import": "Beispieldaten importieren",
            "GENERIC_Import": "Importieren",
            "SENPAI_ManageQuestions": "Fragen verwalten",
            "SENPAI_ManageCategories": "Kategorien verwalten",
            "GENERIC_Filter": "Filter",
            "GENERIC_Any": "Jede",
            "SENPAI_Statistics": "Du hast {0} / {1} Fragen beantwortet",
            "COOKIEPOLICY_Title": "Du musst unsere Cookies akzeptieren",
            "COOKIEPOLICY_Body": "Wir nutzen keine Kekse von Drittanbietern. Wir brauchen jedoch ein paar Cookies, sonst funktioniert das nicht.",
            "GENERIC_Language": "Sprache",
            "GENERIC_Language_German": "Deutsch",
            "GENERIC_Language_English": "Englisch",
            "NAV_AccountSettings": "Benutzer Einstellungen",
            "GENERIC_Logout": "Ausloggen",
            "GENERIC_Register": "Registrieren",
            "GENERIC_Login": "Einloggen",
            "NAV_ControlPanel": "Einstellungen",
            "GENERIC_Notifications": "Benachrichtigungen",
            "NAV_ApiDocs": "API Doku",
            "SOCKET_Establishing": "Verbindung wird aufgebaut",
            "SOCKET_Connected": "Verbunden",
            "SOCKET_SendNo": "Nachrichten gesendet",
            "SOCKET_ReceivedNo": "Nachrichten empfangen",
            "SOCKET_Upload": "Nachricht senden",
            "SOCKET_History": "Nachrichtenverlauf",
            "GENERIC_Type": "Typ",
            "TODO_Create": "Neuen ToDo Eintrag erstellen",
            "LOGINFLOW_LoginInProgress": "Login wird durchgeführt",
            "LOGOUTFLOW_LogoutInProgress": "Logout wird durchgeführt",
            "GENERIC_Success": "Erfolg",
            "GENERIC_Email": "Email",
            "PROFILE_NewEmail": "Neue Email",
            "PROFILE_SetPassword": "Passwort setzen",
            "PROFILE_ChangePassword": "Passwort ändern",
            "PROFILE_OldPassword": "Altes Passwort",
            "PROFILE_NewPassword": "Neues Passwort",
            "PROFILE_ConfirmPassword": "Passwort bestätigen",
            "CONNECTEDLOGINS_Title": "Derzeit verbundene Login Schema",
            "CONNECTEDLOGINS_None": "Du nutzt kein externes Login Schema",
            "CONNECTEDLOGINS_TransferMessage": "Du logst dich derzeit via {0} ein. Du kannst unten zu einem konventionellen Passwort-Login wechseln.",
            "CONNECTEDLOGINS_PasswordRequirement": "Bevor du dein externes Login Schema entfernen kannst, musst du ein Passwort für deinen Account setzen.",
            "GENERIC_Remove_Imperative": "Entferne",
            "PRIVACY_Title": "Persönliche Daten",
            "PRIVACY_Description": "Dein account enthält persönliche informationen die du uns anvertraut hast. Diese Seite erlaubt dir diese Daten herunterzuladen oder sie zu löschen.",
            "PRIVACY_Warning": "Das Löschen dieser Daten wird zum Verlust deines Accounts führen. Dies kann nicht rückgängig gemacht werden.",
            "PRIVACY_ConfirmPassword": "Bitte gib dein Passwort zur Bestätigung ein",
            "GENERIC_Download": "Herunterladen",
            "PROFILEPAGE_Title": "Accountverwaltung",
            "PROFILEPAGE_Subtitle": "Ändere deine Accounteinstellungen",
            "PROFILE_ChangeProfile": "Profiländerungen",
            "GENERIC_Username": "Benutzername",
            "GENERIC_Phonenumber": "Telefonnummer",
            "PROFILE_TFA_Title": "Verwaltung: Zwei-Faktor Authentifizierung",
            "PROFILE_TFA_ExternalExplanation": "Du kannst Zwei-Faktor Authentifizierung zu deinem Account hinzufügen. Allerdings wird dies keinen Effekt haben, solange du einen externen Identitätsanbieter zum Anmelden nutzt. Wenn du dies ändern willst, musst du dein Konto in ein lokales Konto umwandeln",
            "PROFILE_TFA_RecoveryCodesDescription": "Bitte drucke oder speichere deine Notfall-Passwörter. Du kannst mit diesen einloggen falls dein Handy einen Schaden hat.",
            "PROFILE_TFA_RecoveryCodes": "Notfall-Passwörter",
            "PROFILE_TFA_NoRecoveryCodesLeft": "Du hast keine Notfall-Passwörter mehr.",
            "PROFILE_TFA_GenerateNewRecoveryCodes": "Du musst einen neuen Satz Notfall-Passwörter generieren um dich im Notfall einloggen zu können.",
            "PROFILE_TFA_CodesLeft": "Du hast {0} Notfall-Passwörter übrig.",
            "PROFILE_TFA_ForgetBrowserDescription": "Dieses Gerät braucht keinen TFA Code zum einloggen. Du kannst diesen Zustand hier zurücksetzen:",
            "PROFILE_TFA_Forget": "Diesen Browser vergessen",
            "PROFILE_TFA_DisableDescription": "TFA ist aktiviert. Du kannst es temporär deaktivieren:",
            "GENERIC_Disable": "Deaktivieren",
            "PROFILE_TFA_RemoveDescription": "Du kannst TFA auch komplett entfernen:",
            "GENERIC_Remove": "Entfernen",
            "PROFILE_TFA_NewCodes": "Brauchst du neue Notfall-Passwörter?",
            "PROFILE_TFA_RecoveryCodesReset": "Notfall-Passwörter generieren",
            "PROFILE_TFA_AmbigiousState": "Du hast einen Authenticator registriert, aber TFA ist deaktiviert. Du kannst es aktivieren. Möglicherweise hast du auch einfach den Aktivierungs-Prozess abgebrochen. In diesem Fall solltest du neu beginnen.",
            "PROFILE_TFA_Reactivate": "TFA aktivieren",
            "PROFILE_TFA_RestartActivation": "Aktivierung neu beginnen",
            "PROFILE_TFA_AuthenticatorApp": "Authentifikator einrichten",
            "PROFILE_TFA_StartActivation": "Aktivierung beginnen",
            "PROFILE_TFA_AuthenticatorQRCodeDescription": "Benutze eine Authentifikator App um den QR code zu scannen oder gib den Aktivierungscode manuell in deine App ein.",
            "PROFILE_TFA_AuthenticatorActivationCodePrompt": "Nachdem du den QR code gespannt hast gib bitte einen Code aus der App zur Bestätigung ein.",
            "PROFILE_TFA_Code": "Code",
            "PROFILE_TFA_Activate": "TFA aktivieren",
            "GENERIC_Password": "Passwort",
            "PROFILEPAGE_ProfileNav": "Mein Profil",
            "PROFILEPAGE_ExternalLoginsNav": "Externe Logins",
            "PROFILEPAGE_TFANav": "Zwei-Faktor-Authentifizierung",
            "PROFILEPAGE_PrivacyNav": "Persönliche Daten",
            "SIGNUPPAGE_Title": "Erstelle einen neuen Account",
            "SIGNUPPAGE_Title_Confirmation": "Registrierung abschließen",
            "SIGNUPPAGE_Title_ExternalExplanation": "Dies ist das erste mal, dass du dich mit einem externen Login Anbieter anmeldest. Wir haben mit den Informationen einen Suitamoi.com Account erstellt.",
            "SIGNUPPAGE_ActivationMailDescription": "Bitte beende die Registrierung indem du den Link in der email klickst die wir gerade verschickt haben",
            "ACTIVATION_RequestMail": "Accountaktivierungsmail anfordern",
            "ACTIVATION_Success": "Dein Account wurde aktiviert - du kannst nun einloggen",
            "RESETPASSWORD_Confirm": "Falls dieser Account existiert wurde eine Email an diesen verschickt. Bitte folge dem Link der Mail",
            "RESETPASSWORD_Success": "Dein Passwort wurde zurückgesetzt. Du kannst jetzt einloggen.",
            "ACTIVATION_Confirm": "Falls dieser Account existiert wurde eine neue Aktivierungsmail verschickt. Bitte folge dem Link in der Mail.",
            "EXTERNALLOGINS_Title": "Benutze einen externen Login Anbieter",
            "EXTERNALLOGINS_NoneAvailable": "Es sind keine externen Login Anbieter verfügbar.",
            "RESETPASSWORD_Prompt": "Bitte gib dein neues Passwort ein",
            "RESETPASSWORD_Reset": "Passwort zurücksetzen",
            "LOGINPAGE_Title": "Einloggen mit Suitamoi.com Account",
            "LOGINPAGE_TfaPrompt": "Bitte TFA Code eingeben",
            "LOGINPAGE_LostAuthenticator": "Authentifikator verloren?",
            "LOGINPAGE_UseRecoveryCode": "Nutz ein Notfall-Passwort",
            "LOGINPAGE_BackToTfa": "Zurück zur Code Eingabe",
            "LOGINPAGE_RememberMe": "Eingeloggt bleiben",
            "LOGINPAGE_NAV_NewAccount": "Account erstellen",
            "LOGINPAGE_NAV_ForgotPassword": "Password vergessen?",
            "LOGINPAGE_NAV_ActivationMail": "Aktivierungs-Email anfordern",
            "HOME_FEATURES_BODY_5": "Neu: Swagger UI im Dark Mode + Authorisierung via Open ID Client auf dieser Seite",
            "NAV_AccentColor": "Akzent Farbe",
            "LOGINPAGE_NAV_Login": "Lokaler Login",
            "LOGINPAGE_MAIN_Subtitle": "Du benötigst einen Account für diese Seite",
            "LOGINPAGE_MAIN_Title": "Suitamoi.com beitreten",
            "GENERIC_Confirm": "Bestätigen",
            "SIDEBAR_Navigation": "Navigation",
            "SIDEBAR_Partnerlist": "Partnerliste",
            "SIDEBAR_General": "Allgemein",
            "PARTNERLIST_ADD_Partner": "Partner hinzufügen",
            "PARTNERLIST_ADD_Group": "Gruppe hinzufügen",
            "NOTIFICATIONCENTER_FRIENDREQUEST": "würde dich gern als Freund gewinnen.",
            "NAV_State": "Status",
            "ONLINESTATE_Online": "Online",
            "ONLINESTATE_Offline": "Offline",
            "ONLINESTATE_Away": "Abwesend",
            "ONLINESTATE_DoNotDisturb": "Nicht stören",
            "PARTNERLIST_DRAGHERETOREMOVE": "hier ablegen",
            "PROFILEPICTURES_HEADLINE": "Profilbild hochladen",
        },
        errors: {
            "BACKEND_LOGIN_ERROR_AccountLocked": "Dein Account wurde vom Administrator gesperrt.",
            "BACKEND_LOGIN_ERROR_EmailActivationRequired": "Dein Account muss erst per Mail aktiviert werden.",
            "BACKEND_LOGIN_ERROR_ExternalLoginNotSupported": "Login mit einem externen Login Provider wird nicht unterstützt.",
            "BACKEND_LOGIN_ERROR_RemoteError": "Fehler beim einloggen am externen Login Provider:",
            "SPA_LOGIN_ERROR_InternalServerError": "Interner Server Fehler (dar Fehler den keiner mag).",
            "SPA_LOGIN_ERROR_LoginRequired": "Login erforderlich.",
            "SPA_LOGOUT_ERROR_ExternalSignOutError": "Fehler beim ausloggen am externen Login Provider.",
            "SPA_LOGOUT_ERROR_SignOutError": "Fehler beim ausloggen.",
            "SPA_LOGOUT_SUCCESS": "Logout durchgeführt.",
            "TestKey": "TestFehler",
            "BACKEND_MANAGEPROFILE_DATAPRIV_ERROR_AccountDeletion": "Accountlöschung fehlgeschlagen.",
            "BACKEND_MANAGEPROFILE_LOGIN_ERROR_DeleteProvider": "Der Identitätsanbieter konnte nicht entfernt werden.",
            "BACKEND_MANAGEPROFILE_PASSWORD_AlreadySet": "Password ist bereits gesetzt.",
            "BACKEND_MANAGEPROFILE_TFA_ToggleWouldDestroyAccount": "Kein Authenticator registriert. Aktivieren von TFA würde zu Accountverlust führen!",
            "BACKEND_GENERIC_Error": "Ein Fehler! Bitte versuch es nochmals.",
            "BACKEND_MANAGEPROFILE_TFA_InvalidToken": "Ungültiger TFA Code.",
            "BACKEND_MANAGEPROFILE_TFA_RecoveryCodeGenerationNoTfa": "Es konnten keine Wiederherstellungscodes erzeugt werden, da gar kein TFA aktiviert ist.",
            "BACKEND_GENERIC_InvalidPassword": "Falsches Passwort.",
            "BACKEND_GENERIC_InvalidInput": "Eingabe zumindest teilweise fehlerhaft. Bitte überprüf deine Angaben.",
            "BACKEND_GENERIC_OPERATIONDENIED": "Operation terminiert.",
            "BACKEND_MANAGEPROFILE_ChangeEmailError": "Ein Fehler ist beim ändern der Email aufgetreten.",
            "BACKEND_MANAGEPROFILE_ChangePhoneError": "Ein Fehler ist beim ändern der Telefonnummer aufgetreten.",
            "BACKEND_MANAGEPROFILE_ChangeUserNameError": "Ein Fehler ist beim ändern des Benutzernamens aufgetreten.",
            "BACKEND_MANAGEPROFILE_ChangePasswordError": "Ein Fehler ist beim ändern des Passwortes aufgetreten.",
            "BACKEND_LOGIN_ERROR_UserNameOrPassword": "Benutzername oder Passwort falsch.",
            "SPA_VALIDATION_REQUIRED": "Erforderlich",
            "SPA_VALIDATION_LENGTH": "Zu kurz.",
            "SPA_VALIDATION_STRENGTH": "Muss mindestens eine Zahl sowie einen Groß- und Kleinbuchstaben einthalten",
            "SPA_VALIDATION_EMAIL": "Keine gültige Email",
            "SPA_VALIDATION_EXACTLENGTH": "Es werden exakt {0} Zeichen benötigt",
            "SPA_VALIDATION_NaN": "Es wird eine Zahl benötigt",
            "SPA_MANAGEPROFILE_DeleteAccountConfirmation": "Dein Account wurde gelöscht",
            "SPA_MANAGEPROFILE_DeleteLoginProviderConfirmation": "Der Externe Login Anbieter wurde gelöscht. Du musst dich fortan mit deinem Passwort anmelden.",
            "SPA_MANAGEPROFILE_ChangeEmailConfirmation": "Eine Email wurde an deine neue Email Adresse geschickt. Bitte beende den Prozess indem du den Link in der Mail anklickst.",
            "SPA_MANAGEPROFILE_ChangeEmailSuccess": "Deine Email Adresse wurde geändert",
            "SPA_MANAGEPROFILE_UpdatePasswordConfirmation": "Dein Passwort wurde geändert",
            "SPA_MANAGEPROFILE_TfaRemoveConfirmation": "Dein TFA Setup wurde entfernt",
            "SPA_MANAGEPROFILE_ChangeEmail": "Email ändern",
            "SPA_VALIDATION_PasswordsDoNotMatch": "Passwörter stimmen nicht überein",
            "BACKEND_SENPAI_ImportError": "Du hast bereits Daten.",
            "SPA_PARTNERLIST_PARTNERNOTFOUND": "Der Partner existiert nicht",
            "SPA_PARTNERLIST_CANTADDYOURSELF": "Du kannst dich nicht selbst hinzufügen",
        },
    },
    en: {
        translations: {
            "TestKey": "TestValue",
            "EMAILSERVICE_HEADER_ConfirmEmail": "Please confirm your email.",
            "EMAILSERVICE_HEADER_ResetPassword": "Password reset: Suitamoi.com",
            "EMAILSERVICE_HEADER_ChangeEmail": "Email change: Suitamoi.com",
            "EMAILSERVICE_BODY_ConfirmEmail": "Please confirm your account {0} by clicking the link: {1}",
            "EMAILSERVICE_BODY_ResetPassword": "Please reset the password for account: {0} by clicking the link: {1}",
            "EMAILSERVICE_BODY_ChangeEmail": "Please change the email for account: {0} by clicking the link: {1}",
            "HOME_Welcome": "Welcome",
            "HOME_TechStack": "Tech Stack:",
            "HOME_TECHSTACK_BODY_1": "Purple Circle Support",
            "HOME_TECHSTACK_BODY_2": "Azure Hosted WebApp",
            "HOME_TECHSTACK_BODY_3": "Duende Identity Server + Google login",
            "HOME_TECHSTACK_BODY_4": "SQL Database for Identity Server and User Storage",
            "HOME_TECHSTACK_BODY_5": "Single Page Application based on react-redux, typescript and es6",
            "HOME_TECHSTACK_BODY_6": "Backend C# ASP.NET Core",
            "HOME_TECHSTACK_BODY_7": "Scaling freely because of stateless sessions",
            "HOME_TECHSTACK_BODY_8": "Multiple sessions per user supported and synched via authed web sockets",
            "HOME_TECHSTACK_BODY_9": "Grids by AG Grid",
            "HOME_TECHSTACK_BODY_10": "Bootstrap used for Layout",
            "HOME_TECHSTACK_BODY_11": "German and English supported",
            "HOME_TECHSTACK_BODY_12": "Serverless session check - super fast page refresh (F5)",
            "HOME_Features": "Features",
            "HOME_FEATURES_BODY_1": "Ask me, Senpai: Enter some data and get it back randomly - totally amazing",
            "HOME_FEATURES_BODY_2": "New: Categories & inplace edit for Ask me, Senpai",
            "HOME_FEATURES_BODY_3": "New: Two-factor authentication, cookie consent, data subject requests, user profile management",
            "HOME_FEATURES_BODY_4": "New for Power Users: Remember choices for Ask me, Senpai + Return key support on various forms",
            "HOME_SecurityDataProtection": "Security & Data Protection",
            "HOME_SECURITYDATAPROTECTION_BODY_1": "AzureKeyVault for configuration values",
            "HOME_SECURITYDATAPROTECTION_BODY_2": "HSTS",
            "HOME_SECURITYDATAPROTECTION_BODY_3": "Data Subject Requests & Account deletion",
            "HOME_Development": "Dev Support, CI/CD, Automation, Testing",
            "HOME_Upcomming": "Upcomming",
            "HOME_DEVELOPMENT_BODY_1": "Azure DevOps for task management and continous integration",
            "HOME_DEVELOPMENT_BODY_2": "Swagger endpoint for OpenAPI & Swagger UI",
            "HOME_DEVELOPMENT_BODY_3": "Automated unit & integration tests before deploy",
            "HOME_DEVELOPMENT_BODY_4": "Log4Net logging & Azure Application Insights monitoring",
            "HOME_DEVELOPMENT_BODY_5": "Resources (resx) and Api definitions transpiled to Typescript",
            "GENERIC_Error": "Error",
            "GENERIC_Warning": "Warning",
            "GENERIC_Message": "Message",
            "GENERIC_CONFIRM_Title": "Please confirm your choice",
            "GENERIC_CONFIRM_Body": "Are you sure?",
            "GENERIC_Ok": "Ok",
            "GENERIC_Cancel": "Cancel",
            "GENERIC_Delete": "Delete",
            "GENERIC_Remember": "Remember my choice",
            "GENERIC_None": "None",
            "GENERIC_Category": "Category",
            "GENERIC_Description": "Description",
            "GENERIC_Question": "Question",
            "SENPAI_AddQuestion": "Add a new question",
            "SENPAI_AddCategory": "Add a new category",
            "SENPAI_Import": "Import example data",
            "GENERIC_Import": "Import",
            "SENPAI_ManageQuestions": "Manage questions",
            "SENPAI_ManageCategories": "Manage categories",
            "GENERIC_Filter": "Filter",
            "GENERIC_Any": "Any",
            "SENPAI_Statistics": "You have answered {0} / {1} Questions",
            "COOKIEPOLICY_Title": "You need to accept our cookie policy",
            "COOKIEPOLICY_Body": "We do not use any 3rd Party tracking. But we need to use Cookies, so this stuff does work.",
            "GENERIC_Language": "Language",
            "GENERIC_Language_German": "German",
            "GENERIC_Language_English": "English",
            "NAV_AccountSettings": "Account settings",
            "GENERIC_Logout": "Logout",
            "GENERIC_Register": "Register",
            "GENERIC_Login": "Login",
            "NAV_ControlPanel": "Control panel",
            "GENERIC_Notifications": "Notifications",
            "NAV_ApiDocs": "API Docs",
            "SOCKET_Establishing": "Establishing connection",
            "SOCKET_Connected": "Is connected",
            "SOCKET_SendNo": "Messages send",
            "SOCKET_ReceivedNo": "Messages received",
            "SOCKET_Upload": "Upload Message",
            "SOCKET_History": "Message history",
            "GENERIC_Type": "Type",
            "TODO_Create": "Create ToDo item",
            "LOGINFLOW_LoginInProgress": "Processing login",
            "LOGOUTFLOW_LogoutInProgress": "Processing logout",
            "GENERIC_Success": "Success",
            "GENERIC_Email": "Email",
            "PROFILE_NewEmail": "New email",
            "PROFILE_SetPassword": "Set password",
            "PROFILE_ChangePassword": "Change password",
            "PROFILE_OldPassword": "Old password",
            "PROFILE_NewPassword": "New password",
            "PROFILE_ConfirmPassword": "Confirm password",
            "CONNECTEDLOGINS_Title": "Current External Logins Scheme",
            "CONNECTEDLOGINS_None": "You do not use any external login schemas",
            "CONNECTEDLOGINS_TransferMessage": "You are currently logging in through {0}. You can convert to conventional Password-Login below.",
            "CONNECTEDLOGINS_PasswordRequirement": "Before you can remove the external login schema you need to set a password for your account",
            "GENERIC_Remove_Imperative": "Remove",
            "PRIVACY_Title": "Personal Data",
            "PRIVACY_Description": "Your account contains personal data that you have given us. This page allows you to download or delete that data.",
            "PRIVACY_Warning": "Deleting this data will permanently remove your account, and this cannot be recovered.",
            "PRIVACY_ConfirmPassword": "Enter your password to confirm",
            "GENERIC_Download": "Download",
            "PROFILEPAGE_Title": "Manage your account",
            "PROFILEPAGE_Subtitle": "Change your account settings",
            "PROFILE_ChangeProfile": "Change profile",
            "GENERIC_Username": "Username",
            "GENERIC_Phonenumber": "Phone number",
            "PROFILE_TFA_Title": "Manage Two-factor Authentication",
            "PROFILE_TFA_ExternalExplanation": "You can add Two-Factor-Authentication to your account. But you are signin in with an external Identity Provider, this will not have an effect until you convert this account to a local account",
            "PROFILE_TFA_RecoveryCodesDescription": "Please print your recovery codes. You can log in with these, in case your authenticator / phone breaks.",
            "PROFILE_TFA_RecoveryCodes": "Recovery Codes",
            "PROFILE_TFA_NoRecoveryCodesLeft": "You have no recovery codes left.",
            "PROFILE_TFA_GenerateNewRecoveryCodes": "You must generate a new set of recovery codes before you can log in with a recovery code.",
            "PROFILE_TFA_CodesLeft": "You have {0} recovery codes left.",
            "PROFILE_TFA_ForgetBrowserDescription": "This device is remembered and you do not need to reenter your TFA Code everytime. You can forget your device here:",
            "PROFILE_TFA_Forget": "Forget this browser",
            "PROFILE_TFA_DisableDescription": "TFA is enabled but you can temporarily disable it:",
            "GENERIC_Disable": "Disable",
            "PROFILE_TFA_RemoveDescription": "You can also remove TFA altogether:",
            "GENERIC_Remove": "Remove",
            "PROFILE_TFA_NewCodes": "Need new recovery codes?",
            "PROFILE_TFA_RecoveryCodesReset": "Reset recovery codes",
            "PROFILE_TFA_AmbigiousState": "You have a registered Authenticator, but TFA is deactivated. You can re-activate it. Or you aborted the activation process - you can start over.",
            "PROFILE_TFA_Reactivate": "Re-Activate TFA",
            "PROFILE_TFA_RestartActivation": "Restart activation process",
            "PROFILE_TFA_AuthenticatorApp": "Authenticator App",
            "PROFILE_TFA_StartActivation": "Start activiation process",
            "PROFILE_TFA_AuthenticatorQRCodeDescription": "Use an authenticator App to scan the QR Code above or enter the activation code manually:",
            "PROFILE_TFA_AuthenticatorActivationCodePrompt": "After you have scanned the QR code, enter a code from the authenticator app into the below box and click activate TFA",
            "PROFILE_TFA_Code": "Code",
            "PROFILE_TFA_Activate": "Activate TFA",
            "GENERIC_Password": "Password",
            "PROFILEPAGE_ProfileNav": "Profile",
            "PROFILEPAGE_ExternalLoginsNav": "External Logins",
            "PROFILEPAGE_TFANav": "Two-factor authentication",
            "PROFILEPAGE_PrivacyNav": "Personal Data",
            "SIGNUPPAGE_Title": "Create a new account",
            "SIGNUPPAGE_Title_Confirmation": "Register confirmation",
            "SIGNUPPAGE_Title_ExternalExplanation": "This is the first time you logged in with an external login provider. As such we have used that information to create your Suitamoi.com account.",
            "SIGNUPPAGE_ActivationMailDescription": "Please continue the registration process of your account, by clicking the link in the email send to you.",
            "ACTIVATION_RequestMail": "Request activation mail",
            "ACTIVATION_Success": "Account successfully activated - you can sign in now",
            "RESETPASSWORD_Confirm": "If this account exists an email has been send to that inbox. Please follow the link in the email",
            "RESETPASSWORD_Success": "Your password has been reset. You can login now.",
            "ACTIVATION_Confirm": "If the account exists, an email with a new activation code has been send. Please inspect your inbox.",
            "EXTERNALLOGINS_Title": "Use an external Login",
            "EXTERNALLOGINS_NoneAvailable": "There are no external authentication services configured.",
            "RESETPASSWORD_Prompt": "Please enter your new password",
            "RESETPASSWORD_Reset": "Reset password",
            "LOGINPAGE_Title": "Login with your Suitamoi.com account",
            "LOGINPAGE_TfaPrompt": "Please enter your TFA code",
            "LOGINPAGE_LostAuthenticator": "Lost your Authenticator?",
            "LOGINPAGE_UseRecoveryCode": "Log in with a recovery code instead",
            "LOGINPAGE_BackToTfa": "Back to code promp",
            "LOGINPAGE_RememberMe": "Remember me",
            "LOGINPAGE_NAV_NewAccount": "Create a new account",
            "LOGINPAGE_NAV_ForgotPassword": "Forgot your password?",
            "LOGINPAGE_NAV_ActivationMail": "Resend email confirmation",
            "HOME_FEATURES_BODY_5": "New: Swagger UI Dark mode + Authorize via OpenID Client on parent site",
            "NAV_AccentColor": "Accent color",
            "LOGINPAGE_NAV_Login": "Local login",
            "LOGINPAGE_MAIN_Subtitle": "You need an account to use this site",
            "LOGINPAGE_MAIN_Title": "Join Suitamoi.com",
            "GENERIC_Confirm": "Confirm",
            "SIDEBAR_Navigation": "Navigation",
            "SIDEBAR_Partnerlist": "Partnerlist",
            "SIDEBAR_General": "General",
            "PARTNERLIST_ADD_Partner": "Add partner",
            "PARTNERLIST_ADD_Group": "Add group",
            "NOTIFICATIONCENTER_FRIENDREQUEST": "would like to be your friend.",
            "NAV_State": "Status",
            "ONLINESTATE_Online": "Online",
            "ONLINESTATE_Offline": "Offline",
            "ONLINESTATE_Away": "Away",
            "ONLINESTATE_DoNotDisturb": "Do not disturb",
            "PARTNERLIST_DRAGHERETOREMOVE": "drop here",
            "PROFILEPICTURES_HEADLINE": "Upload a profile picture",
        },
        errors: {
            "BACKEND_LOGIN_ERROR_AccountLocked": "Your account is locked by the administrator.",
            "BACKEND_LOGIN_ERROR_EmailActivationRequired": "Account required activation by email.",
            "BACKEND_LOGIN_ERROR_ExternalLoginNotSupported": "External login is not supported.",
            "BACKEND_LOGIN_ERROR_RemoteError": "An error occured on the remote login provider:",
            "SPA_LOGIN_ERROR_InternalServerError": "Internal server error (the one everyone hates).",
            "SPA_LOGIN_ERROR_LoginRequired": "Login required.",
            "SPA_LOGOUT_ERROR_ExternalSignOutError": "There was an error signing out with the external provider.",
            "SPA_LOGOUT_ERROR_SignOutError": "There was an error signing out with.",
            "SPA_LOGOUT_SUCCESS": "You successfully logged out!",
            "TestKey": "TestError",
            "BACKEND_MANAGEPROFILE_DATAPRIV_ERROR_AccountDeletion": "Account deletion failed.",
            "BACKEND_MANAGEPROFILE_LOGIN_ERROR_DeleteProvider": "The selected provider could not be removed.",
            "BACKEND_MANAGEPROFILE_PASSWORD_AlreadySet": "Password already is already set.",
            "BACKEND_MANAGEPROFILE_TFA_ToggleWouldDestroyAccount": "No authenticator registered! Enabling TFA would brick the account!",
            "BACKEND_GENERIC_Error": "Error during operation. Please try again.",
            "BACKEND_MANAGEPROFILE_TFA_InvalidToken": "Invalid TFA Code.",
            "BACKEND_MANAGEPROFILE_TFA_RecoveryCodeGenerationNoTfa": "Cannot generate recovery codes for user as they do not have 2FA enabled.",
            "BACKEND_GENERIC_InvalidPassword": "Invalid password.",
            "BACKEND_GENERIC_InvalidInput": "Entered data is at least partially invalid. Please check your input.",
            "BACKEND_GENERIC_OPERATIONDENIED": "Operation denied.",
            "BACKEND_MANAGEPROFILE_ChangeEmailError": "An error occured changing your email.",
            "BACKEND_MANAGEPROFILE_ChangePhoneError": "An error occured changing your phone number.",
            "BACKEND_MANAGEPROFILE_ChangeUserNameError": "An error occured changing your user name",
            "BACKEND_MANAGEPROFILE_ChangePasswordError": "An error occured changing your password.",
            "BACKEND_LOGIN_ERROR_UserNameOrPassword": "User name or password wrong.",
            "SPA_VALIDATION_REQUIRED": "Required",
            "SPA_VALIDATION_LENGTH": "Too short",
            "SPA_VALIDATION_STRENGTH": "Must contain at least one number and letter, upper and lower case",
            "SPA_VALIDATION_EMAIL": "Not a valid email address",
            "SPA_VALIDATION_EXACTLENGTH": "Exactly {0} values expected",
            "SPA_VALIDATION_NaN": "Input is not a number",
            "SPA_MANAGEPROFILE_DeleteAccountConfirmation": "Your account has been deleted",
            "SPA_MANAGEPROFILE_DeleteLoginProviderConfirmation": "The external Login Provider has been removed. You now need to login with a password and your email address.",
            "SPA_MANAGEPROFILE_ChangeEmailConfirmation": "An email has been send to your new email adress. Please continue the confirmation process by clicking in the link in the mail.",
            "SPA_MANAGEPROFILE_ChangeEmailSuccess": "Your email was changed",
            "SPA_MANAGEPROFILE_UpdatePasswordConfirmation": "Password has been updated",
            "SPA_MANAGEPROFILE_TfaRemoveConfirmation": "Your TFA Setup was removed",
            "SPA_MANAGEPROFILE_ChangeEmail": "Change Email",
            "SPA_VALIDATION_PasswordsDoNotMatch": "Passwords do not match",
            "BACKEND_SENPAI_ImportError": "You already have data.",
            "SPA_PARTNERLIST_PARTNERNOTFOUND": "Partner does not exist",
            "SPA_PARTNERLIST_CANTADDYOURSELF": "You cannot add yourself",
        },
    },
}
