import type { Middleware } from "redux"
import { connectionEstablished, receiveMessage, resetSocket } from "./reducer/socketReducer"
import { show, PopupType as PopUpType } from "../../components/popupmessage/reducer/popupReducer"
import { FrontendNotification } from "../../@types/FrontendNotification"
import { NotificationTarget } from "../../@types/NotificationTarget"
import { AskMeSenpaiNotificationPayload } from "../../@types/AskMeSenpaiNotificationPayload"
import { addCategory, addQuestion, editCategory, editQuestion, loadCategories, loadQuestions, removeCategory, removeQuestion } from "../../components/askmesenpai/reducer/askMeSenpaiReducer"
import { AskMeSenpaiNotificationType } from "../../@types/AskMeSenpaiNotificationType"
import { remove } from "../../components/todolist/reducer/toDoItemsReducer"
import i18n from "i18next"
import { ToDoListNotificationType } from "../../@types/ToDoListNotificationType"
import { ToDoListNotificationPayload } from "../../@types/ToDoListNotificationPayload"
import { PartnerlistNotificationPayload } from "../../@types/PartnerlistNotificationPayload"
import { PartnerlistNotificationType } from "../../@types/PartnerlistNotificationType"
import { addGroup, addPartner, editGroup, editPartner, loadAllGroups, loadAllPartners, removeGroup, removePartner, setOnlineState, setOnlineStateForPartner } from "../../components/partnerlist/reducer/partnerlistReducer"
import { partnerAdded, partnerEdited, partnersLoaded, partnerDeleted } from "../../components/navmenu/reducer/notficationCenterReducer"
import { ServerInstructionNotificationPayload } from "../../@types/ServerInstructionNotificationPayload"
import { ServerInstruction } from "../../@types/ServerInstruction"
import { logout } from "../../app/reducer/appStateReducer"

const SocketMiddleware: Middleware = store => {
    let socket: WebSocket

    const handleReceiveMessage = (notification: FrontendNotification, store: any) => {
        switch (notification.id) {
            case NotificationTarget.Message: {
                const popup = {
                    showPopup: true,
                    //deserialize because content is encoded twice
                    content: JSON.parse(notification.content),
                    popupType: PopUpType.Normal,
                    headline: i18n.t("GENERIC_Message")
                }
                //show a notification to the user in a popup
                store.dispatch(show(popup))
                break
            }
            case NotificationTarget.Error: {
                const popup = {
                    showPopup: true,
                    //deserialize because content is encoded twice
                    content: JSON.parse(notification.content),
                    popupType: PopUpType.Error,
                    headline: i18n.t("GENERIC_Error"),
                }
                //show a notification to the user in a popup
                store.dispatch(show(popup))
                break
            }
            case NotificationTarget.ServerInstruction: {                 
                const notificationPayload = JSON.parse(notification.content) as ServerInstructionNotificationPayload
                switch (notificationPayload.serverInstruction) {
                    case ServerInstruction.Refresh: {
                        break
                    }
                    case ServerInstruction.SignOut: {
                        store.dispatch(logout())                        
                        break
                    }
                }
                break
            }
            case NotificationTarget.ServiceAskMeSenpai: {
                const notificationPayload = JSON.parse(notification.content) as AskMeSenpaiNotificationPayload
                switch (notificationPayload.type) {
                    case AskMeSenpaiNotificationType.ReadQuestions: {
                        store.dispatch(loadQuestions(notificationPayload.questions))
                        break
                    }
                    case AskMeSenpaiNotificationType.CreateQuestion: {
                        store.dispatch(addQuestion(notificationPayload.question))
                        break
                    }
                    case AskMeSenpaiNotificationType.UpdateQuestion: {
                        store.dispatch(editQuestion(notificationPayload.question))
                        break
                    }
                    case AskMeSenpaiNotificationType.DeleteQuestion: {
                        store.dispatch(removeQuestion(notificationPayload.deletedQuestionId))
                        break
                    }
                    case AskMeSenpaiNotificationType.ReadCategories: {
                        store.dispatch(loadCategories(notificationPayload.categories))
                        break
                    }
                    case AskMeSenpaiNotificationType.CreateCategory: {
                        store.dispatch(addCategory(notificationPayload.category))
                        break
                    }
                    case AskMeSenpaiNotificationType.UpdateCategory: {
                        store.dispatch(editCategory(notificationPayload.category))
                        break
                    }
                    case AskMeSenpaiNotificationType.DeleteCategory: {
                        store.dispatch(removeCategory(notificationPayload.deletedCategoryId))
                        break
                    }
                    case AskMeSenpaiNotificationType.SetResult: {
                        store.dispatch(editQuestion(notificationPayload.question))
                        break
                    }
                }

                break
            }
            case NotificationTarget.ServiceToDoList: {
                const notificationPayload = JSON.parse(notification.content) as ToDoListNotificationPayload
                switch (notificationPayload.type) {
                    case ToDoListNotificationType.DeleteToDoItem: {
                        store.dispatch(remove(notificationPayload.toDoItem))
                        break
                    }
                }
                break
            }           
            case NotificationTarget.ServicePartnerlist: {
                const notificationPayload = JSON.parse(notification.content) as PartnerlistNotificationPayload
                switch (notificationPayload.type) {
                    case PartnerlistNotificationType.ReadPartnerlist: {
                        store.dispatch(loadAllPartners(notificationPayload.partnerlist))
                        store.dispatch(partnersLoaded(notificationPayload))
                        break
                    }
                    case PartnerlistNotificationType.ReadPartnerlistGroups: {
                        store.dispatch(loadAllGroups(notificationPayload.groups))
                        break
                    }
                    case PartnerlistNotificationType.AddPartnerlistPartner: {
                        store.dispatch(addPartner(notificationPayload.partner))
                        break
                    }
                    case PartnerlistNotificationType.AddPartnerlistPartnerRequestReceived: {
                        store.dispatch(partnerAdded(notificationPayload))
                        break
                    }
                    case PartnerlistNotificationType.AddPartnerlistGroup: {
                        store.dispatch(addGroup(notificationPayload.group))
                        break
                    }
                    case PartnerlistNotificationType.UpdatePartnerlistPartner: {
                        store.dispatch(editPartner(notificationPayload.partner))
                        store.dispatch(partnerEdited(notificationPayload))
                        break
                    }
                    case PartnerlistNotificationType.UpdatePartnerlistGroup: {
                        store.dispatch(editGroup(notificationPayload.group))
                        break
                    }
                    case PartnerlistNotificationType.DeletePartnerlistPartner: {
                        store.dispatch(removePartner(notificationPayload.partner))
                        store.dispatch(partnerDeleted(notificationPayload))
                        break
                    }
                    case PartnerlistNotificationType.DeletePartnerlistGroup: {
                        store.dispatch(removeGroup(notificationPayload.group))
                        break
                    }
                    case PartnerlistNotificationType.OwnOnlineStateUpdate: {
                        store.dispatch(setOnlineState(notificationPayload.onlineState))
                        break
                    }
                    case PartnerlistNotificationType.PartnerOnlineStateUpdate: {
                        store.dispatch(setOnlineStateForPartner(notificationPayload.onlineState))
                        break
                    }
                }
                break
            }
        }
    }

    return next => async action => {
        //state
        const isConnectionEstablished = store.getState().socketState.isConnected
        const isEstablishingConnection = store.getState().socketState.isEstablishingConnection
        const isloggedIn = store.getState().appState.isLoggedIn

        if (action.type.match("socketReducer/startConnecting")) {
            if ((!socket || socket.readyState === 3) && !isConnectionEstablished && !isEstablishingConnection && isloggedIn) {
                const isDevelopment = process.env.NODE_ENV === "development"

                const token = store.getState().appState.accessToken
                if (token === null || token === undefined) {
                    return
                }

                const socketUrl = isDevelopment
                    ? `wss://localhost:7036/api/socket/connect?token=${token}`
                    : `wss://${window.location.host}/api/socket/connect?token=${token}`
                try {
                    socket = new WebSocket(socketUrl)

                    //configure socket callbacks
                    socket.onopen = (e: Event) => {
                        store.dispatch(connectionEstablished())
                    }
                    socket.onerror = (e: Event) => {
                        //store.dispatch(logout())
                    }
                    socket.onmessage = (e: MessageEvent) => {
                        const data = JSON.parse(e.data)
                        handleReceiveMessage(data, store)
                        //SocketTest component - not important
                        store.dispatch(receiveMessage(data))
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        }
        if (isConnectionEstablished) {
            //socket is not available directly - as such the middleware interjects and sends the item
            if (action.type.match("socketReducer/submitMessage")) {                
                socket.send(JSON.stringify(action.payload))
            }
            if (!isloggedIn) {
                if (socket.readyState === 1) {
                    socket.close()
                    store.dispatch(resetSocket())    
                    
                }                                
            }
        }
        

        //next action to not deadlock
        next(action)
    }
}

export default SocketMiddleware