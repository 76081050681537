import * as React from "react"
import { FeatureSpaPaths, IdentitySpaPaths } from "../../app/AppConstants"
import { Nav, Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
import { ControlPanel } from "./ControlPanel"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useTranslation } from "react-i18next"
import { toggleNotificationCenter } from "../../app/reducer/appStateReducer"
import { OnlineStatePanel } from "./OnlineStatePanel"

export const NavMenu = () => {
    const isLoggedIn = useAppSelector((state) => state.appState.isLoggedIn)
    const { t } = useTranslation()
    const dispatch = useAppDispatch() as any
    const notifications = useAppSelector((state) => state.notificationCenterState.notifications)

    const onClickToggleNotificationCenter = () => {
        dispatch(toggleNotificationCenter())
    }
    return (
        <Navbar collapseOnSelect className="px-2 navbarCustom" expand="md" bg="dark" sticky="top" variant="dark" >
            <Navbar.Toggle />
            <Navbar.Collapse >
                <Nav className="me-auto">
                    <Navbar.Brand to={IdentitySpaPaths.Home} as={Link}>Suitamoi</Navbar.Brand>
                    <Nav.Link to={FeatureSpaPaths.SocketTest} as={Link}>SocketDebug</Nav.Link>
                    <If condition={isLoggedIn}>
                        <Nav.Link to={FeatureSpaPaths.AskMeSenpai} as={Link}>Ask me, Senpai!</Nav.Link>
                        <Nav.Link to={FeatureSpaPaths.ToDoList} as={Link}>ToDo</Nav.Link>
                        
                    </If>
                </Nav>
                <Nav>
                    <ControlPanel />
                    <If condition={isLoggedIn}>
                        <OnlineStatePanel />
                    </If>
                    <Nav.Link onClick={onClickToggleNotificationCenter}>{t("GENERIC_Notifications")}: {notifications.length}</Nav.Link>

                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}