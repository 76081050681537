import * as React from "react"
import { SessionServiceContext } from "./SessionService"
import { Navigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IdentitySpaPaths, LoginActions } from "../../../app/AppConstants"
import { useAppDispatch } from "../../../app/hooks"
import { logout, setIsLoading } from "../../../app/reducer/appStateReducer"

interface ILoginProps {
    action: LoginActions
}

export const LoginFlow = (props: ILoginProps) => {
    const [action, setAction] = React.useState<LoginActions | undefined>(props.action)
    const sessionService = React.useContext(SessionServiceContext)

    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    React.useEffect(() => {
        switch (action) {
            case LoginActions.Login:
                login()
                break
            case LoginActions.LoginCallback:
                processLoginCallback()
                break
            case LoginActions.LoginFailed:
                //29.03.2024 : currently the logincallback throws an error when backend provides an error? url instead of a proper response
                //this leads to an unwanted exception being logged, but its actually a bug in the oidc library lets see if this gets fixed.
                //functionalitywise its fine but there is no nice backend error to be shown           
                dispatch(logout())
                break
        }
    }, [action, setAction])

    const login = () => {
        dispatch(setIsLoading(true))
        sessionService.signIn().then((success) => {
            if (success) {
                setAction(LoginActions.LoginSuccess)
            } else {                
                setAction(LoginActions.LoginFailed)
            }
            dispatch(setIsLoading(false))
        })
    }
    //this is only executed in the iframe
    const processLoginCallback = () => {
        const url = window.location.href
        sessionService.completeSignIn(url)
    }

    switch (action) {
        case LoginActions.Login:
        case LoginActions.LoginCallback:
            return (<div className="p-2">{t("LOGINFLOW_LoginInProgress")}</div>)
        case LoginActions.LoginFailed:
            return <Navigate to={IdentitySpaPaths.LoginPage} />
        case LoginActions.LoginSuccess:
            return <Navigate to={IdentitySpaPaths.Home} />
        default:
            return <div></div>
    }
}