import * as React from "react"
import { useEffect, useRef, useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { QuestionModel } from "../../@types/QuestionModel"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import { QuestionCategoryModel } from "../../@types/QuestionCategoryModel"
import { QuestionCategoryGridCellEditor } from "./grid/QuestionCategoryGridCellEditor"
import { Accordion, Button, Container } from "react-bootstrap"
import { QuestionCategoryGridCellRenderer } from "./grid/QuestionCategoryGridCellRenderer"
import { AskMeSenpaiServiceContext } from "./reducer/AskMeSenapiService"
import { CreateCategory } from "./CreateCategory"
import { QuestionDeleteGridCellRenderer } from "./grid/QuestionDeleteGridCellRenderer"
import { CategoryDeleteGridCellRenderer } from "./grid/CategoryDeleteGridCellRenderer"
import { CellEditRequestEvent } from "@ag-grid-community/core"
import { setInitialized } from "./reducer/askMeSenpaiReducer"
import { getCategoryNameFromId } from "./AskMeSenpaiHelper"
import { CreateQuestion } from "./CreateQuestion"
import { RandomQuestion } from "./RandomQuestion"
import { QuestionsGridCategoryFilter, QuestionsGridCategoryFloatingFilter } from "./grid/QuestionsGridCategoryFilter"
import { useTranslation } from "react-i18next"
import { ThemeServiceContext } from "../../core/Themes"

//Questions Grid DataType
export interface AskMeSenpaiManagementGridModel extends QuestionModel {
    categoryName: string
}

export const AskMeSenpai = () => {
    //the important stuffs
    const askMeSenpaiService = React.useContext(AskMeSenpaiServiceContext)
    const dispatch = useAppDispatch() as any

    //redux state
    const isInitialized = useAppSelector((state) => state.askMeSenpaiState.isInitialized)
    const isSocketInitialized = useAppSelector((state) => state.socketState.isConnected)
    const questions = useAppSelector((state) => state.askMeSenpaiState.questions)
    const categories = useAppSelector((state) => state.askMeSenpaiState.categories)

    //Grids
    const gridRef = useRef<AgGridReact<AskMeSenpaiManagementGridModel>>(null)
    const gridRefCategories = useRef<AgGridReact<QuestionCategoryModel>>(null)

    //scale grid on window resize to "adapt to fluid behavior of bootstrap container
    window.addEventListener("resize", () => {
        gridRef.current?.api.sizeColumnsToFit()
        gridRefCategories.current?.api.sizeColumnsToFit()
    })

    // take care of resizing
    const onGridReadyCategories = useCallback(() => {
        gridRefCategories.current!.api.sizeColumnsToFit()
    }, [])
    const onGridReady = useCallback(() => {
        gridRef.current!.api.sizeColumnsToFit()
    }, [])

    //edit Handler when any cell is edited of the Questions Grid - currently only text field supported
    const onGridCellEditing = (e: CellEditRequestEvent) => {
        const model: QuestionModel = { ...e.data }
        const newValue = e.newValue
        if (e.column.getColId() === "text") {
            model.text = newValue
            askMeSenpaiService.modifyQuestion(model)
        }
    }
    //edit Handler when any cell is edited of the Questions Category Grid
    const onGridCategoryCellEditing = (e: CellEditRequestEvent) => {
        const model: QuestionCategoryModel = { ...e.data }
        const newValue = e.newValue
        if (e.column.getColId() === "categoryName") {
            if (newValue === "") {
                return
            }
            model.categoryName = newValue
        }
        if (e.column.getColId() === "categoryDescription") {
            model.categoryDescription = newValue
        }
        if (e.data.categoryName !== noneCategory.categoryName) {
            askMeSenpaiService.modifyQuestionCategory(model)
        }
    }
    const { t } = useTranslation()

    const noneCategory: QuestionCategoryModel = {
        categoryDescription: "",
        categoryName: t("GENERIC_None"),
        questionCategoryId: 0
    }
    // Categories Grid Column Definitions
    const columnDefsCategories = [
        { field: "id", minWidth: 65, maxWidth: 65 },
        { field: "categoryName", headerName: t("GENERIC_Category"), filter: true, editable: (params) => params.data.categoryName !== noneCategory.categoryName, minWidth: 65 },
        { field: "categoryDescription", headerName: t("GENERIC_Description"), minWidth: 65, filter: true, editable: (params) => params.data.categoryName !== noneCategory.categoryName },
        {
            headerName: t("GENERIC_Delete"),
            field: "id",
            minWidth: 100,
            maxWidth: 100,
            cellRenderer: CategoryDeleteGridCellRenderer,
            editable: false,
        },
    ]

    // Questions Grid Column Definitions
    const askMeSenpaiColumnDefs = [
        { field: "id", minWidth: 65, maxWidth: 65 },
        { field: "text", headerName: t("GENERIC_Question"), editable: true, minWidth: 200, filter: true },
        { field: "numberOfTimesShown", headerName: "#Nani?", minWidth: 120, maxWidth: 120, filter: "agNumberColumnFilter" },
        { field: "numberOfTimesAnsweredCorrectly", headerName: "#Pass!", minWidth: 120, maxWidth: 120, filter: "agNumberColumnFilter" },
        {
            headerName: t("GENERIC_Category"),
            field: "categoryId",
            minWidth: 120,
            maxWidth: 120,
            cellRenderer: QuestionCategoryGridCellRenderer,
            cellEditor: QuestionCategoryGridCellEditor,
            editable: true,
            cellEditorPopup: true,
            floatingFilterComponent: QuestionsGridCategoryFloatingFilter,
            floatingFilterComponentParams: {
                suppressFloatingFilterButton: true,
                color: "red",
            },
            filter: QuestionsGridCategoryFilter,
        },
        {
            headerName: t("GENERIC_Delete"),
            minWidth: 100,
            maxWidth: 100,
            cellRenderer: QuestionDeleteGridCellRenderer,

            editable: false,
            filter: false,
            resizable: false
        },
    ]

    //default Grid Config - to save some typing above
    const defaultAskMeSenpaiColumnDefs = {
        resizable: true,
        sortable: true,
        filter: false,
        floatingFilter: true,
        editable: false
    }

    //updated on every notification which changes the external state
    useEffect(() => {
        if (isSocketInitialized && !isInitialized) {
            askMeSenpaiService.readQuestions()
            askMeSenpaiService.readCategories()
            dispatch(setInitialized())
        }
    }, [isSocketInitialized, isInitialized])

    //Question Grid Data has "data derived out of the model"
    const gridData = React.useMemo(() => {
        return questions?.map((val) => {
            return {
                categoryName: getCategoryNameFromId(val.categoryId, categories),
                ...val
            }
        })
    }, [questions, categories])

    const categoriesData = React.useMemo(() => {
        return [noneCategory, ...categories]
    }, [categories])

    const themeService = React.useContext(ThemeServiceContext)

    return (
        <Container>
            <RandomQuestion />
            <hr></hr>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t("SENPAI_AddQuestion")}</Accordion.Header>
                    <Accordion.Body >
                        <CreateQuestion />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t("SENPAI_AddCategory")}</Accordion.Header>
                    <Accordion.Body>
                        <CreateCategory />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>{t("SENPAI_Import")}</Accordion.Header>
                    <Accordion.Body>
                        <h1 id="tabelLabel" >{t("SENPAI_Import")}</h1>
                        <Button variant="primary" onClick={askMeSenpaiService.importData} >{t("GENERIC_Import")}</Button>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <hr></hr>
            <h1 id="tabelLabel" >{t("SENPAI_ManageQuestions")}</h1>
            <div className={themeService.themeAsAGGridIdentifer} style={{ height: 500 }}>
                <AgGridReact<AskMeSenpaiManagementGridModel>
                    reactiveCustomComponents={true}
                    readOnlyEdit={true}
                    ref={gridRef}
                    pagination={true}
                    paginationPageSize={20}
                    rowData={gridData}
                    columnDefs={askMeSenpaiColumnDefs}
                    defaultColDef={defaultAskMeSenpaiColumnDefs}
                    animateRows={true}
                    rowSelection="multiple"
                    onGridReady={onGridReady}
                    onCellEditRequest={onGridCellEditing}
                />
            </div>
            <hr></hr>
            <h1 id="tabelLabel" >{t("SENPAI_ManageCategories")}</h1>
            <div className={themeService.themeAsAGGridIdentifer} style={{ height: 400 }}>
                <AgGridReact<QuestionCategoryModel>
                    reactiveCustomComponents={true}
                    readOnlyEdit={true}
                    ref={gridRefCategories}
                    pagination={true}
                    paginationPageSize={20}
                    rowData={categoriesData}
                    columnDefs={columnDefsCategories}
                    defaultColDef={defaultAskMeSenpaiColumnDefs}
                    animateRows={true}
                    rowSelection="multiple"
                    onGridReady={onGridReadyCategories}
                    onCellEditRequest={onGridCategoryCellEditing}
                />
            </div>
        </Container>
    )
}