import * as React from "react"
import { Button, Form, Stack } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { QuestionCategoryModel } from "../../@types/QuestionCategoryModel"
import { useAppSelector } from "../../app/hooks"
import { SingleSelect } from "../../elements/Select"
import { TextField, TextFieldType } from "../../elements/TextField"
import { AskMeSenpaiServiceContext } from "./reducer/AskMeSenapiService"

export const CreateQuestion = () => {
    //the important stuffs
    const askMeSenpaiService = React.useContext(AskMeSenpaiServiceContext)
    const categories = useAppSelector((state) => state.askMeSenpaiState.categories)
    //input states
    const [questionToUpload, setQuestionToUpload] = React.useState("")
    const [questionToUploadHasInputError, setQuestionToUploadHasInputError] = React.useState(true)
    const [selectedCategoryId, setSelectedCategoryId] = React.useState<number>(0)

    const uploadQuestion = () => {
        askMeSenpaiService.uploadQuestion(questionToUpload, selectedCategoryId).then(() => {
            setQuestionToUpload("")
        })
    }
    const { t } = useTranslation()

    const noneCategory: QuestionCategoryModel = {
        categoryDescription: "",
        categoryName: t("GENERIC_None"),
        questionCategoryId: 0
    }

    const categoriesData = React.useMemo(() => {
        return [noneCategory, ...categories].sort((x, y) => x.questionCategoryId - y.questionCategoryId)
    }, [categories])

    const options = categoriesData?.map((cat: QuestionCategoryModel, i: number) => {
        return { value: cat.questionCategoryId, label: cat.categoryName }
    })

    return (
        <div>
            <TextField label={t("GENERIC_Question")} type={TextFieldType.Required} onChange={setQuestionToUpload} callback={setQuestionToUploadHasInputError} value={questionToUpload} submitCallback={uploadQuestion} />
            <Stack direction="horizontal" gap={3}>
                <Button variant="primary" disabled={questionToUploadHasInputError} onClick={uploadQuestion} >{t("GENERIC_Ok")}</Button>
                <SingleSelect onChange={(event: any) => {
                    setSelectedCategoryId(event.value)
                }} options={options} />

            </Stack>
        </div>
    )
}