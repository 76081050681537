import * as React from "react"
import { Button, Container, Stack } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { PartnerlistGroupModel } from "../../@types/PartnerlistGroupModel"
import { PartnerlistItemModel } from "../../@types/PartnerlistItemModel"
import { useAppSelector } from "../../app/hooks"
import { TextField, TextFieldType } from "../../elements/TextField"
import PartnerlistTreeView, { IPartnerlistTreeViewItem } from "./PartnerlistTreeView"
import { PartnerlistServiceContext } from "./reducer/PartnerlistService"
//for Drag and drop plugin
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { PartnerLifeTimeState } from "../../@types/PartnerLifeTimeState"
import { PARTNERLIST_NONEGROUP_ID } from "./DragAndDropWrapper"

export const Partnerlist = () => {
    const { t } = useTranslation()
    const partnerlistService = React.useContext(PartnerlistServiceContext)
    const partners = useAppSelector((state) => state.partnerlistState.partners)
    const groups = useAppSelector((state) => state.partnerlistState.groups)

    const hasSocket = useAppSelector((state) => state.socketState.isConnected)
    const [addPartnerlistEmail, setAddPartnerlistEmail] = React.useState("")
    const [addPartnerlistGroup, setAddPartnerlistGroup] = React.useState("")
    const [hasInputErrorAddPartner, setHasInputErrorAddPartner] = React.useState(true)
    const [hasInputErrorAddGroup, setHasInputErrorAddGroup] = React.useState(true)

    const addPartner = () => {
        const model: PartnerlistItemModel = {
            partnerUserId: "",
            userId: "",
            displayName: addPartnerlistEmail,
            partnerlistItemId: 0,
            email: addPartnerlistEmail,
            state: PartnerLifeTimeState.PendingApproval,
            groupId: 0,
        }
        partnerlistService.addPartner(model).then(() => {
            setAddPartnerlistEmail("")
        })
    }

    const addGroup = () => {
        const model: PartnerlistGroupModel = {
            displayName: addPartnerlistGroup,
            userId: "",
            parentGroupId: null,
            groupId: 0,
        }
        partnerlistService.addGroup(model).then(() => {
            setAddPartnerlistGroup("")
        })
    }

    const aggregateGroup = (group: PartnerlistGroupModel) => {
        if (group.parentGroupId) {
            return undefined
        }
        const partnerNodes = partners?.map<IPartnerlistTreeViewItem>((node: PartnerlistItemModel) =>
            convertPartner(node, group?.groupId)
        )
        const groupNodes = groups?.map<IPartnerlistTreeViewItem>((node: PartnerlistItemModel) =>
            convertGroup(node, group?.groupId)
        )

        const result: IPartnerlistTreeViewItem = {
            key: "g" + group.groupId,
            groupModel: group,
            partnerModel: undefined,
            children: partnerNodes.filter(x => x !== undefined).concat(groupNodes.filter(x => x !== undefined))
        }
        return result
    }

    const convertPartner = (partner: PartnerlistItemModel, groupId: number) => {
        if (partner.groupId === groupId) {
            const result: IPartnerlistTreeViewItem = {
                key: "p" + partner.partnerlistItemId,
                partnerModel: partner,
                groupModel: undefined,
            }
            return result
        }
    }

    const convertGroup = (group: PartnerlistGroupModel, groupId: number) => {
        if (group.parentGroupId === groupId) {
            const result: IPartnerlistTreeViewItem = {
                key: "g" + group.groupId,
                groupModel: group,
                partnerModel: undefined,
                children: partners?.map<IPartnerlistTreeViewItem>((node: PartnerlistItemModel) => convertPartner(node, group?.groupId)).filter(x => x !== undefined).concat(groups?.map<IPartnerlistTreeViewItem>((node: PartnerlistItemModel) => convertGroup(node, group?.groupId)).filter(x => x !== undefined))
            }
            return result
        }
    }

    const partnerlistData = React.useMemo(() => {
        const groupFragment = groups.map<IPartnerlistTreeViewItem>((node: PartnerlistGroupModel) => (
            aggregateGroup(node)
        ))
        const partnersWithoutGroups = partners?.map<IPartnerlistTreeViewItem>((node: PartnerlistItemModel) =>
            convertPartner(node, undefined)
        ).filter(x => x !== undefined)

        const allPartnersAndGroups = [{
            groupModel: {
                displayName: t("PARTNERLIST_DRAGHERETOREMOVE"),
                groupId: PARTNERLIST_NONEGROUP_ID,
                parentGroupId: undefined,
                userId: ""
            },
            key: "g-1",
            partnerModel: undefined,
            children: undefined
        }, ...partnersWithoutGroups.concat(groupFragment).filter(x => x !== undefined)]
        return allPartnersAndGroups
    }, [partners, groups])

    return (
        <Container>
            <DndProvider backend={HTML5Backend}>
                <If condition={hasSocket}>
                    <PartnerlistTreeView data={partnerlistData} />
                    <hr />
                    <Stack direction={"horizontal"}>
                        <TextField
                            type={TextFieldType.Email}
                            label={t("PARTNERLIST_ADD_Partner")}
                            onChange={setAddPartnerlistEmail}
                            value={addPartnerlistEmail}
                            submitCallback={addPartner}
                            callback={setHasInputErrorAddPartner}
                            hideValidation={true}
                        />
                        <Button variant="primary" hidden={!addPartnerlistEmail || hasInputErrorAddPartner} onClick={addPartner} >{t("GENERIC_Ok")}</Button>
                    </Stack>
                    <Stack direction={"horizontal"}>
                        <TextField
                            type={TextFieldType.Required}
                            label={t("PARTNERLIST_ADD_Group")}
                            onChange={setAddPartnerlistGroup}
                            value={addPartnerlistGroup}
                            callback={setHasInputErrorAddGroup}
                            submitCallback={addGroup}
                            hideValidation={true}
                        />
                        <Button variant="primary" hidden={!addPartnerlistGroup || hasInputErrorAddGroup} onClick={addGroup} >{t("GENERIC_Ok")}</Button>
                    </Stack>
                </If>
            </DndProvider>
        </Container >
    )
}