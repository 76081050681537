import * as React from "react"
import { Button, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { AccountServiceContext } from "../user/account/reducer/AccountService"

export const CookieConsent = () => {
    const accountService = React.useContext(AccountServiceContext)
    const [showCookieBanner, setShowCookieBanner] = React.useState(false)

    React.useEffect(() => {
        if (accountService) {
            accountService.getCookieConsentStatus().then((response) => {
                setShowCookieBanner(response.payload)
            })
        }
    }, [accountService])
    const { t } = useTranslation()
    const handleCloseCookieBanner = () => {
        accountService.giveCookieConsent().then(() => {
            setShowCookieBanner(false)
        })
    }
    return (
        <div>
            <Modal show={showCookieBanner} >
                <Modal.Header>
                    <Modal.Title>{t("COOKIEPOLICY_Title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body >{t("COOKIEPOLICY_Body")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCookieBanner}>
                        {t("GENERIC_Ok")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>)
}