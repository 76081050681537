import * as React from "react"
import { useState, useEffect } from "react"
import { TextField, TextFieldType } from "../../elements/TextField"
import { useAppSelector, useAppDispatch } from "../../app/hooks"
import { ToDoItemModel } from "../../@types/ToDoItemModel"
import { ToDoServiceContext } from "./reducer/ToDoService"
import { useTranslation } from "react-i18next"
import { Button, Container, Table } from "react-bootstrap"
import { DateTime } from "luxon"

export const ToDoList = () => {
    //redux state
    const toDoItems = useAppSelector((state) => state.toDoListState.toDoItems)
    const dispatch = useAppDispatch() as any
    const initialized = useAppSelector((state) => state.toDoListState.initialized)
    const toDoService = React.useContext(ToDoServiceContext)
    const language = useAppSelector((state) => state.profileState.language)
    //internal state
    const [toDoItemToUpload, setToDoItemToUpload] = useState("")
    const [currentToDoItem, setCurrentToDoItem] = useState(null)

    useEffect(() => {
        if (!initialized) {
            toDoService.readToDoItems()
        }
    }, [initialized, toDoItems, dispatch])

    const { t } = useTranslation()

    const onSend = () => {
        toDoService.uploadToDoItem(toDoItemToUpload)
    }

    const onDelete = () => {
        toDoService.deleteToDoItem(currentToDoItem)
    }
    
    return (
        <Container>
            <h1 id="tabelLabel" >{t("TODO_Create")}</h1>
            <TextField
                type={TextFieldType.Required}
                label={t("GENERIC_Description")}
                onChange={setToDoItemToUpload}
                value={toDoItemToUpload}
                submitCallback={onSend} />
            <p> <Button variant="primary" disabled={!toDoItemToUpload} onClick={onSend} >{t("GENERIC_Ok")}</Button></p>

            <Table striped bordered hover variant="secondary">
                <thead>
                    <tr>
                        <th>{t("GENERIC_Description")}</th>
                        <th>{t("GENERIC_Priority")}</th>
                        <th>{t("GENERIC_Status")}</th>
                        <th>{t("GENERIC_CreatedAt")}</th>
                        <th>{t("GENERIC_Modify")}</th>
                        <th>{t("GENERIC_DueDate")}</th>
                    </tr>
                </thead>

                <tbody>
                    {toDoItems?.map((item: ToDoItemModel, index: number) => (
                        <tr key={item?.toDoItemId} onMouseOver={() => setCurrentToDoItem(item)}>
                            <td>{item?.text}</td>
                            <td>{item?.priority}</td>
                            <td>{item?.status}</td>
                            <td>{DateTime.fromISO(item?.createdAt).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}</td>
                            <td>{DateTime.fromISO(item?.dueDate).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}</td>
                            <td><Button variant="secondary" onClick={onDelete} >{t("GENERIC_Delete")}</Button></td>
                        </tr>                        
                    ))}
                </tbody>
            </Table>
        </Container>
    )
}