import { ICellRendererParams } from "@ag-grid-community/core"
import * as React from "react"
import { Button, Modal, Nav } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { AskMeSenpaiServiceContext } from "../reducer/AskMeSenapiService"

export const CategoryDeleteGridCellRenderer = (props: ICellRendererParams) => {
    const askMeSenpaiService = React.useContext(AskMeSenpaiServiceContext)
    const [show, setShow] = React.useState<boolean>(false)
    const onDelete = () => {
        setShow(true)
    }
    const { t } = useTranslation()

    const onClick = (confirm: boolean) => {
        if (confirm) {
            askMeSenpaiService.deleteCategory(props.data)
        }
        setShow(false)
    }

    return (
        <div>
            <Modal show={show} dialogClassName="popup popupWarning" >
                <Modal.Header closeButton onHide={() => onClick(false)} >
                    <Modal.Title>{t("GENERIC_CONFIRM_Title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body >{t("GENERIC_CONFIRM_Body")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onClick(false)}>
                        {t("GENERIC_Cancel")}
                    </Button>
                    <Button variant="primary" onClick={() => onClick(true)}>
                        {t("GENERIC_Ok")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <If condition={props.data.id !== 0}>
                <Nav.Link className="link-secondary" onClick={onDelete}>{t("GENERIC_Delete")}</Nav.Link>
            </If>
        </div>
    )
}