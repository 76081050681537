import React from "react"
import { Nav } from "react-bootstrap"

import { SketchPicker } from "react-color"
import { useTranslation } from "react-i18next"
import { ThemeServiceContext } from "./Themes"

export const ColorThemePicker = () => {
    const themeService = React.useContext(ThemeServiceContext)
    const { t } = useTranslation()
    const initState = {
        displayColorPicker: false,
        color: themeService.accentColor ?? "darkmagenta",
    }

    const [state, setState] = React.useState(initState)

    const handleClick = () => {
        if (state.displayColorPicker) {
            handleClose()
        } else {
            setState({ displayColorPicker: !state.displayColorPicker, color: state.color })
        }
    }

    const handleClose = () => {
        setState({ displayColorPicker: false, color: state.color })
    }

    const handleChange = (color) => {
        setState({ color: color.rgb, displayColorPicker: state.displayColorPicker })
        themeService.changeColor(color)
    }

    return (
        <div className="nav-link d-inline-flex" >
            <div id="colorthemepicker" className="pointer mt-1 me-1" onClick={handleClick}>
                <div className="picker-preview" />
            </div>
            <If condition={state.displayColorPicker}>
                <div className="picker-popover" >
                    <div className="picker-cover ">
                        <SketchPicker color={state.color} onChange={handleChange} />
                    </div>
                </div>
            </If>
            <Nav.Item className="pointer" onClick={handleClick} >{t("NAV_AccentColor")}</Nav.Item>
        </div>
    )
}