import * as React from "react"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { useSearchParams } from "react-router-dom"
import { QueryParameterNames } from "../../../app/AppConstants"

import { TextField, TextFieldType } from "../../../elements/TextField"
import { AccountServiceContext } from "./reducer/AccountService"

export enum ForgotPasswordPageState {
    Request,
    Reset,
}
export interface IForgotPasswordPageProps {
    pageState: ForgotPasswordPageState
}
export const ForgotPassword = (props: IForgotPasswordPageProps) => {
    const accountService = React.useContext(AccountServiceContext)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [hasInputErrorEmail, setHasInputErrorEmail] = useState(true)
    const [hasInputErrorPassword, setHasInputErrorPassword] = useState(true)
    const [hasInputErrorConfirmPassword, setHasInputErrorConfirmPassword] = useState(true)

    const [hasInputError, setHasInputError] = useState(true)

    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)

    const code = urlParams.get(QueryParameterNames.Code)
    const userId = urlParams.get(QueryParameterNames.UserId)

    const [searchParams, setSearchParams] = useSearchParams()

    const onClickRequest = () => {
        if (hasInputErrorEmail) {
            return
        }
        accountService.requestResetPassword(email)
        setEmail("")
    }
    const { t } = useTranslation()

    const onClickReset = () => {
        if (hasInputError) {
            return
        }
        accountService.resetPassword(code, password, userId).then(setSearchParams(""))
        setPassword("")
        setConfirmPassword("")
    }

    React.useEffect(() => {
        setHasInputError(hasInputErrorPassword || hasInputErrorConfirmPassword || (confirmPassword !== password))
    }, [hasInputErrorPassword, hasInputErrorConfirmPassword, confirmPassword, password])

    return (
        <div>
            <If condition={props.pageState === ForgotPasswordPageState.Request}>
                <div>
                    <h2>{t("EMAILSERVICE_HEADER_ResetPassword")}</h2>
                    <hr />
                    <div className="form-floating">
                        <TextField label={t("GENERIC_Email")} onChange={setEmail} type={TextFieldType.Email} callback={setHasInputErrorEmail} value={email} submitCallback={onClickRequest} />
                    </div>
                    <Button variant="primary" onClick={onClickRequest} disabled={hasInputErrorEmail} >{t("GENERIC_Ok")}</Button>
                </div>
            </If>
            <If condition={props.pageState === ForgotPasswordPageState.Reset}>
                <div>
                    <h2>{t("RESETPASSWORD_Prompt")}</h2>
                    <hr />
                    <div className="form-floating">
                        <TextField label={t("GENERIC_Password")} onChange={setPassword} type={TextFieldType.Password} callback={setHasInputErrorPassword} value={password} submitCallback={onClickReset} />
                    </div>
                    <div className="form-floating">
                        <TextField label={t("PROFILE_ConfirmPassword")} onChange={setConfirmPassword} type={TextFieldType.Password} callback={setHasInputErrorConfirmPassword} value={confirmPassword} submitCallback={onClickReset} />
                    </div>
                    <If condition={confirmPassword !== password}>
                        <span className="text-danger">{t("errors:SPA_VALIDATION_PasswordsDoNotMatch")}</span>
                    </If>
                    <Button variant="primary" onClick={onClickReset} disabled={hasInputError}>{t("RESETPASSWORD_Reset")} </Button>
                </div>
            </If>
        </div>

    )
}