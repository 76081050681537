import styled from "@emotion/styled"
import React from "react"
import { ArrowBarLeft, ArrowBarRight } from "react-bootstrap-icons"

interface SidebarFooterProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode
    collapsed?: boolean,
    setCollapsed: any,
}

const StyledSidebarFooter = styled.div`
  //width: 50%;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: center;
  //padding: 20px;
  //border-radius: 8px;
  //color: white;
  //background: linear-gradient(45deg, rgb(21 87 205) 0%, rgb(90 225 255) 100%);
  //background: #0098e5
`

const StyledCollapsedSidebarFooter = styled.a`
  //width: 40px;
  //height: 40px;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: center;
  //cursor: pointer;
  //border-radius: 50%;
  //color: white;
  //background: linear-gradient(45deg, rgb(21 87 205) 0%, rgb(90 225 255) 100%);
  //background: #0098e5
`


export const SidebarFooter: React.FC<SidebarFooterProps> = ({ children, collapsed, setCollapsed, ...rest }) => {


    return (
        <div
            style={{
                display: "flex",
                justifyContent: "end",
                paddingBottom: "10px",
                paddingRight: "10px"
            }}
        >
            <If condition={collapsed}>
                <StyledCollapsedSidebarFooter>
                    <ArrowBarRight color="var(--bs-secondary)" className="pointer" size="2rem" onClick={() => { setCollapsed(!collapsed) }} />
                </StyledCollapsedSidebarFooter>

            </If>
            <If condition={!collapsed}>
                <StyledSidebarFooter>
                    <ArrowBarLeft color="var(--bs-secondary)" className="pointer" size="2rem" onClick={() => { setCollapsed(!collapsed) }} />
                </StyledSidebarFooter>

            </If>
        </div>
    )
}