import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import { PartnerlistItemModel } from "../../../@types/PartnerlistItemModel"
import { PartnerlistGroupModel } from "../../../@types/PartnerlistGroupModel"
import { OnlineState } from "../../../@types/OnlineState"
import { OnlineStateModel } from "../../../@types/OnlineStateModel"

export type PartnerlistState = {
    partners: Array<PartnerlistItemModel>
    groups: Array<PartnerlistGroupModel>
    ownOnlineState: OnlineState,
    isInitialized: boolean
}

const initialState: PartnerlistState = {
    partners: [],
    groups: [],
    isInitialized: false,
    ownOnlineState: OnlineState.Offline
}

export const partnerlistReducer = createSlice({
    name: "partnerlistReducer",
    initialState,
    reducers: {
        addPartner: (state: PartnerlistState, action: PayloadAction<PartnerlistItemModel>) => {
            if (action.payload === undefined) {
                return
            }
            if (state.partners.find(x => x.partnerlistItemId == action.payload.partnerlistItemId)) {
                return
            }
            state.partners.push(action.payload)
        },
        removePartner: (state: PartnerlistState, action: PayloadAction<PartnerlistItemModel>) => {
            state.partners.forEach((item: PartnerlistItemModel, index) => {
                if (item.partnerlistItemId === action.payload.partnerlistItemId) state.partners.splice(index, 1)
            })
        },
        editPartner: (state: PartnerlistState, action: PayloadAction<PartnerlistItemModel>) => {
            if (action.payload === undefined) {
                return
            }
            state.partners.forEach((item: PartnerlistItemModel, index) => {
                if (item.partnerlistItemId === action.payload.partnerlistItemId) {
                    state.partners[index] = action.payload
                    return
                }
            })
        },
        loadAllPartners: (state: PartnerlistState, action: PayloadAction<PartnerlistItemModel[]>) => {
            state.partners = action.payload
        },
        reset: (state: PartnerlistState) => {
            state.isInitialized = false
        },
        setInitialized: (state: PartnerlistState) => {
            state.isInitialized = true
        },
        loadAllGroups: (state: PartnerlistState, action: PayloadAction<PartnerlistGroupModel[]>) => {
            if (action.payload === undefined || action.payload.length === 0) {
                return
            }
            state.groups = action.payload
        },
        addGroup: (state: PartnerlistState, action: PayloadAction<PartnerlistGroupModel>) => {
            if (action.payload === undefined) {
                return
            }
            state.groups.push(action.payload)
        },
        removeGroup: (state: PartnerlistState, action: PayloadAction<PartnerlistGroupModel>) => {
            state.groups.forEach((item: PartnerlistGroupModel, index) => {
                if (item.groupId === action.payload.groupId) state.groups.splice(index, 1)
            })
        },
        editGroup: (state: PartnerlistState, action: PayloadAction<PartnerlistGroupModel>) => {
            if (action.payload === undefined) {
                return
            }
            state.groups.forEach((item: PartnerlistGroupModel, index) => {
                if (item.groupId === action.payload.groupId) {
                    state.groups[index] = action.payload
                    return
                }
            })
        },
        setOnlineState: (state: PartnerlistState, action: PayloadAction<OnlineStateModel>) => {
            state.ownOnlineState = action.payload.onlineState
        },
        setOnlineStateForPartner: (state: PartnerlistState, action: PayloadAction<OnlineStateModel>) => {
            state.partners.forEach((item: PartnerlistItemModel, index) => {
                if (item.partnerUserId === action.payload.userId) {
                    state.partners[index].onlineState = action.payload.onlineState
                    state.partners[index].lastSeen = action.payload.lastSeen
                    return
                }
            })
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    addPartner,
    removePartner,
    editPartner,
    loadAllPartners,
    reset,
    setInitialized,
    loadAllGroups,
    addGroup,
    removeGroup,
    editGroup,
    setOnlineState,
    setOnlineStateForPartner
} = partnerlistReducer.actions

// Other code such as selectors can use the imported `RootState` type
//do we need this?
export const partnerlistState = (globalState: RootState) => globalState.partnerlistState

export default partnerlistReducer.reducer