import * as React from "react"
import { useTranslation } from "react-i18next"
import { AuthenticationSchemeModel } from "../../../../@types/AuthenticationSchemeModel"
import { ConfirmEmailModel } from "../../../../@types/ConfirmEmailModel"
import { LoginModel } from "../../../../@types/LoginModel"
import { PasswordResetModel } from "../../../../@types/PasswordResetModel"
import { RequestActivationCodeModel } from "../../../../@types/RequestActivationCodeModel"
import { RequestPasswordResetModel } from "../../../../@types/RequestPasswordResetModel"
import { SignUpModel } from "../../../../@types/SignUpModel"
import { SignUpResponse } from "../../../../@types/SignUpResponse"
import { SuitamoiApiStatus } from "../../../../@types/SuitamoiApiStatus"
import { TfaLoginModel } from "../../../../@types/TfaLoginModel"
import { TfaRecoveryLoginModel } from "../../../../@types/TfaRecoveryLoginModel"
import { IdentityApiPaths } from "../../../../app/AppConstants"
import { useAppDispatch } from "../../../../app/hooks"
import { ApiRequest, RequestVerb } from "../../../../core/network/DataRequest"
import { PopupType, show } from "../../../popupmessage/reducer/popupReducer"

export interface IAccountServiceContext {
    readExternalLogins()
    signUp(model: SignUpModel, callback: any)
    confirmEmail(model: ConfirmEmailModel)
    requestResetPassword(email: string)
    resetPassword(code: string, password: string, email: string)
    requestActivationMail(model: RequestActivationCodeModel)
    getCookieConsentStatus()
    giveCookieConsent()
    login(userName: string, password: string, rememberMe: boolean)
    loginTfa(model: TfaLoginModel)
    loginTfaRecoveryCode(tfaRecoveryCode: string)
}

export const AccountServiceContext = React.createContext<IAccountServiceContext | undefined>(undefined)

export const useAccountServiceContext = () => {
    return React.useContext<IAccountServiceContext | undefined>(undefined)
}

const AccountService = ({ children }: any) => {
    const dispatch = useAppDispatch() as any
    const { t } = useTranslation()

    const accountService = {
        async readExternalLogins() {
            return await ApiRequest<null, AuthenticationSchemeModel>({
                route: IdentityApiPaths.ReadExternalLogins,
                verb: RequestVerb.Get
            })
        },
        async getCookieConsentStatus() {
            return await ApiRequest<null, boolean>({
                route: IdentityApiPaths.CookieConsentStatus,
                verb: RequestVerb.Post
            })
        },
        async giveCookieConsent() {
            return await ApiRequest({
                route: IdentityApiPaths.GiveCookieConsent,
                verb: RequestVerb.Post
            })
        },
        async login(userName: string, password: string, rememberMe: boolean) {
            const model: LoginModel = {
                email: userName,
                password: password,
                rememberMe: rememberMe
            }

            return await ApiRequest<LoginModel, null>({
                payload: model,
                route: IdentityApiPaths.Login,
                verb: RequestVerb.Post
            })
        },
        async loginTfa(model: TfaLoginModel) {
            return await ApiRequest<TfaLoginModel, null>({
                payload: model,
                route: IdentityApiPaths.LoginTFA,
                verb: RequestVerb.Post
            })
        },
        async loginTfaRecoveryCode(tfaRecoveryCode: string) {
            const model: TfaRecoveryLoginModel = {
                recoveryCode: tfaRecoveryCode
            }

            return await ApiRequest<TfaRecoveryLoginModel, null>({
                payload: model,
                route: IdentityApiPaths.LoginTFARecovery,
                verb: RequestVerb.Post
            })
        },
        async confirmEmail(model: ConfirmEmailModel) {
            ApiRequest<ConfirmEmailModel, null>({
                payload: model,
                route: IdentityApiPaths.ConfirmEmail,
                verb: RequestVerb.Post
            })
                .then(response => {
                    if (response.statusCode === SuitamoiApiStatus.Success) {
                        const popup = {
                            showPopup: true,
                            content: t("ACTIVATION_Success"),
                            popupType: PopupType.Normal,
                            headline: t("GENERIC_Success")
                        }
                        dispatch(show(popup))
                    }
                })
        },
        async signUp(model: SignUpModel, callback: any) {
            ApiRequest<SignUpModel, SignUpResponse>({
                payload: model,
                route: IdentityApiPaths.SignUpLocal,
                verb: RequestVerb.Post
            })
                .then(response => {
                    if (response.statusCode === SuitamoiApiStatus.Success) {
                        callback(response.payload.isSuccess)
                    }
                })
        },
        async requestResetPassword(email: string) {
            const model: RequestPasswordResetModel = {
                email: email
            }
            ApiRequest<RequestPasswordResetModel, null>({
                payload: model,
                route: IdentityApiPaths.RequestResetPassword,
                verb: RequestVerb.Post
            })
                .then(response => {
                    if (response.statusCode === SuitamoiApiStatus.Success) {
                        const popup = {
                            showPopup: true,
                            content: t("RESETPASSWORD_Confirm"),
                            popupType: PopupType.Normal,
                            headline: t("GENERIC_Success")
                        }
                        dispatch(show(popup))
                    }
                })
        },
        async resetPassword(code: string, password: string, email: string) {
            const model: PasswordResetModel = {
                code: code,
                password: password,
                email: email
            }
            ApiRequest<PasswordResetModel, null>({
                payload: model,
                route: IdentityApiPaths.ResetPassword,
                verb: RequestVerb.Post
            })
                .then(response => {
                    if (response.statusCode === SuitamoiApiStatus.Success) {
                        const popup = {
                            showPopup: true,
                            content: t("RESETPASSWORD_Success"),
                            popupType: PopupType.Normal,
                            headline: t("GENERIC_Success")
                        }
                        dispatch(show(popup))
                    }
                })
        },
        async requestActivationMail(model: RequestActivationCodeModel) {
            ApiRequest<RequestActivationCodeModel, null>({
                payload: model,
                route: IdentityApiPaths.ResendActivationEmail,
                verb: RequestVerb.Post
            })
                .then(response => {
                    if (response.statusCode === SuitamoiApiStatus.Success) {
                        const popup = {
                            showPopup: true,
                            content: t("ACTIVATION_Confirm"),
                            popupType: PopupType.Normal,
                            headline: t("GENERIC_Success")
                        }
                        dispatch(show(popup))
                    }
                })
        }
    }
    return (
        <div>
            <AccountServiceContext.Provider value={accountService}>
                {children}
            </AccountServiceContext.Provider>
        </div>
    )
}

export default AccountService