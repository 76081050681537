import * as React from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { IdentityApiPaths, QueryParameterNames } from "../../../app/AppConstants"
import { useAppSelector } from "../../../app/hooks"

export const ExternalLoginFragment = () => {
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const returnUrl = urlParams.get(QueryParameterNames.ReturnUrl)

    const state = useAppSelector((state) => state.appState)

    const provider = state.externalLoginProviders.length === 2 ? state.externalLoginProviders[1].name : ""
    const { t } = useTranslation()
    return (
        <div>
            <h2>{t("EXTERNALLOGINS_Title")}</h2>
            <hr />
            <If condition={state.externalLoginProviders!.length === 0}>
                <p>{t("EXTERNALLOGINS_NoneAvailable")}</p>
            </If>
            <If condition={state.externalLoginProviders!.length > 0}>
                <form action={IdentityApiPaths.LoginWithExternalProviderChallenge} method="post">
                    <input type="hidden" id="provider" name="provider" defaultValue={provider} />
                    <input type="hidden" id="returnUrl" name="returnUrl" defaultValue={returnUrl} />
                    <input className="w-100 btn btn-lg btn-primary" type="submit" value={provider} />
                </form>
            </If>
        </div>
    )
}