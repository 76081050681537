import * as React from "react"
import { useSwipeable } from "react-swipeable"
import { FooterMenu } from "../components/navmenu/FooterMenu"
import { NavMenu } from "../components/navmenu/NavMenu"
import { NotificationCenter } from "../components/navmenu/NotificationCenter"
import { SideBar } from "../components/navmenu/sidebar/Sidebar"


interface ILayoutProps {
    children: any;
}

export const Layout = (props: ILayoutProps) => {
    const { ref: refreshSiteOnSwipeHandler } = useSwipeable({
        delta: {
            down: 500
        },
        onSwipedDown: () => window.location.reload()
    })

    // attach swipeable to document
    React.useEffect(() => {
        refreshSiteOnSwipeHandler(document as any)
    })

    return (
        <div>
            <NavMenu />
            <div className="contentWrapper">
                <SideBar />
                <NotificationCenter />
                <main className="mainContent">
                    {props.children}
                </main>
            </div>
            <FooterMenu />

        </div>
    )
}