import * as React from "react"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { TextField, TextFieldType } from "../../../elements/TextField"
import { AccountServiceContext } from "./reducer/AccountService"

export const ResendActivationMail = () => {
    const [email, setEmail] = useState("")
    const [hasInputErrorEmail, setHasInputErrorEmail] = useState(true)
    const accountService = React.useContext(AccountServiceContext)

    const onClick = () => {
        if (hasInputErrorEmail) {
            return
        }
        accountService.requestActivationMail({ email: email })
        setEmail("")
    }
    const { t } = useTranslation()
    return (
        <div >
            <h2>{t("ACTIVATION_RequestMail")}</h2>
            <hr />
            <div className="form-floating">
                <TextField label={t("GENERIC_Email")} onChange={setEmail} type={TextFieldType.Email} callback={setHasInputErrorEmail} value={email} submitCallback={onClick} />
            </div>
            <Button variant="primary" onClick={onClick} disabled={hasInputErrorEmail} >{t("ACTIVATION_RequestMail")}</Button>
        </div>

    )
}