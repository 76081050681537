import * as React from "react"
import { SuitamoiApiResponse } from "./SuitamoiApiResponse"
import { PopupType, show } from "../../components/popupmessage/reducer/popupReducer"
import { SuitamoiApiStatus } from "../../@types/SuitamoiApiStatus"
import { store } from "../../app/store"
import { SuitamoiApiSubStatusCode } from "../../@types/SuitamoiApiSubStatusCode"
import { logout, setIsLoading } from "../../app/reducer/appStateReducer"
import i18n from "i18next"


//why else would we have a loading animation if we could not see it?
const delayTime = 0

export enum RequestVerb {
    Get = "GET",
    Post = "POST",
}

export interface RequestOptions<TModel extends any> {
    route: string
    verb: RequestVerb,
    returnUrl?: string | null
    payload?: TModel
}

export const ApiRequest =
    async <TRequestModel extends any, TResponse extends any>(requestOptions: RequestOptions<TRequestModel>): Promise<SuitamoiApiResponse<TResponse>> => {
        store.dispatch(setIsLoading(true))
        await new Promise(f => setTimeout(f, delayTime))
        const token = store.getState().appState.accessToken
        const init: RequestInit = {
            headers: !token ?
                { "Content-Type": "application/json; charset=utf-8" } :
                { "Content-Type": "application/json; charset=utf-8", "Authorization": `Bearer ${token}` },
            method: requestOptions.verb,
            body: requestOptions?.payload ? JSON.stringify(requestOptions.payload) : null
        }


        const response = await fetch(requestOptions.route, init)
            .then((data: any) => {
                if (!data.ok) {
                    switch (data.status) {
                        //on internal server error => logout stop everything
                        case 500: {
                            store.dispatch(logout())
                            break
                        }
                        case 401: {
                            //logout if the token has expired so user can login again.
                            store.dispatch(logout())
                            break
                        }
                    }
                    //reaching here creates excaptions and thus breaks all running requests. nice "accidental dos protection"
                    const errorResponse: SuitamoiApiResponse<TResponse> = {
                        exceptionMessage: data.status,
                        statusCode: SuitamoiApiStatus.Error,
                        subStatusCode: SuitamoiApiSubStatusCode.None
                    }
                    return errorResponse
                }
                if (data.redirected) {
                    return {
                        exceptionMessage: data.url,
                        statusCode: SuitamoiApiStatus.Redirect,
                        subStatusCode: SuitamoiApiSubStatusCode.None
                    }                    
                }
                //the response sohuld always be a suitamoiapiresponse parsable object due to the global exception handler
                const res = data.json() as Promise<SuitamoiApiResponse<TResponse>>
                return res
            })
            .catch((error) => {
                //happens only when the promise is rejected or there is a error in the .then block
                const errorResponse: SuitamoiApiResponse<TResponse> = {
                    exceptionMessage: error.message,
                    statusCode: SuitamoiApiStatus.Error,
                    subStatusCode: SuitamoiApiSubStatusCode.None
                }
                return errorResponse
            })
        //The repponse is always a SuitamoiApiResponse - show the error
        if (response.statusCode === SuitamoiApiStatus.Error) {
            const popup = {
                showPopup: true,
                content: response.exceptionMessage,
                popupType: PopupType.Error,
                headline: i18n.t("GENERIC_Error")
            }
            store.dispatch(show(popup))
        }
        //This is something undesired - the backend returned a non SuitamoiApiResonse - fix this
        if (response.statusCode === undefined) {
            const popup = {
                showPopup: true,
                content: (response as any).title,
                popupType: PopupType.Error,
                headline: `UNEXPECTED: HTTP Status Code ${(response as any).status}`
            }
            store.dispatch(show(popup))
        }
        //response is always returned - the receiving side should not need to handle the error
        store.dispatch(setIsLoading(false))
        return response
    }

export const DownloadRequest = async (url, filename) => {
    store.dispatch(setIsLoading(true))
    const token = store.getState().appState.accessToken
    await fetch(url, {
        headers: !token ?
            { "Content-Type": "application/json; charset=utf-8" } :
            { "Content-Type": "application/json; charset=utf-8", "Authorization": `Bearer ${token}` }
    }).then(function (t) {
        return t.blob().then((b) => {
            const a = document.createElement("a")
            a.href = URL.createObjectURL(b)
            a.setAttribute("download", filename)
            a.click()
            store.dispatch(setIsLoading(false))
        }
        )
    })
}
export const UploadFile = async (url, file) => {
    store.dispatch(setIsLoading(true))
    const data = new FormData()
    data.append("file", file)
    const token = store.getState().appState.accessToken
    await fetch(url, {
        method: RequestVerb.Post,
        headers: { "Authorization": `Bearer ${token}` },
        body: data
    }).then((data: any) => {
        if (!data.ok) {
            switch (data.status) {
                case 401: {
                    //logout if the token has expired so user can login again.
                    store.dispatch(logout())
                    break
                }
            }
            store.dispatch(setIsLoading(false))
            return false
        }
        store.dispatch(setIsLoading(false))
        return true
    })
}