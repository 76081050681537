import * as React from "react"
import { Modal } from "react-bootstrap"
import { Puff } from "react-loader-spinner"
import { useAppSelector } from "../app/hooks"

export const LoadingSpinner = () => {
    const appState = useAppSelector((state) => state.appState)
    const show = React.useMemo(() => {
        return appState.isLoading || !appState.isInitialized
    }, [appState.isLoading, appState.isInitialized])

    return (
        <Modal show={show} contentClassName="loadingSpinnerContent" centered={true} animation={false} backdrop={false}>
            <Modal.Body className="loadingSpinnerBody">
                <Puff
                    height="80"
                    width="80"
                    radius={1}
                    color="var(--color-theme-accent)"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={show}

                />
            </ Modal.Body>
        </Modal>
    )
}
