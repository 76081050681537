import * as React from "react"

import { UserManager, WebStorageStateStore, Log, User } from "oidc-client-ts"
import { ApplicationName, IdentityApiPaths } from "../../../app/AppConstants"

import { setIsLoggedIn, setToken } from "../../../app/reducer/appStateReducer"
import { useAppDispatch } from "../../../app/hooks"
import { useTranslation } from "react-i18next"
import { setUserId } from "../profile/reducer/profileReducer"
import { isIframe } from "../../../core/GenericHelper"
import { PopupState, PopupType, show, showError } from "../../popupmessage/reducer/popupReducer"
import { SuitamoiApiStatus } from "../../../@types/SuitamoiApiStatus"
import { ApiRequest, RequestVerb } from "../../../core/network/DataRequest"

export interface ISessionService {
    ensureUserManagerInitialized(): boolean
    signIn(): Promise<boolean>
    signOut(): Promise<boolean>
    completeSignIn(url: string)
    completeSignOut(url: string)
    checkSession()
    //loads the user into the SPA AFTER it has been signed in by the OIDC client
    loadUser()
}
export const SessionServiceContext = React.createContext<ISessionService | undefined>(undefined)

export const useAuthorizeServiceContext = () => {
    return React.useContext<ISessionService | undefined>(undefined)
}
// This class holds the OIDC UserManager which is the underlying lib to maintain the user session.
// Everything is theoretically covered by the oidc usermanager. However itself has no connection to the application itself.
// thus we need to link the state of the user manager with the state of the app.
// Doing this, is the purpose of this class
const SessionService = ({ children }: any) => {
    const [userManager, setUserManager] = React.useState<UserManager | null>(null)
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const externalSignOutError: PopupState = {
        showPopup: true,
        content: t("errors:SPA_LOGOUT_ERROR_ExternalSignOutError"),
        popupType: PopupType.Error,
        headline: t("GENERIC_Error")
    }

    const signOutError: PopupState = {
        showPopup: true,
        content: t("errors:SPA_LOGOUT_ERROR_SignOutError"),
        popupType: PopupType.Error,
        headline: t("GENERIC_Error")
    }

    const sessionService = {

        async signIn(): Promise<boolean> {
            try {
                //  is the only login we do - we sign in via POST in the SPA and use oidc client to do the rest.
                const silentUser = await userManager.signinSilent()
                updateUser(silentUser)
                return true
            } catch (e) {
                dispatch(showError(e.message))
                return false
            }
        },
        //this loads user into the app state, in case it is authenticated in the user manager.
        //if this returns null , a login is required
        async loadUser() {
            try {
                const silentUser = await userManager.getUser()             
                updateUser(silentUser)
            } catch (e) {
                dispatch(showError(e.message))
                updateUser(null)
            }
        },
        async checkSession():Promise<boolean> {
            try {
                await userManager.querySessionStatus()          
                return true
            } catch (e) {
                //this message would just annoy, as its for silent in the background checks
                //dispatch(showError(e.message))
                return false
            }
        },
        async completeSignIn(url: string): Promise<void> {
            try {
                await userManager.signinSilentCallback(url)
            } catch (e) {
                dispatch(showError(e.message))
            }
        },
        async signOut(): Promise<boolean> {
            try {
                await userManager.signoutSilent()
                await userManager.removeUser()
                return await ApiRequest<null, null>({
                    route: IdentityApiPaths.Logout,
                    verb: RequestVerb.Post
                }).then((response) => {
                    if (response.statusCode === SuitamoiApiStatus.Success) {
                        updateUser(null)
                        return true
                    }
                    return false
                })

            } catch (e) {
                dispatch(show(externalSignOutError))
                return false
            }
        },
        async completeSignOut(url: string): Promise<void> {
            try {
                //add the actual backend logout               
                await userManager.signoutSilentCallback(url)
            } catch (e) {
                dispatch(show(signOutError))
            }
        },
        ensureUserManagerInitialized(): boolean {
            if (window.location.origin.includes("localhost") && !isIframe()) {
                Log.setLogger(console)
                Log.setLevel(Log.DEBUG)
            }

            if (userManager !== null) {
                return true
            }

            const settings = {
                authority: window.location.origin,
                client_id: "Tiphy.Main.Web",
                redirect_uri: `${window.location.origin}/authentication/login-callback`,
                post_logout_redirect_uri: `${window.location.origin}/authentication/logout-callback`,
                response_type: "code",
                scope: "SuitamoiAPI openid profile",
                automaticSilentRenew: true,
                includeIdTokenInSilentRenew: true,
                userStore: new WebStorageStateStore({
                    prefix: ApplicationName
                })
            }

            const uM = new UserManager(settings)

            uM.events.addUserLoaded((user) => {
                updateUser(user)
            })

            setUserManager(uM)
            return true
        }
    }

    const updateUser = (user: User | void) => {
        dispatch(setToken(user && user.access_token))
        dispatch(setUserId(user && user.profile.sub))
        dispatch(setIsLoggedIn(!!user))
    }

    return (
        <div>
            <SessionServiceContext.Provider value={sessionService}>
                {children}
            </SessionServiceContext.Provider>
        </div >
    )
}
export default SessionService