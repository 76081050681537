import { configureStore } from "@reduxjs/toolkit"
import toDoItemsReducer from "../components/todolist/reducer/toDoItemsReducer"
import socketReducer from "../core/network/reducer/socketReducer"
import popupReducer from "../components/popupmessage/reducer/popupReducer"
import askMeSenpaiReducer from "../components/askmesenpai/reducer/askMeSenpaiReducer"
import appStateReducer from "../app/reducer/appStateReducer"
import profileReducer from "../components/user/profile/reducer/profileReducer"
import SocketMiddleware from "../core/network/socketMiddleware"
import partnerlistReducer from "../components/partnerlist/reducer/partnerlistReducer"
import notficationCenterReducer from "../components/navmenu/reducer/notficationCenterReducer"

export const store = configureStore({
    reducer: {
        toDoListState: toDoItemsReducer,
        socketState: socketReducer,
        popupState: popupReducer,
        askMeSenpaiState: askMeSenpaiReducer,
        appState: appStateReducer,
        profileState: profileReducer,
        partnerlistState: partnerlistReducer,
        notificationCenterState: notficationCenterReducer
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat([SocketMiddleware])
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch