import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
} from "react"
import { useAppSelector } from "../../../app/hooks"
import { QuestionCategoryModel } from "../../../@types/QuestionCategoryModel"
import { QuestionModel } from "../../../@types/QuestionModel"
import { ListGroup } from "react-bootstrap"
import { ICellEditorParams } from "@ag-grid-community/core"
import { AskMeSenpaiServiceContext } from "../reducer/AskMeSenapiService"
import { useTranslation } from "react-i18next"

export const QuestionCategoryGridCellEditor = forwardRef((props: ICellEditorParams, ref) => {
    const categories = useAppSelector((state) => state.askMeSenpaiState.categories)
    const { t } = useTranslation()

    const noneCategory: QuestionCategoryModel = {
        categoryDescription: "",
        categoryName: t("GENERIC_None"),
        questionCategoryId: 0
    }
    const categoriesData = React.useMemo(() => {
        return [noneCategory, ...categories].sort((x, y) => x.questionCategoryId - y.questionCategoryId)
    }, [categories])

    const refContainer = useRef<any>(null)
    const askMeSenpaiService = React.useContext(AskMeSenpaiServiceContext)

    const handleCategory = (newCategoryId: number, gridData: any) => {
        const model: QuestionModel = {
            ...gridData,
            categoryId: newCategoryId
        }
        askMeSenpaiService.modifyQuestion(model)
    }

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return 0
            },
        }
    })

    return (

        <div
            ref={refContainer}
        >
            <If condition={categoriesData.length > 1}>
                <ListGroup>
                    {categoriesData.map((cat: QuestionCategoryModel, i: number) => {
                        return (<ListGroup.Item action onClick={
                            () => handleCategory(cat.questionCategoryId, props.data)} key={i}>
                            {cat.categoryName}
                        </ListGroup.Item>)
                    })}
                </ListGroup>
            </If>
        </div>
    )
})
QuestionCategoryGridCellEditor.displayName = "GridDropDownCellEditor"