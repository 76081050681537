import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { ActivateTfaModel } from "../../../../@types/ActivateTfaModel"
import { ChangeEmailModel } from "../../../../@types/ChangeEmailModel"
import { ChangePasswordModel } from "../../../../@types/ChangePasswordModel"
import { ConfirmChangeEmailModel } from "../../../../@types/ConfirmChangeEmailModel"
import { DeletePersonalDataModel } from "../../../../@types/DeletePersonalDataModel"
import { GeneralSettingsModel } from "../../../../@types/GeneralSettingsModel"
import { LoginManagementModel } from "../../../../@types/LoginManagementModel"
import { ManageTfaModel } from "../../../../@types/ManageTfaModel"
import { RemoveLoginModel } from "../../../../@types/RemoveLoginModel"
import { SetPasswordModel } from "../../../../@types/SetPasswordModel"
import { SuitamoiApiStatus } from "../../../../@types/SuitamoiApiStatus"
import { SupportedLanguages } from "../../../../@types/SupportedLanguages"
import { TfaRecoveryCodesModel } from "../../../../@types/TfaRecoveryCodesModel"
import { IdentityApiPaths, IdentityProfileApiPaths, IdentitySpaPaths } from "../../../../app/AppConstants"
import { useAppDispatch } from "../../../../app/hooks"
import { ApiRequest, DownloadRequest, RequestVerb } from "../../../../core/network/DataRequest"
import { PopupType, show } from "../../../popupmessage/reducer/popupReducer"
import { parseSupportedLanguageToCultureInfo, setEmail, setHasPassword, setLanguage, setLoginManagementData, setPhoneNumber, setTfaData, setUserName } from "./profileReducer"
import { SuitamoiApiResponse } from "../../../../core/network/SuitamoiApiResponse"

export interface IProfileService {
    readProfile(): Promise<SuitamoiApiResponse<GeneralSettingsModel>>
    sendUpdateProfileRequest(userName: string, phoneNumber: string)
    changePassword(oldPassword: string, newPassword: string, confirmPassword: string)
    setPassword(newPassword: string, confirmPassword: string)
    requestChangeEmail(newEmail: string)
    confirmChangeEmail(code: string, userId: string, newEmail: string)
    readConnectedLogins()
    removeConnectedLogin(model: RemoveLoginModel)
    deleteAccount(password: string)
    downloadAccountData()
    readTFA()
    removeCurrentBrowserFromTFA()
    removeTFA()
    toggleTFA()
    readTFAActivationData()
    activateTFA(model: ActivateTfaModel)
    generateRecoveryCode()
    setLanguage(language: SupportedLanguages)
}

export const ProfileServiceContext = React.createContext<IProfileService | undefined>(undefined)

export const useProfileServiceContext = () => {
    return React.useContext<IProfileService | undefined>(undefined)
}

const ProfileService = ({ children }: any) => {
    const dispatch = useAppDispatch() as any
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const profileService = {
        async deleteAccount(password: string) {
            const model: DeletePersonalDataModel = {
                password: password
            }
            await ApiRequest<DeletePersonalDataModel, null>({
                payload: model,
                route: IdentityProfileApiPaths.DeleteAccount,
                verb: RequestVerb.Post
            }).then(response => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    const popup = {
                        showPopup: true,
                        content: t("errors:SPA_MANAGEPROFILE_DeleteAccountConfirmation"),
                        popupType: PopupType.Normal,
                        headline: t("GENERIC_Success")
                    }
                    dispatch(show(popup))
                    navigate(IdentitySpaPaths.Home)
                }
            })
        },
        async setLanguage(language: SupportedLanguages) {
            await ApiRequest<SupportedLanguages, null>({
                payload: language,
                route: IdentityApiPaths.SetLanguage,
                verb: RequestVerb.Post
            }).then((response) => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    dispatch(setLanguage(language))
                    i18n.changeLanguage(parseSupportedLanguageToCultureInfo(language))
                }
            })
        },
        async downloadAccountData() {
            return await DownloadRequest(IdentityProfileApiPaths.DataSubjectRequest, "PersonalData.json")
        },
        async readConnectedLogins() {
            await ApiRequest<null, LoginManagementModel>({
                route: IdentityProfileApiPaths.ReadConnectedLogins,
                verb: RequestVerb.Get
            }).then(response => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    dispatch(setLoginManagementData(response.payload))
                }
            })
        },
        async removeConnectedLogin(model: RemoveLoginModel) {
            await ApiRequest<RemoveLoginModel, null>({
                payload: model,
                route: IdentityProfileApiPaths.RemoveConnectedLogins,
                verb: RequestVerb.Post
            }).then(response => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    const popup = {
                        showPopup: true,
                        content: t("errors:SPA_MANAGEPROFILE_DeleteLoginProviderConfirmation"),
                        popupType: PopupType.Normal,
                        headline: t("GENERIC_Success")
                    }
                    dispatch(show(popup))
                    profileService.readConnectedLogins()
                }
            })
        },
        async readProfile() {
            return await ApiRequest<null, GeneralSettingsModel>({
                route: IdentityProfileApiPaths.ReadProfile,
                verb: RequestVerb.Get
            }).then((response) => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    dispatch(setEmail(response.payload.email))
                    dispatch(setUserName(response.payload.userName))
                    dispatch(setPhoneNumber(response.payload.phoneNumber ?? ""))
                    dispatch(setHasPassword(response.payload.hasPassword))
                }
                return response
            })
        },
        async sendUpdateProfileRequest(userName: string, phoneNumber: string) {
            const model: GeneralSettingsModel = {
                phoneNumber: phoneNumber,
                userName: userName,
            }
            await ApiRequest<GeneralSettingsModel, null>({
                payload: model,
                route: IdentityProfileApiPaths.UpdateProfile,
                verb: RequestVerb.Post
            }).then((response) => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    dispatch(setUserName(model.userName))
                    dispatch(setPhoneNumber(model.phoneNumber))
                }
            })
        },
        async requestChangeEmail(newEmail: string) {
            const model: ChangeEmailModel = {
                newEmail: newEmail
            }

            ApiRequest<ChangeEmailModel, null>({
                payload: model,
                route: IdentityProfileApiPaths.RequestChangeEmail,
                verb: RequestVerb.Post
            })
                .then(response => {
                    if (response.statusCode === SuitamoiApiStatus.Success) {
                        const popup = {
                            showPopup: true,
                            content: t("errors:SPA_MANAGEPROFILE_ChangeEmailConfirmation"),
                            popupType: PopupType.Normal,
                            headline: t("GENERIC_Success")
                        }
                        dispatch(show(popup))
                    }
                })
        },
        async confirmChangeEmail(code: string, userId: string, newEmail: string) {
            const model: ConfirmChangeEmailModel = {
                code: code,
                email: newEmail,
                userId: userId
            }
            return await ApiRequest<ConfirmChangeEmailModel, null>({
                payload: model,
                route: IdentityProfileApiPaths.ConfirmChangeEmail,
                verb: RequestVerb.Post
            }).then((response) => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    const popup = {
                        showPopup: true,
                        content: t("errors:SPA_MANAGEPROFILE_ChangeEmailSuccess"),
                        popupType: PopupType.Normal,
                        headline: t("GENERIC_Success")
                    }
                    dispatch(show(popup))
                    dispatch(setEmail(newEmail))
                }
            })
        },
        async changePassword(oldPassword: string, newPassword: string, confirmPassword: string) {
            const model: ChangePasswordModel = {
                confirmPassword: confirmPassword,
                newPassword: newPassword,
                oldPassword: oldPassword
            }
            await ApiRequest<ChangePasswordModel, null>({
                payload: model,
                route: IdentityProfileApiPaths.ChangePassword,
                verb: RequestVerb.Post
            }).then((response) => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    const popup = {
                        showPopup: true,
                        content: t("errors:SPA_MANAGEPROFILE_UpdatePasswordConfirmation"),
                        popupType: PopupType.Normal,
                        headline: t("GENERIC_Success")
                    }
                    dispatch(show(popup))
                    dispatch(setHasPassword(true))
                }
            })
        },
        async setPassword(newPassword: string, confirmPassword: string) {
            const model: SetPasswordModel = {
                confirmPassword: confirmPassword,
                newPassword: newPassword,
            }
            return await ApiRequest<SetPasswordModel, null>({
                payload: model,
                route: IdentityProfileApiPaths.SetPassword,
                verb: RequestVerb.Post
            })
        },
        async readTFA() {
            await ApiRequest<null, ManageTfaModel>({
                route: IdentityProfileApiPaths.ReadTFA,
                verb: RequestVerb.Get
            }).then((response) => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    dispatch(setTfaData(response.payload))
                }
            })
        },
        async removeCurrentBrowserFromTFA() {
            return await ApiRequest({
                route: IdentityProfileApiPaths.RemoveCurrentBrowserFromTFA,
                verb: RequestVerb.Post
            })
        },
        async removeTFA() {
            return await ApiRequest({
                route: IdentityProfileApiPaths.RemoveTFA,
                verb: RequestVerb.Post
            }).then(response => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    const popup = {
                        showPopup: true,
                        content: t("errors:SPA_MANAGEPROFILE_TfaRemoveConfirmation"),
                        popupType: PopupType.Normal,
                        headline: t("GENERIC_Success")
                    }
                    dispatch(show(popup))
                    profileService.readTFA()
                }
            })
        },
        async toggleTFA() {
            await ApiRequest({
                route: IdentityProfileApiPaths.ToggleTFA,
                verb: RequestVerb.Post
            }).then(response => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    profileService.readTFA()
                }
            })
        },
        async readTFAActivationData() {
            return await ApiRequest<null, ActivateTfaModel>({
                route: IdentityProfileApiPaths.ReadTFAActivationData,
                verb: RequestVerb.Get
            })
        },
        async activateTFA(model: ActivateTfaModel) {
            return await ApiRequest<ActivateTfaModel, TfaRecoveryCodesModel>({
                payload: model,
                route: IdentityProfileApiPaths.ActivateTFA,
                verb: RequestVerb.Post
            }).then(response => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    profileService.readTFA()
                }
                return response
            })
        },
        async generateRecoveryCode() {
            return await ApiRequest<null, TfaRecoveryCodesModel>({
                route: IdentityProfileApiPaths.GenerateRecoveryCode,
                verb: RequestVerb.Post
            })
        },
    }
    return (
        <div>
            <ProfileServiceContext.Provider value={profileService}>
                {children}
            </ProfileServiceContext.Provider>
        </div>
    )
}

export default ProfileService