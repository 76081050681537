import * as React from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"

import { useAppSelector } from "../../../app/hooks"
import { TextField, TextFieldType } from "../../../elements/TextField"
import { ProfileServiceContext } from "./reducer/ProfileService"

export const DataPrivacyFragment = () => {
    const [password, setPassword] = React.useState("")
    const profileService = React.useContext(ProfileServiceContext)
    const [hasPasswordInputError, setHasPasswordInputError] = React.useState(true)
    const onDelete = () => {
        if (profileState.hasPassword && hasPasswordInputError) {
            return
        }
        profileService.deleteAccount(password)
    }

    const onDownload = () => {
        profileService.downloadAccountData()
    }
    const { t } = useTranslation()
    const profileState = useAppSelector((state) => state.profileState)
    return (
        <div>
            <h3>{t("PRIVACY_Title")}</h3>
            <p>{t("PRIVACY_Description")}</p>
            <p> <Button variant="primary" onClick={onDownload}  type="submit">{t("GENERIC_Download")}</Button></p>
            <hr></hr>
            <p><strong>{t("PRIVACY_Warning")}</strong> </p>
            <If condition={profileState.hasPassword}>
                <p>{t("PRIVACY_ConfirmPassword")}:</p>
                <div className="form-floating">
                    <TextField label={t("GENERIC_Password")} onChange={setPassword} type={TextFieldType.Password} callback={setHasPasswordInputError} value={password} />
                </div>
            </If>
            <p> <Button variant="primary" onClick={onDelete} disabled={profileState.hasPassword && hasPasswordInputError} >{t("GENERIC_Delete")} </Button></p>
        </div>
    )
}