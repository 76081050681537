import React from "react"

export const formatString = (str: string, ...val: string[]) => {
    for (let index = 0; index < val.length; index++) {
        str = str.replace(`{${index}}`, val[index])
    }
    return str
}

export const getRandom = (max: number): number => {
    const min = 0
    const val = Math.round(min + Math.random() * (max - min))
    return val
}

export const isIframe = (): boolean => {
    return window.self !== window.top
}