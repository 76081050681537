import { DateTime } from "luxon"
import * as React from "react"
import { BasicPriority } from "../../../@types/BasicPriority"
import { BasicStatus } from "../../../@types/BasicStatus"
import { ToDoItemModel } from "../../../@types/ToDoItemModel"
import { FeatureApiPaths } from "../../../app/AppConstants"
import { useAppDispatch } from "../../../app/hooks"
import { ApiRequest, RequestVerb } from "../../../core/network/DataRequest"
import { add, load } from "./toDoItemsReducer"

export interface IToDoService {
    readToDoItems()
    uploadToDoItem(text: string)
    deleteToDoItem(model: ToDoItemModel)
}

export const ToDoServiceContext = React.createContext<IToDoService | undefined>(undefined)

export const useToDoServiceContext = () => {
    return React.useContext<IToDoService | undefined>(undefined)
}

const ToDoService = ({ children }: any) => {
    const dispatch = useAppDispatch() as any

    const toDoService = {
        async readToDoItems() {
            //Todo rename and change return to socket
            ApiRequest<null, ToDoItemModel[]>({
                route: FeatureApiPaths.ToDoList.Read,
                verb: RequestVerb.Get
            })
                .then(response => {
                    dispatch(load(response.payload))
                })
        },
        async uploadToDoItem(text: string) {
            const model: ToDoItemModel = {
                text: text,
                toDoItemId: 0,
                createdAt: DateTime.now().toUTC().toISO(),
                dueDate: DateTime.now().toUTC().toISO(),
                priority: BasicPriority.None,
                status: BasicStatus.NotSet,
            }
            ApiRequest<ToDoItemModel, ToDoItemModel>({
                payload: model,
                route: FeatureApiPaths.ToDoList.Add,
                verb: RequestVerb.Post
            }).then(response => {
                dispatch(add(response.payload))
            })
        },
        async deleteToDoItem(model: ToDoItemModel) {
            ApiRequest<ToDoItemModel, null>({
                payload: model,
                route: FeatureApiPaths.ToDoList.Delete,
                verb: RequestVerb.Post
            })
        }
    }

    return (
        <div>
            <ToDoServiceContext.Provider value={toDoService}>
                {children}
            </ToDoServiceContext.Provider>
        </div>
    )
}

export default ToDoService