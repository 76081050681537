import * as React from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import QRCode from "react-qr-code"
import { Link } from "react-router-dom"
import { ActivateTfaModel } from "../../../@types/ActivateTfaModel"
import { SuitamoiApiStatus } from "../../../@types/SuitamoiApiStatus"
import { IdentitySpaPaths } from "../../../app/AppConstants"
import { useAppSelector } from "../../../app/hooks"
import { formatString } from "../../../core/GenericHelper"
import { TextField, TextFieldType } from "../../../elements/TextField"
import { ProfileServiceContext } from "./reducer/ProfileService"

export const ManageTfaFragment = () => {
    const profileService = React.useContext(ProfileServiceContext)
    const state = useAppSelector((state) => state.profileState.tfaData)
    const loginManagementData = useAppSelector((state) => state.profileState.loginManagementData)
    const [hasCodeInputError, setHasCodeInputError] = React.useState(true)
    const [code, setCode] = React.useState("")
    const [activateModel, setActivateModel] = React.useState<ActivateTfaModel | null>(null)
    const [activationInProgress, setActivationInProgress] = React.useState(false)
    const [recoveryCodes, setRecoveryCodes] = React.useState<string[] | null>(null)

    const onActivateTfaClick = () => {
        profileService.readTFAActivationData().then((response) => {
            setActivateModel(response.payload)
            setActivationInProgress(true)
        })
    }

    const activateTFA = () => {
        if (hasCodeInputError) {
            return
        }
        activateModel.code = code
        profileService.activateTFA(activateModel).then((response) => {
            if (response.statusCode === SuitamoiApiStatus.Success) {
                setActivationInProgress(false)
                setRecoveryCodes(response.payload.recoveryCodes)
            }
        })
    }

    const toggleTFA = () => {
        profileService.toggleTFA()
    }

    const removeTFA = () => {
        profileService.removeTFA()
    }

    const removeCurrentBrowser = () => {
        profileService.removeCurrentBrowserFromTFA()
    }

    const requestNewRecoveryCodes = () => {
        profileService.generateRecoveryCode().then((response) => {
            if (response.statusCode === SuitamoiApiStatus.Success) {
                setRecoveryCodes(response.payload.recoveryCodes)
            }
        })
    }
    const { t } = useTranslation()

    return (
        <div>
            <h3>{t("PROFILE_TFA_Title")}</h3>
            <If condition={loginManagementData.currentLogins.length > 0}>
                <p>{t("PROFILE_TFA_ExternalExplanation")}: <Link to={IdentitySpaPaths.ExternalLogins}>here</Link>.</p>
            </If>
            <If condition={state.isTfaEnabled && recoveryCodes?.length > 0}>
                <p>{t("PROFILE_TFA_RecoveryCodesDescription")}</p>
                <table className={"table table-striped table-secondary"} aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th>{t("PROFILE_TFA_RecoveryCodes")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {recoveryCodes?.map((item: string, index: number) => (
                            <tr key={index}>
                                <td>{item}</td>
                            </tr>
                        ))}
                    </tbody>
                </table >
            </If>
            <If condition={state.isTfaEnabled}>
                <If condition={state.recoveryCodesLeft == 0}>
                    <div className="alert alert-danger">
                        <strong>{t("PROFILE_TFA_NoRecoveryCodesLeft")}</strong>
                        <p>{t("PROFILE_TFA_GenerateNewRecoveryCodes")}</p>
                    </div>
                </If>
                <If condition={state.recoveryCodesLeft == 1}>
                    <div className="alert alert-danger">
                        <strong>{formatString(t("PROFILE_TFA_CodesLeft"), String(1))}</strong>
                        <p>{t("PROFILE_TFA_GenerateNewRecoveryCodesNotice")}</p>
                    </div>
                </If>
                <If condition={state.recoveryCodesLeft <= 10}>
                    <div className="alert alert-warning">
                        <strong>{formatString(t("PROFILE_TFA_CodesLeft"), state.recoveryCodesLeft.toString())}</strong>
                    </div>
                </If>
                <If condition={state.isMachineRemembered}>
                    <p>{t("PROFILE_TFA_ForgetBrowserDescription")}  <Button variant="primary" onClick={removeCurrentBrowser} >{t("PROFILE_TFA_Forget")}</Button></p>
                </If>
                <p>{t("PROFILE_TFA_DisableDescription")}  <Button variant="primary" onClick={toggleTFA}>{t("GENERIC_Disable")}</Button></p>
                <p>{t("PROFILE_TFA_RemoveDescription")}  <Button variant="primary" onClick={removeTFA}>{t("GENERIC_Remove")}</Button></p>
                <p>{t("PROFILE_TFA_NewCodes")}  <Button variant="primary" onClick={requestNewRecoveryCodes} >{t("PROFILE_TFA_RecoveryCodesReset")}</Button></p>
            </If>
            <If condition={!state.isTfaEnabled && state.hasAuthenticator}>
                <p>{t("PROFILE_TFA_AmbigiousState")}</p>
                <p> <Button variant="primary" onClick={toggleTFA}>{t("PROFILE_TFA_Reactivate")}</Button>  <Button variant="primary" onClick={onActivateTfaClick}>{t("PROFILE_TFA_RestartActivation")}</Button></p>
            </If>
            <If condition={!activationInProgress && !state.isTfaEnabled && !state.hasAuthenticator}>
                <h4>{t("PROFILE_TFA_AuthenticatorApp")}</h4>
                <Button variant="primary" onClick={onActivateTfaClick} id="enable-authenticator" >{t("PROFILE_TFA_StartActivation")}</Button>
            </If>
            <If condition={activationInProgress && !state.isTfaEnabled}>
                <p>{t("PROFILE_TFA_AuthenticatorQRCodeDescription")}</p>
                <p><strong>{activateModel?.sharedKey}</strong></p>
                <div style={{ border: "10px solid white", width: "fit-content" }}><QRCode value={activateModel?.authenticatorUri} size={180} level="Q" /></div>
                <p>{t("PROFILE_TFA_AuthenticatorActivationCodePrompt")}</p>
                <TextField label={t("PROFILE_TFA_Code")} onChange={setCode} type={TextFieldType.Code} value={code} callback={setHasCodeInputError} />
                <Button variant="primary" disabled={hasCodeInputError} onClick={activateTFA} id="enable-authenticator" >{t("PROFILE_TFA_Activate")}</Button>
            </If>
        </div>

    )
}