import * as React from "react"
import { useEffect } from "react"
import { Routes, Route, useNavigate } from "react-router-dom"
import "./app.css"
import { Layout } from "./Layout"
import { Home } from "../components/home/Home"
import { AuthorizationFlowPaths, FeatureSpaPaths, IdentitySpaPaths, LoginActions, LoginPageActions, LogoutActions, ProfileActions } from "./AppConstants"
import { PopupMessage } from "../components/popupmessage/PopupMessage"
import { useAppSelector, useAppDispatch } from "./hooks"
import { startConnecting, submitMessage } from "../core/network/reducer/socketReducer"
import { setInitialized } from "../app/reducer/appStateReducer"
import { LoginPage } from "../components/user/account/LoginPage"
import { LoginFlow } from "../components/user/session/LoginFlow"
import { ProfilePage } from "../components/user/profile/ProfilePage"
import { ToDoList } from "../components/todolist/ToDoList"
import { AskMeSenpai } from "../components/askmesenpai/AskMeSenpai"
import { SocketTest } from "../components/notifications/SocketTest"
import { LogoutFlow } from "../components/user/session/LogoutFlow"
import { SessionServiceContext } from "../components/user/session/SessionService"
import { LoadingSpinner } from "../elements/LoadingSpinner"
import { AccountServiceContext } from "../components/user/account/reducer/AccountService"
import { ProfileServiceContext } from "../components/user/profile/reducer/ProfileService"
import { CookieConsent } from "../components/cookieconsent/CookieConsent"
import { PartnerlistServiceContext } from "../components/partnerlist/reducer/PartnerlistService"
import { OnlineState } from "../@types/OnlineState"
import { FrontendNotification } from "../@types/FrontendNotification"
import { NotificationTarget } from "../@types/NotificationTarget"
import { PartnerlistNotificationPayload } from "../@types/PartnerlistNotificationPayload"
import { PartnerlistNotificationType } from "../@types/PartnerlistNotificationType"
import { NotificationTypeRegistry } from "../@types/NotificationTypeRegistry"
import { isIframe } from "../core/GenericHelper"
import { SuitamoiApiStatus } from "../@types/SuitamoiApiStatus"

export const App = () => {
    const navigate = useNavigate()
    const accountService = React.useContext(AccountServiceContext)
    const profileService = React.useContext(ProfileServiceContext)
    const partnerlistService = React.useContext(PartnerlistServiceContext)
    const dispatch = useAppDispatch() as any
    const isInitialized = useAppSelector((state) => state.appState.isInitialized)
    const isLoggedIn = useAppSelector((state) => state.appState.isLoggedIn)
    const sessionService = React.useContext(SessionServiceContext)
    const isConnected = useAppSelector((state) => state.socketState.isConnected)
    const shouldLogout = useAppSelector((state) => state.appState.logout)
    const [lastOnlineState] = React.useState<OnlineState | undefined>(JSON.parse(localStorage.getItem("lastonlinestate")))
    const startOnlineStateUpdateLoop = () => {
        setInterval(function () {
            if (isConnected) {
                const content: PartnerlistNotificationPayload = {
                    onlineState: {
                        onlineState: lastOnlineState ?? OnlineState.Online,
                        lastSeen: "",
                        userId: ""
                    },
                    type: PartnerlistNotificationType.OwnOnlineStateUpdate
                }
                const setOnlineState: FrontendNotification = {
                    content: JSON.stringify(content),
                    id: NotificationTarget.ServicePartnerlist,
                    type: NotificationTypeRegistry.partnerlistNotificationPayload
                }
                dispatch(submitMessage(setOnlineState))
            }
        }, 60000) // should not be longer than 5 minutes actually considerably smaller (see PartnerlistItem.cs)
    }

    //Load the Services in order
    // UserManager so authentication works - only afterwards load the content - everything is depending on the userManager
    useEffect(() => {
        //happens when the server sends a logout instruction
        if (shouldLogout) {
            sessionService.signOut()
        }
        //since we have identity server and the SPA in the same js bundled (which is suboptimal and should be changed)
        //this code is also executed when a background iframe update of the session via the OIDC UserManager is done.
        //We are not interested in doing any app stuff in the iframe as such we only have to initialize the usermanager and load the user (in the iframe) to be able to do the background stuff.

        if (!isInitialized) {
            if (sessionService.ensureUserManagerInitialized()) {
                accountService.readExternalLogins()
                    .then((response) => {
                        dispatch(setInitialized(response.payload))
                    })
            }
        }

        if (window.location.origin.includes("localhost") && !isIframe()) {
            if (isInitialized && !isLoggedIn && !isConnected) {
                console.log(`isInitialized ${isInitialized}`)
            }
            if (isInitialized && isLoggedIn && !isConnected) {
                console.log(`isLoggedIn ${isLoggedIn}`)
            }
            if (isInitialized && isLoggedIn && isConnected) {
                console.log(`isConnected ${isConnected}`)
            }
        }

        // login silently check and log into existing session
        if (isInitialized && !isLoggedIn) {
            if (isIframe()) {
                return
            } else {
                sessionService.checkSession().
                    then((success) => {
                        if (success) {
                            sessionService.loadUser()
                        }
                    })
            }
        }

        if (!isIframe()) {
            if (isInitialized && isLoggedIn && !isConnected) {
                //This needs to be the first call - just in case anything is wrong we dont hammer the server with many invalid parallel requests.
                //if we do not have a proper session at this point the identity server does start a passive "implicit" token flow. which is not supported by the current react-ts-oidc client used here. so this would cause errors
                //instead we check the session above manually
                profileService.readProfile()
                    .then((response) => {
                        if (response.statusCode === SuitamoiApiStatus.Redirect) {
                            //triggered when the backend implicitly redirects to authorization point from a request
                            //this should not ever happen, but at least if it does it redirects to login page
                            const url = new URL(response.exceptionMessage)
                            navigate(url.pathname + url.search)
                        }
                        if (response.statusCode === SuitamoiApiStatus.Success) {
                            dispatch(startConnecting())
                        }
                    })                
            }

            if (isInitialized && isLoggedIn && isConnected) {
                Promise.all([
                    profileService.readConnectedLogins(),
                    profileService.readTFA(),
                    partnerlistService.setOnlineState({
                        onlineState: lastOnlineState ?? OnlineState.Online,
                        userId: "",
                        lastSeen: "",
                    }),
                    partnerlistService.readAllPartners(),
                    partnerlistService.readAllGroups(),
                    startOnlineStateUpdateLoop()
                ])
            }
        }
    }, [isInitialized, isLoggedIn, isConnected, shouldLogout])

    return (
        <Layout>
            <Routes>
                <Route path="*" element={<Home />} />
                <Route path={IdentitySpaPaths.Home} element={<Home />} />
                <Route path={IdentitySpaPaths.LoginPage} element={<LoginPage action={LoginPageActions.Login} />} />
                <Route path={IdentitySpaPaths.ForgotPassword} element={<LoginPage action={LoginPageActions.ForgotPassword} />} />
                <Route path={IdentitySpaPaths.ResendActivationMail} element={<LoginPage action={LoginPageActions.ResendActivationMail} />} />
                <Route path={IdentitySpaPaths.SignUp} element={<LoginPage action={LoginPageActions.SignUp} />} />
                <Route path={IdentitySpaPaths.ResetPassword} element={<LoginPage action={LoginPageActions.ResetPassword} />} />
                <Route path={IdentitySpaPaths.SignUpSuccess} element={<LoginPage action={LoginPageActions.RegistrationSucces} />} />

                <If condition={isInitialized}>
                    <Route path={AuthorizationFlowPaths.Login} element={<LoginFlow action={LoginActions.Login} />} />
                    <Route path={AuthorizationFlowPaths.LoginFailed} element={<LoginFlow action={LoginActions.LoginFailed} />} />
                    <Route path={AuthorizationFlowPaths.LoginCallback} element={<LoginFlow action={LoginActions.LoginCallback} />} />
                    <Route path={AuthorizationFlowPaths.LogOut} element={<LogoutFlow action={LogoutActions.Logout} />} />
                    <Route path={AuthorizationFlowPaths.LogOutCallback} element={<LogoutFlow action={LogoutActions.LogoutCallback} />} />
                    <Route path={AuthorizationFlowPaths.LoggedOut} element={<LogoutFlow action={LogoutActions.LoggedOut} />} />
                    <Route path={AuthorizationFlowPaths.LogOutFailed} element={<LogoutFlow action={LogoutActions.LogoutFailed} />} />
                    <Route path={FeatureSpaPaths.SocketTest} element={<SocketTest />} />
                </If>

                <If condition={isLoggedIn}>
                    <Route path={IdentitySpaPaths.Profile} element={<ProfilePage action={ProfileActions.Change} />} />
                    <Route path={IdentitySpaPaths.ChangeEmail} element={<ProfilePage action={ProfileActions.ChangeEmail} />} />
                    <Route path={IdentitySpaPaths.ChangePassword} element={<ProfilePage action={ProfileActions.ChangePassword} />} />
                    <Route path={IdentitySpaPaths.ExternalLogins} element={<ProfilePage action={ProfileActions.ExternalLogins} />} />
                    <Route path={IdentitySpaPaths.ManageTfa} element={<ProfilePage action={ProfileActions.ManageTfa} />} />
                    <Route path={IdentitySpaPaths.DataPrivacy} element={<ProfilePage action={ProfileActions.DataPrivacy} />} />
                    <Route path={FeatureSpaPaths.AskMeSenpai} element={<AskMeSenpai />} />
                    <Route path={FeatureSpaPaths.ToDoList} element={<ToDoList />} />
                </If>
            </Routes>
            <PopupMessage />
            <CookieConsent />
            <LoadingSpinner />
        </Layout >
    )
}