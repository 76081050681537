import * as React from "react"
import { OnlineStateModel } from "../../../@types/OnlineStateModel"
import { PartnerlistGroupModel } from "../../../@types/PartnerlistGroupModel"
import { PartnerlistItemModel } from "../../../@types/PartnerlistItemModel"

import { FeatureApiPaths } from "../../../app/AppConstants"
import { ApiRequest, RequestVerb } from "../../../core/network/DataRequest"

// This service works socket only - no response Data of the API is used. instead the socketmiddleware handles the socket notifications
// this is a very intentional design decision - we want an API too with http data. but we also want to use sockets.
// For big requests like the readquestions and readcategories the backend will only send an empty list response when a session has a socket open
export interface IPartnerlistService {
    addPartner(model: PartnerlistItemModel)
    confirmPartner(model: PartnerlistItemModel)
    editPartner(model: PartnerlistItemModel)
    readAllPartners()
    deletePartner(model: PartnerlistItemModel)

    readAllGroups()
    modifyGroup(model: PartnerlistGroupModel)
    addGroup(model: PartnerlistGroupModel)
    deleteGroup(model: PartnerlistGroupModel)

    setOnlineState(model: OnlineStateModel)
}

export const PartnerlistServiceContext = React.createContext<IPartnerlistService | undefined>(undefined)

export const usePartnerlistService = () => {
    return React.useContext<IPartnerlistService | undefined>(undefined)
}

const PartnerlistService = ({ children }: any) => {
    const partnerlistService = {
        async addPartner(model: PartnerlistItemModel) {
            return await ApiRequest<PartnerlistItemModel, PartnerlistItemModel>({
                payload: model,
                route: FeatureApiPaths.Partnerlist.AddPartner,
                verb: RequestVerb.Post,
            })
        },
        async confirmPartner(model: PartnerlistItemModel) {
            return await ApiRequest<PartnerlistItemModel, PartnerlistItemModel>({
                payload: model,
                route: FeatureApiPaths.Partnerlist.ConfirmPartner,
                verb: RequestVerb.Post,
            })
        },
        async editPartner(model: PartnerlistItemModel) {
            return await ApiRequest<PartnerlistItemModel, PartnerlistItemModel>({
                payload: model,
                route: FeatureApiPaths.Partnerlist.EditPartner,
                verb: RequestVerb.Post,
            })
        },
        async deletePartner(model: PartnerlistItemModel) {
            return await ApiRequest<PartnerlistItemModel, null>({
                route: FeatureApiPaths.Partnerlist.DeletePartner,
                verb: RequestVerb.Post,
                payload: model,
            })
        },
        async readAllPartners() {
            return await ApiRequest<null, PartnerlistItemModel[]>({
                route: FeatureApiPaths.Partnerlist.ReadPartners,
                verb: RequestVerb.Get
            })
        },

        async readAllGroups() {
            return await ApiRequest<null, PartnerlistGroupModel[]>({
                route: FeatureApiPaths.Partnerlist.ReadGroups,
                verb: RequestVerb.Get
            })
        },
        async addGroup(model: PartnerlistGroupModel) {
            return await ApiRequest<PartnerlistGroupModel, PartnerlistGroupModel>({
                route: FeatureApiPaths.Partnerlist.AddGroup,
                verb: RequestVerb.Post,
                payload: model,
            })
        },
        async deleteGroup(model: PartnerlistGroupModel) {
            return await ApiRequest<PartnerlistGroupModel, null>({
                route: FeatureApiPaths.Partnerlist.DeleteGroup,
                verb: RequestVerb.Post,
                payload: model
            })
        },
        async modifyGroup(model: PartnerlistGroupModel) {
            return await ApiRequest<PartnerlistGroupModel, PartnerlistGroupModel>({
                payload: model,
                route: FeatureApiPaths.Partnerlist.EditGroup,
                verb: RequestVerb.Post,
            })
        },
        async setOnlineState(model: OnlineStateModel) {
            return await ApiRequest<OnlineStateModel, null>({
                payload: model,
                route: FeatureApiPaths.Partnerlist.SetOnlineState,
                verb: RequestVerb.Post,
            })
        }
    }
    return (
        <div>
            <PartnerlistServiceContext.Provider value={partnerlistService}>
                {children}
            </PartnerlistServiceContext.Provider>
        </div>
    )
}

export default PartnerlistService