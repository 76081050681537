import { ICellRendererParams } from "@ag-grid-community/core"
import * as React from "react"
import { Button, Modal, Nav } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import CheckBox from "../../../elements/CheckBox"

import { AskMeSenpaiServiceContext } from "../reducer/AskMeSenapiService"
import { setSkipDeleteConfirmation } from "../reducer/askMeSenpaiReducer"

export const QuestionDeleteGridCellRenderer = (props: ICellRendererParams) => {
    const askMeSenpaiService = React.useContext(AskMeSenpaiServiceContext)
    const dispatch = useAppDispatch() as any
    const skipDeleteConfirmation = useAppSelector((state) => state.askMeSenpaiState.skipDeleteNotifications)
    const [isSkipChecked, setIsSkipChecked] = React.useState(skipDeleteConfirmation)
    const [show, setShow] = React.useState<boolean>(false)
    const onDelete = () => {
        if (skipDeleteConfirmation) {
            askMeSenpaiService.deleteQuestion(props.data)
        } else {
            setShow(true)
        }
    }

    const onClick = (confirm: boolean) => {
        if (confirm) {
            askMeSenpaiService.deleteQuestion(props.data)
            dispatch(setSkipDeleteConfirmation(isSkipChecked))  
        }
        setShow(false)
    }
    const { t } = useTranslation()
    return (
        <div>
            <Modal show={show} dialogClassName="popup popupWarning" >
                <Modal.Header closeButton onHide={() => onClick(false)} >
                    <Modal.Title>{t("GENERIC_CONFIRM_Title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <p>{t("GENERIC_CONFIRM_Body")}</p>
                    <CheckBox
                        isChecked={isSkipChecked}
                        label={t("GENERIC_Remember")}
                        handleChange={() => {
                            setIsSkipChecked(!isSkipChecked)                                                      
                        }} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onClick(false)}>
                        {t("GENERIC_Cancel")}
                    </Button>
                    <Button variant="primary" onClick={() => onClick(true)}>
                        {t("GENERIC_Ok")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <If condition={props.data.id !== 0}>
                <Nav.Link className="link-secondary" onClick={onDelete}>{t("GENERIC_Delete")}</Nav.Link>
            </If>
        </div>
    )
}