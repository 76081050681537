import * as React from "react"

import { AuthorizationFlowPaths, IdentitySpaPaths } from "../../app/AppConstants"
import { Form, NavDropdown } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { ProfileServiceContext } from "../user/profile/reducer/ProfileService"
import { SupportedLanguages } from "../../@types/SupportedLanguages"
import { useTranslation } from "react-i18next"
import { GearFill } from "react-bootstrap-icons"

export const ControlPanel = () => {
    const isLoggedIn = useAppSelector((state) => state.appState.isLoggedIn)
    const language = useAppSelector((state) => state.profileState.language)
    const profileService = React.useContext(ProfileServiceContext)

    const changeLanguage = (lng: SupportedLanguages) => {
        profileService.setLanguage(lng)
    }
    const { t } = useTranslation()

    const controlPanelLabel = () => {
        return (
            <div className="d-inline-flex align-items-center">
                <GearFill size="1rem" />&ensp;
                {t("NAV_ControlPanel")}
            </div>
        )
    }

    return (
        <div>
            <NavDropdown title={controlPanelLabel()} id="collasible-nav-dropdown">
                <NavDropdown.ItemText><strong>{t("GENERIC_Language")}</strong></NavDropdown.ItemText>
                <NavDropdown.Item active={language === SupportedLanguages.German} onClick={() => {
                    changeLanguage(SupportedLanguages.German)
                }}>- {t("GENERIC_Language_German")}</NavDropdown.Item>
                <NavDropdown.Item active={language === SupportedLanguages.English} onClick={() => {
                    changeLanguage(SupportedLanguages.English)
                }}>- {t("GENERIC_Language_English")}</NavDropdown.Item>
                <NavDropdown.Divider />
                <If condition={isLoggedIn}>
                    <NavDropdown.Item to={IdentitySpaPaths.Profile} as={Link}>{t("NAV_AccountSettings")}</NavDropdown.Item>
                    <NavDropdown.Item to={AuthorizationFlowPaths.LogOut} as={Link}>{t("GENERIC_Logout")}</NavDropdown.Item>
                </If>
                <If condition={!isLoggedIn}>
                    <NavDropdown.Item to={IdentitySpaPaths.SignUp} as={Link}>{t("GENERIC_Register")}</NavDropdown.Item>
                    <NavDropdown.Item to={IdentitySpaPaths.LoginPage} as={Link}>{t("GENERIC_Login")}</NavDropdown.Item>
                </If>
            </NavDropdown>
        </div>
    )
}