import React, { useState } from "react"
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { PersonCheck, Trash } from "react-bootstrap-icons"
import { PartnerlistGroupModel } from "../../@types/PartnerlistGroupModel"
import { PartnerlistItemModel } from "../../@types/PartnerlistItemModel"
import { PartnerlistServiceContext } from "./reducer/PartnerlistService"
import { Themes, ThemeServiceContext } from "../../core/Themes"
import { Badge, mapOnlineStateToBadgeColor } from "../navmenu/sidebar/Badge"
import { PartnerLifeTimeState } from "../../@types/PartnerLifeTimeState"
import { DateTime } from "luxon"
import { DragAndDropWrapper, DragAndDropWrapperItemTypes, DragResult, PARTNERLIST_NONEGROUP_ID } from "./DragAndDropWrapper"
import EditOnDoubleClick from "./EditOnDoubleClick"

export interface IPartnerlistTreeViewItem {
    key: any,
    partnerModel: PartnerlistItemModel,
    groupModel: PartnerlistGroupModel,
    children?: Array<IPartnerlistTreeViewItem>
}

interface IPartnerlistTreeViewProps {
    data: Array<IPartnerlistTreeViewItem>
}
interface IPartnerlisttTreeViewNodeProps {
    node: IPartnerlistTreeViewItem,
    key: any,
}

interface IPersistentPartnerlistNodeState {
    showChildren: boolean,
}
const PartnerlistTreeView = (props: IPartnerlistTreeViewProps) => {
    return (
        <div>
            {props.data?.map((node) => (
                <PartnerlistTreeViewNode node={node} key={node.key} />
            ))}
        </div>
    )
}

const PartnerlistTreeViewNode = (props: IPartnerlisttTreeViewNodeProps) => {
    const partnerlistService = React.useContext(PartnerlistServiceContext)
    const [showChildren, setShowChildren] = useState(false)
    const isGroup = props.node.groupModel !== undefined
    const [savedPartnerlistNodeState,] = React.useState<IPersistentPartnerlistNodeState>(JSON.parse(localStorage.getItem(`plist${props.node.key}`)))
    const [isMouseOver, setIsMouseOver] = useState(false)
    //Persist State in local storage
    React.useEffect(() => {
        if (savedPartnerlistNodeState) {
            setShowChildren(savedPartnerlistNodeState.showChildren)
        }
    }, [savedPartnerlistNodeState])

    React.useEffect(() => {
        const data: IPersistentPartnerlistNodeState = {
            showChildren: showChildren
        }
        localStorage.setItem(`plist${props.node.key}`, JSON.stringify(data))
    }, [showChildren])

    const themeService = React.useContext(ThemeServiceContext)
    const theme = Themes[themeService.theme]
    const handleClick = () => {
        setShowChildren(!showChildren)
    }
    const deletePartner = (model: PartnerlistItemModel) => {
        partnerlistService.deletePartner(model)
    }

    const editPartner = (model: PartnerlistItemModel) => {
        partnerlistService.editPartner(model)
    }

    const deleteGroup = (model: PartnerlistGroupModel) => {
        partnerlistService.deleteGroup(model)
    }

    const editGroup = (model: PartnerlistGroupModel) => {
        partnerlistService.modifyGroup(model)
    }

    const confirmPartner = (model: PartnerlistItemModel) => {
        partnerlistService.confirmPartner(model)
    }

    const elementStyle = {
        color: "var(--bs-body-color)",
        alignContent: "center",
        //alignSelf: "center",
        //background: `var(--bs-${theme})`,
        marginTop: "5px",
        marginBottom: "5px",
    }

    const generateGroupEntry = (model: PartnerlistGroupModel) => {
        return (
            <Container fluid onMouseEnter={() => setIsMouseOver(true)} onMouseLeave={() => setIsMouseOver(false)}>
                <Row>
                    <If condition={model.groupId !== PARTNERLIST_NONEGROUP_ID}>
                        <Col md={9}>
                            <div className="d-inline-flex" style={elementStyle}>
                                <b><EditOnDoubleClick text={model.displayName} data={model} onChange={(model) => editGroup(model)} /></b>
                            </div>
                        </Col>
                        <If condition={isMouseOver}>
                            <Col md={3} style={elementStyle}>
                                <Trash onClick={() => deleteGroup(model)} color="var(--bs-negative-color)" size="1rem" />
                            </Col>
                        </If>
                    </If>
                    <If condition={model.groupId === PARTNERLIST_NONEGROUP_ID}>
                        <Col>
                            <span style={{ color: "var(--color-disabled-text)" }}>{model.displayName}</span>
                        </Col>
                    </If>
                </Row >
            </Container>
        )
    }

    const renderLastSeenTooltip = (model: PartnerlistItemModel) => (
        <Tooltip id="button-tooltip" {...props}>
            {DateTime.fromISO(model?.lastSeen).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}
        </Tooltip>
    )

    const generatePartnerEntry = (model: PartnerlistItemModel) => {
        return (
            <OverlayTrigger
                placement="top"
                delay={{ show: 1000, hide: 400 }}
                overlay={renderLastSeenTooltip(model)}>

                <Container fluid onMouseEnter={() => setIsMouseOver(true)} onMouseLeave={() => setIsMouseOver(false)}>
                    <Row>
                        <Col md={9}>
                            <div className="d-inline-flex" style={elementStyle}>
                                <Badge variant={mapOnlineStateToBadgeColor(model.onlineState)} style={{}}></Badge>
                                <EditOnDoubleClick text={model.displayName} data={model} onChange={(model) => editPartner(model)} />
                            </div>
                        </Col>
                        <If condition={isMouseOver}>
                            <Col md={3} style={elementStyle}>
                                <div className="d-inline-flex partnerlistIcon" >
                                    <Trash onClick={() => deletePartner(model)} color={"var(--bs-negative-color)"} size="1rem" />
                                    <If condition={model.state === PartnerLifeTimeState.WaitingForApproval}>
                                        <PersonCheck onClick={() => confirmPartner(model)} color="var(--bs-green)" size="1rem" />
                                    </If>
                                </div>
                            </Col>
                        </If>
                    </Row>
                </Container>
            </OverlayTrigger>
        )
    }
    return (
        <>
            <div >
                <DragAndDropWrapper
                    modelData={isGroup ? props.node.groupModel : props.node.partnerModel}
                    modelChangeCallback={(dropped: DragResult, target: DragResult) => {
                        if (dropped.type === target.type) {
                            if (dropped.type === DragAndDropWrapperItemTypes.GROUP) {
                                if (dropped.model.groupId === target.model.groupId) {
                                    return
                                }
                            }
                            if (dropped.type === DragAndDropWrapperItemTypes.PARTNER) {
                                const drop = dropped.model as PartnerlistItemModel
                                const tar = target.model as PartnerlistItemModel
                                if (drop.partnerlistItemId === tar.partnerlistItemId) {
                                    return
                                }
                            }
                        }
                        switch (target.type) {
                            case DragAndDropWrapperItemTypes.GROUP: {
                                if (dropped.type === DragAndDropWrapperItemTypes.PARTNER) {
                                    //change dropped partner group Id to target group Id
                                    const newPartner = { ...(dropped.model as PartnerlistItemModel) }
                                    if (target.model.groupId === -1) {
                                        newPartner.groupId = null
                                    } else {
                                        newPartner.groupId = target.model.groupId
                                    }

                                    editPartner(newPartner)
                                }
                                if (dropped.type === DragAndDropWrapperItemTypes.GROUP) {
                                    //Group into Group
                                    const newGroup = { ... (dropped.model as PartnerlistGroupModel) }
                                    if (target.model.groupId === newGroup.parentGroupId) {
                                        return
                                    }
                                    if (target.model.groupId === -1) {
                                        newGroup.parentGroupId = null
                                    } else {
                                        newGroup.parentGroupId = target.model.groupId
                                    }
                                    editGroup(newGroup)
                                }
                                break
                            }
                            case DragAndDropWrapperItemTypes.PARTNER: {
                                if (dropped.type === DragAndDropWrapperItemTypes.PARTNER) {
                                    //nothing
                                    break
                                }
                                if (dropped.type === DragAndDropWrapperItemTypes.GROUP) {
                                    //group in group TODO
                                    break
                                }
                                break
                            }
                        }
                    }}
                    type={isGroup ? DragAndDropWrapperItemTypes.GROUP : DragAndDropWrapperItemTypes.PARTNER}
                >
                    <If condition={isGroup}>
                        <div onClick={handleClick} className="partnerlistItem">
                            {generateGroupEntry(props.node.groupModel)}
                        </div>
                    </If>
                    <If condition={!isGroup}>
                        <div onClick={handleClick} className="partnerlistItem">
                            {generatePartnerEntry(props.node.partnerModel)}
                        </div>
                    </If>
                    <ul style={{ paddingLeft: "1px", borderLeft: "5px solid gray", background: `var(--bs-${theme})` }} className="partnerlistItem" >
                        {showChildren && <PartnerlistTreeView data={props.node.children} />}
                    </ul>
                </DragAndDropWrapper>
            </div>
        </>
    )
}

export default PartnerlistTreeView