import * as React from "react"
import { useState } from "react"
import { Button, Stack } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { TextField, TextFieldType } from "../../elements/TextField"
import { AskMeSenpaiServiceContext } from "./reducer/AskMeSenapiService"

export const CreateCategory = () => {
    const [categoryName, setCategoryName] = useState("")
    const [categoryDescription, setCategoryDescription] = useState("")
    const [hasCategoryNameInputError, setHaCategoryNameInputError] = useState(true)
    const askMeSenpaiService = React.useContext(AskMeSenpaiServiceContext)
    const createCategory = () => {
        askMeSenpaiService.addCategory(categoryDescription, categoryName)
        setCategoryName("")
        setCategoryDescription("")
    }
    const { t } = useTranslation()

    return (
        <div>
            <Stack direction="vertical" gap={3}>
                <TextField label={t("GENERIC_Category")} type={TextFieldType.Required} onChange={setCategoryName} callback={setHaCategoryNameInputError} value={categoryName} submitCallback={createCategory} />
                <TextField label={t("GENERIC_Description")} onChange={setCategoryDescription} type={TextFieldType.Default} value={categoryDescription} submitCallback={createCategory} />
            </Stack>
            <Button variant="primary" disabled={hasCategoryNameInputError} onClick={createCategory} >{t("GENERIC_Ok")}</Button>
        </div>
    )
}