import * as React from "react"
import { Button, Stack } from "react-bootstrap"
import { AskMeSenpaiServiceContext } from "./reducer/AskMeSenapiService"
import { useAppSelector } from "../../app/hooks"
import { AskMeSenpaiManagementGridModel } from "./AskMeSenpai"
import { QuestionModel } from "../../@types/QuestionModel"
import { AgGridReact } from "ag-grid-react"
import { CellEditRequestEvent } from "@ag-grid-community/core"
import { getCategoryNameFromId, getRandomQuestion, getRandomQuestionsFromMultipleCategories } from "./AskMeSenpaiHelper"
import { QuestionCategoryModel } from "../../@types/QuestionCategoryModel"
import { QuestionDeleteGridCellRenderer } from "./grid/QuestionDeleteGridCellRenderer"
import { QuestionCategoryGridCellRenderer } from "./grid/QuestionCategoryGridCellRenderer"
import { useTranslation } from "react-i18next"
import { formatString } from "../../core/GenericHelper"
import { ThemeServiceContext } from "../../core/Themes"
import { MultiSelect } from "../../elements/Select"

export const RandomQuestion = () => {
    const statistics = useAppSelector((state) => state.askMeSenpaiState.statistics)
    const askMeSenpaiService = React.useContext(AskMeSenpaiServiceContext)
    const gridRefRandomQuestion = React.useRef<AgGridReact<AskMeSenpaiManagementGridModel>>(null)

    const questions = useAppSelector((state) => state.askMeSenpaiState.questions)
    const categories = useAppSelector((state) => state.askMeSenpaiState.categories)
    const { t } = useTranslation()
    window.addEventListener("resize", () => {
        gridRefRandomQuestion.current?.api.sizeColumnsToFit()
    })
    const onGridRandomQuestionReady = React.useCallback(() => {
        gridRefRandomQuestion.current!.api.sizeColumnsToFit()
    }, [])

    //should only always contain 1 question
    const [randomQuestions, setRandomQuestions] = React.useState<QuestionModel[]>([])
    const [selectedCategoryIdsForRandomQuestion, setSelectedCategoryIdsForRandomQuestion] = React.useState<[]>([])
    //edit Handler when any cell is edited of the Questions Grid - currently only text field supported
    const onGridCellEditing = (e: CellEditRequestEvent) => {
        const model: QuestionModel = { ...e.data }
        const newValue = e.newValue
        if (e.column.getColId() === "text") {
            model.text = newValue
            askMeSenpaiService.modifyQuestion(model)
        }
    }

    // Questions Grid Column Definitions
    const randomQuestionColumnDefs = [
        { field: "id", minWidth: 65, maxWidth: 65 },
        { field: "text", headerName: t("GENERIC_Question"), editable: true, minWidth: 200, filter: true },
        { field: "numberOfTimesShown", headerName: "#Nani?", minWidth: 120, maxWidth: 120, filter: "agNumberColumnFilter" },
        { field: "numberOfTimesAnsweredCorrectly", headerName: "#Passed", minWidth: 120, maxWidth: 120, filter: "agNumberColumnFilter" },
        {
            headerName: t("GENERIC_Category"),
            field: "categoryId",
            minWidth: 120,
            maxWidth: 120,
            cellRenderer: QuestionCategoryGridCellRenderer,
            editable: false,
            cellEditorPopup: true,
        },
        {
            headerName: t("GENERIC_Delete"),
            minWidth: 100,
            maxWidth: 100,
            cellRenderer: QuestionDeleteGridCellRenderer,
            editable: false,
            filter: false,
            resizable: false
        },
    ]

    //default Grid Config - to save some typing above
    const defaultAskMeSenpaiColumnDefs = {
        resizable: false,
        sortable: false,
        filter: false,
        floatingFilter: false,
        editable: false
    }

    //updated on every notification which changes the external state
    React.useEffect(() => {
        //special cast - the last question has been deleted- reset to none
        if (randomQuestions.length === 1 && questions.length === 0) {
            setRandomQuestions([])
        }
        //set random question to first question if there is no other choice
        if (randomQuestions.length === 0 && questions.length > 0) {
            setRandomQuestions([questions[0]])
        }
        //check if we need to refresh the entry
        if (randomQuestions.length === 1 && questions.length > 0) {
            const randomQuestionFromParentData = questions?.find((x) => x.questionId === randomQuestions[0].questionId)
            const isEqual = randomQuestionFromParentData === randomQuestions[0]
            // no question found?
            if (!randomQuestionFromParentData) {
                //Refresh Random Question
                setRandomQuestions([getRandomQuestionsFromMultipleCategories(questions, selectedCategoryIdsForRandomQuestion)])
            }
            // found the same question but its different
            if (!isEqual && randomQuestionFromParentData) {
                setRandomQuestions([randomQuestionFromParentData])
            }
        }
    }, [categories, randomQuestions, questions, selectedCategoryIdsForRandomQuestion])

    //Question Grid Data has "data derived out of the model"
    const gridData = React.useMemo(() => {
        return randomQuestions?.map((val) => {
            return {
                categoryName: getCategoryNameFromId(val?.categoryId, categories),
                ...val
            }
        })
    }, [randomQuestions, categories])

    const anyCategory: QuestionCategoryModel = {
        categoryName: t("GENERIC_Any"),
        categoryDescription: "",
        questionCategoryId: -1
    }

    const categoriesData = React.useMemo(() => {
        return [anyCategory, ...categories].sort((x, y) => x.questionCategoryId - y.questionCategoryId)
    }, [categories])

    const themeService = React.useContext(ThemeServiceContext)

    const options = categoriesData?.map((cat: QuestionCategoryModel, i: number) => {
        return { value: cat.questionCategoryId, label: cat.categoryName }
    })

    return (
        <div>
            <h1 id="tabelLabel" >Ask me, Senpai!</h1>
            <div className={themeService.themeAsAGGridIdentifer} style={{ height: 105 }}>
                <AgGridReact<AskMeSenpaiManagementGridModel>
                    readOnlyEdit={true}
                    ref={gridRefRandomQuestion}
                    pagination={false}
                    rowData={gridData}
                    columnDefs={randomQuestionColumnDefs}
                    defaultColDef={defaultAskMeSenpaiColumnDefs}
                    animateRows={true}
                    rowSelection="multiple"
                    onGridReady={onGridRandomQuestionReady}
                    onCellEditRequest={onGridCellEditing}
                />
            </div>
            <p></p>
            <Stack direction="horizontal" gap={3}>
                <Button variant="outline-success" disabled={randomQuestions.length !== 1} onClick={() => {
                    askMeSenpaiService.setResult(true, randomQuestions[0].questionId)
                    setRandomQuestions([getRandomQuestionsFromMultipleCategories(selectedCategoryIdsForRandomQuestion, questions)])
                }} >Pass!</Button>
                <Button variant="primary" disabled={randomQuestions.length !== 1} onClick={() => {
                    askMeSenpaiService.setResult(false, randomQuestions[0].questionId)
                    setRandomQuestions([getRandomQuestionsFromMultipleCategories(selectedCategoryIdsForRandomQuestion, questions)])
                }} >Nani?</Button>
                <div>{formatString(t("SENPAI_Statistics"), statistics.questionsAnswered.toString(), statistics.totalQuestions.toString())}</div>
                <div className="vr"></div>
                <div>{t("GENERIC_Filter")} Senpai:</div>
                <MultiSelect onChange={(event: []) => {
                    setSelectedCategoryIdsForRandomQuestion(event)
                }} options={options} />
            </Stack>
        </div>
    )
}