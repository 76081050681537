import { QuestionCategoryModel } from "../../@types/QuestionCategoryModel"
import { QuestionModel } from "../../@types/QuestionModel"
import { getRandom } from "../../core/GenericHelper"

export const getCategoryNameFromId = (categoryId: number | null, categories: QuestionCategoryModel[]) => {
    return categories.find((x) => x.questionCategoryId === categoryId)?.categoryName ?? null
}

export const getRandomQuestionsFromMultipleCategories = (selectedCategoryIdsForRandomQuestion: any, questions: QuestionModel[]) => {
    if (selectedCategoryIdsForRandomQuestion.length > 0) {
        return getRandomQuestion(questions, selectedCategoryIdsForRandomQuestion[getRandom(selectedCategoryIdsForRandomQuestion.length - 1)].value)
    }
    return getRandomQuestion(questions, -1)
}
export const getRandomQuestion = (questions: QuestionModel[], selectedCategoryIdForRandomQuestion: number) => {
    const anyCategory = selectedCategoryIdForRandomQuestion === -1

    if (questions.length > 0 && !anyCategory) {
        const prioData = questions.filter(x =>
            (x.categoryId ?? 0) === selectedCategoryIdForRandomQuestion &&
            (x.numberOfTimesShown < 1 || x.numberOfTimesAnsweredCorrectly < 1)
        )
        const takePrioData: boolean = prioData.length > 0

        const nonPrioData = questions.filter(x =>
            (x.categoryId ?? 0) === selectedCategoryIdForRandomQuestion)

        if (nonPrioData?.length > 0) {
            let randomQuestion = takePrioData ?
                prioData[getRandom(prioData.length - 1)] : nonPrioData[getRandom(nonPrioData.length - 1)]

            if (randomQuestion?.numberOfTimesAnsweredCorrectly > 10) {
                randomQuestion = nonPrioData[getRandom(nonPrioData.length - 1)]
            }
            return randomQuestion
        }
        return questions[getRandom(questions.length - 1)]
    }
    return questions[getRandom(questions.length - 1)]
}