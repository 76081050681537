import * as React from "react"
import { useState } from "react"
import { Form } from "react-bootstrap"
import { PartnerlistGroupModel } from "../../@types/PartnerlistGroupModel"
import { PartnerlistItemModel } from "../../@types/PartnerlistItemModel"

function isEnterOrEscapeKeyEvent(event: any) {
    return event.key === "Enter" || event.key === "Escape"
}

export interface IEditOnDoubleClickProps {
    text: string,
    data: PartnerlistGroupModel | PartnerlistItemModel,
    onChange(model: any),

}
const EditOnDoubleClick = (props: IEditOnDoubleClickProps) => {
    const [isEditing, setisEditing] = useState(false)
    const [text, settext] = useState(props.text)


    const onEditEnd = () => {
        setisEditing(false)
        const newModel = { ...props.data }
        if (newModel.displayName !== text) {
            newModel.displayName = text
            props.onChange(newModel)
        }
    }

    return (
        <div>
            <If condition={isEditing}>
                <Form.Control
                    value={text}
                    style={{ height: "20px", width:"120px" }}
                    onKeyDown={(event) => {
                        if (isEnterOrEscapeKeyEvent(event)) {
                            event.preventDefault()
                            event.stopPropagation()
                            onEditEnd()
                        }
                    }}
                    onChange={(ev) => { settext(ev.target.value) }}
                    onBlur={onEditEnd}
                    autoFocus
                />
            </If>
            <If condition={!isEditing}>
                <div onDoubleClick={() => setisEditing(true)}>
                    {text}
                </div>
            </If>
        </div>
    )
}

export default EditOnDoubleClick