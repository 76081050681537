import React from "react"
import type { CSSProperties, ReactNode } from "react"
import { useDrag, useDrop } from "react-dnd"
import { PartnerlistGroupModel } from "../../@types/PartnerlistGroupModel"
import { useAppSelector } from "../../app/hooks"
import { Themes, ThemeServiceContext } from "../../core/Themes"
import { PartnerlistItemModel } from "../../@types/PartnerlistItemModel"


function getStyle(backgroundColor: string): CSSProperties {
    return {
        //border: "1px solid black",

        //minWidth: "2rem",
        //color: "white",
        backgroundColor,
        //paddingTop: "0.2rem",
        //margin: "1rem",
        //textAlign: "center",
        //float: "left",
        //fontSize: "1rem",
        //width: "",
    }
}

export interface IDragAndDropWrapperProps {
    greedy?: boolean
    children?: ReactNode
    type?: string
    modelData?: any
    modelChangeCallback?(dropped: DraggableProps, target: DraggableProps): any
}

export const DragAndDropWrapperItemTypes = {
    PARTNER: "partner",
    GROUP: "group",
}

export const PARTNERLIST_NONEGROUP_ID = -1

export const CheckIfTargetGroupIsChildOfSourceGroup = (sourceGroupId: number, targetGroupId: number, groups: PartnerlistGroupModel[]) => {
    if (sourceGroupId === targetGroupId) {
        return true
    }
    const targetGroup = groups.find(x => x.groupId === targetGroupId)
    if (targetGroup?.parentGroupId === undefined) {
        //end of recursion
        return false
    }
    if (targetGroup.parentGroupId === sourceGroupId) {
        return true
    }
    return CheckIfTargetGroupIsChildOfSourceGroup(sourceGroupId, targetGroup.parentGroupId, groups)
}

export const DragAndDropWrapper = (props: IDragAndDropWrapperProps) => {
    const groups = useAppSelector((state) => state.partnerlistState.groups)
    const partners = useAppSelector((state) => state.partnerlistState.partners)
    const themeService = React.useContext(ThemeServiceContext)
    const theme = Themes[themeService.theme]
    const [{ isOver, isOverCurrent }, drop] = useDrop(
        () => ({
            accept: [DragAndDropWrapperItemTypes.GROUP, DragAndDropWrapperItemTypes.PARTNER],
            drop(_item: unknown, monitor) {
                const didDrop = monitor.didDrop()
                if (didDrop && !props.greedy) {
                    return
                }

                return {
                    type: props.type,
                    item: props.type === DragAndDropWrapperItemTypes.PARTNER ? props.modelData.partnerlistItemId : props.modelData.groupId,
                    model: props.modelData,
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                groups: groups
            }),
            canDrop(_item: unknown, monitor: any) {
                const sourceId = monitor.internalMonitor.registry.dragSources.get(monitor.internalMonitor.getSourceId()).spec.item
                const source = monitor.getItemType() === DragAndDropWrapperItemTypes.GROUP ? groups.find(x => x.groupId === sourceId) : partners.find(x => x.partnerlistItemId === sourceId)
                const target = props.modelData
                if (monitor.isOver({ shallow: true })) {
                    //console.log("Source: " + JSON.stringify(source))
                    //console.log("Target: " + JSON.stringify(target))

                    if (props.type === DragAndDropWrapperItemTypes.PARTNER) {
                        return false
                    }
                    if (source === undefined) {
                        //if not a bug this can only be the "none Group"
                        return false
                    }
                    //if source and target are groups
                    if (monitor.getItemType() === DragAndDropWrapperItemTypes.GROUP && props.type === DragAndDropWrapperItemTypes.GROUP) {
                        if (CheckIfTargetGroupIsChildOfSourceGroup(source.groupId, target.groupId, groups)) {
                            return false
                        }
                    }
                   
                    return true
                }
                return false
            }
        }),
        [props.greedy, groups, partners],
    )

    let backgroundColor = `var(--bs-${theme})`

    if (isOverCurrent || (isOver && props.greedy)) {
        backgroundColor = "var(--color-theme-accent)"
    }

    return (
        <div ref={drop} style={getStyle(backgroundColor)}>
            <Draggable model={props.modelData} item={props.type === DragAndDropWrapperItemTypes.PARTNER ? props.modelData.partnerlistItemId : props.modelData.groupId} type={props.type} modelChangeCallback={props.modelChangeCallback} >{props.children}</Draggable>
        </div>
    )
}

interface DraggableProps {
    type: string,
    item: number,
    model: PartnerlistItemModel | PartnerlistGroupModel
    children?: ReactNode,
    modelChangeCallback?(dropped: DraggableProps, target: DraggableProps): any
}

export interface DragResult {
    type: string,
    item: number,
    model: PartnerlistItemModel | PartnerlistGroupModel
}

const Draggable = (props: DraggableProps) => {
    const style = {
        //color: "black",
        //display: "inline-block",
        //border: "1px dashed gray",
        //padding: "0.5rem 0rem 0.0rem 0rem",
        //backgroundColor: "white",
        cursor: "pointer",
    }
    const [{ isDragging }, drag] = useDrag(() => ({
        type: props.type,
        item: props.item,
        end: (item, monitor) => {
            const target = monitor.getDropResult<DragResult>()
            if (item && target) {
                props?.modelChangeCallback(props, target)
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
        canDrag: ((monitor) => {
            if (props.model.groupId === PARTNERLIST_NONEGROUP_ID) {
                return false
            }
            return true
        }),
    }))

    const opacity = isDragging ? 0.4 : 1
    return (
        <div ref={drag} style={{ ...style, opacity }}>{props.children}</div>
    )
}