import * as React from "react"
import { SessionServiceContext } from "./SessionService"
import { Navigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IdentitySpaPaths, LogoutActions, QueryParameterNames } from "../../../app/AppConstants"
import { PopupType } from "../../popupmessage/reducer/popupReducer"
import { useAppDispatch } from "../../../app/hooks"
import { setIsLoading, setIsLoggedIn } from "../../../app/reducer/appStateReducer"
import { resetSocket } from "../../../core/network/reducer/socketReducer"

interface ILogoutProps {
    action: LogoutActions
}

export const LogoutFlow = (props: ILogoutProps) => {  
    const { t } = useTranslation()
    const [action, setAction] = React.useState<LogoutActions | undefined>(props.action)
    const sessionService = React.useContext(SessionServiceContext)
    const dispatch = useAppDispatch()
    React.useEffect(() => {
        switch (action) {
            case LogoutActions.Logout:
                logout()
                break
            case LogoutActions.LogoutCallback:
                processLogoutCallback()
                break
        }
    }, [action, setAction])

    const logout = () => {
        dispatch(setIsLoading(true))
        sessionService.signOut().then((success) => {
            if (success) {
                setAction(LogoutActions.LoggedOut)
                dispatch(setIsLoggedIn(false))
                dispatch(resetSocket())
                
            } else {
                setAction(LogoutActions.LogoutFailed)
            }
            dispatch(setIsLoading(false))           
        })
    }
    //only executed in iframe
    const processLogoutCallback = () => {
        const url = window.location.href
        sessionService.completeSignOut(url)
    }

    switch (action) {
        case LogoutActions.LogoutCallback:
        case LogoutActions.Logout:
            return (<div className="p-2">{t("LOGOUTFLOW_LogoutInProgress")}</div>)
        case LogoutActions.LoggedOut:
            return <Navigate to={{
                pathname: IdentitySpaPaths.LoginPage,
                search: `?${QueryParameterNames.Message}=${t("errors:SPA_LOGOUT_SUCCESS")}&${QueryParameterNames.MessageType}=${PopupType.Normal}`,
            }} />
        case LogoutActions.LogoutFailed:
            return <Navigate to={{
                pathname: IdentitySpaPaths.LoginPage,
                search: `?${QueryParameterNames.Message}=${t("errors:SPA_LOGOUT_ERROR_SignOutError") }&${QueryParameterNames.MessageType}=${PopupType.Error}`,
            }} />
        default:
            return (<div> </div>)
    }
}