import "bootstrap/dist/css/bootstrap.css"
import * as React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { store } from "./app/store"
import { Provider } from "react-redux"
import { App } from "./app/App"
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") ?? ""
const rootElement = document.getElementById("root")

//bundle the translation subsystem
import "./core/translations/translationLibConfig"
import SessionService from "./components/user/session/SessionService"
import AccountService from "./components/user/account/reducer/AccountService"
import ProfileService from "./components/user/profile/reducer/ProfileService"
import ToDoService from "./components/todolist/reducer/ToDoService"
import AskMeSenpaiService from "./components/askmesenpai/reducer/AskMeSenapiService"
import { ThemeService } from "./core/Themes"
import PartnerlistService from "./components/partnerlist/reducer/PartnerlistService"

const root = createRoot(rootElement!)

root.render(

    <Provider store={store}>
        <BrowserRouter basename={baseUrl}>
            <ThemeService>
                <SessionService>
                    <AccountService>
                        <ProfileService>
                            <PartnerlistService>
                                <ToDoService>
                                    <AskMeSenpaiService>
                                        <App />
                                    </AskMeSenpaiService>
                                </ToDoService>
                            </PartnerlistService>
                        </ProfileService>
                    </AccountService>
                </SessionService>
            </ThemeService>
        </BrowserRouter>
    </Provider>
)