import * as React from "react"
import { Component, useState } from "react"

import { close, PopupState, PopupType } from "./reducer/popupReducer"
import { useAppSelector, useAppDispatch } from "../../app/hooks"

import { Button, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"

export const PopupMessage = () => {
    const dispatch = useAppDispatch() as any
    const popupState: PopupState = useAppSelector((state) => state.popupState)
    const [popTypeCssClass, setPopTypeCssClass] = useState("")
    const HandleCloseModal = () => {
        dispatch(close())
    }

    React.useEffect(() => {
        switch (popupState.popupType) {
            case PopupType.Normal: {
                setPopTypeCssClass("popupSuccess")
                break
            }
            case PopupType.Error: {
                setPopTypeCssClass("popupError")
                break
            }
            case PopupType.Warning: {
                setPopTypeCssClass("popupWarning")
                break
            }
        }
    }, [popupState.popupType])

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            dispatch(close())
        }
    }
    const { t } = useTranslation()
    return (
        <div onKeyDown={(e) => handleKeyDown(e)}>
            <Modal show={popupState.showPopup} onHide={HandleCloseModal} dialogClassName={popTypeCssClass}>
                <Modal.Header closeButton>
                    <Modal.Title>{popupState.headline}</Modal.Title>
                </Modal.Header>
                <Modal.Body >{popupState.content}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={HandleCloseModal}>
                        {t("GENERIC_Ok")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}