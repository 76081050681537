import * as React from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { IdentitySpaPaths } from "../../../app/AppConstants"
import { useAppSelector } from "../../../app/hooks"
import { formatString } from "../../../core/GenericHelper"
import { ProfileServiceContext } from "./reducer/ProfileService"

export const ConnectedLoginsFragment = () => {
    const profileService = React.useContext(ProfileServiceContext)
    const loginManagementData = useAppSelector((state) => state.profileState.loginManagementData)

    const onRemove = () => {
        profileService.removeConnectedLogin({
            loginProvider: loginManagementData.currentLogins[0].loginProvider,
            providerKey: loginManagementData.currentLogins[0].providerKey
        })
    }
    const { t } = useTranslation()
    return (
        <div>
            <h3>{t("CONNECTEDLOGINS_Title")}</h3>
            <If condition={loginManagementData.currentLogins.length === 0}>
                {t("CONNECTEDLOGINS_None")}
            </If>
            <If condition={loginManagementData.currentLogins.length > 0}>

                <p>{formatString(t("CONNECTEDLOGINS_TransferMessage"), loginManagementData.currentLogins[0].loginProvider)}</p>
                <hr></hr>
                <If condition={!loginManagementData?.canRemove}>
                    <p>{t("CONNECTEDLOGINS_PasswordRequirement")} <Link to={IdentitySpaPaths.ChangePassword}>here</Link></p>
                </If>
                <p> <Button variant="primary" onClick={onRemove} disabled={!loginManagementData.canRemove}>{t("GENERIC_Remove_Imperative")}: {loginManagementData.currentLogins[0].loginProvider}</Button></p>
            </If>
        </div>
    )
}