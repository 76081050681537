import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import { AskMeSenpaiNotificationPayload } from "../../../@types/AskMeSenpaiNotificationPayload"
import { ToDoListNotificationPayload } from "../../../@types/ToDoListNotificationPayload"
import { PartnerlistNotificationPayload } from "../../../@types/PartnerlistNotificationPayload"
import { PartnerlistItemModel } from "../../../@types/PartnerlistItemModel"
import { PartnerlistNotificationType } from "../../../@types/PartnerlistNotificationType"
import { PartnerLifeTimeState } from "../../../@types/PartnerLifeTimeState"

export type NotificationCenterEntry = {
    id: string
    payload: AskMeSenpaiNotificationPayload | ToDoListNotificationPayload | PartnerlistNotificationPayload
    typeName: string
}
export type NotificationCenterState = {
    notifications: Array<NotificationCenterEntry>
}

const initialState: NotificationCenterState = {
    notifications: [],
}

export const notificationCenterReducer = createSlice({
    name: "notificationCenterReducer",
    initialState,
    reducers: {
        partnerAdded: (state: NotificationCenterState, action: PayloadAction<PartnerlistNotificationPayload>) => {
            if (action.payload === undefined) {
                return
            }            
            state.notifications.push({
                id: action.payload.partner.partnerUserId,
                payload: action.payload,
                typeName: "PartnerlistNotificationPayload"
            })
        },
        partnersLoaded: (state: NotificationCenterState, action: PayloadAction<PartnerlistNotificationPayload>) => {
            action.payload.partnerlist.forEach((item: PartnerlistItemModel) => {
                if (item.state === PartnerLifeTimeState.WaitingForApproval) {
                    state.notifications.push({
                        id: item.partnerUserId,
                        payload: {
                            type: PartnerlistNotificationType.AddPartnerlistPartnerRequestReceived,
                            partner: item
                        },
                        typeName: "PartnerlistNotificationPayload"
                    })
                }
            })
        },
        partnerEdited: (state: NotificationCenterState, action: PayloadAction<PartnerlistNotificationPayload>) => {
            if (action.payload.partner.state === PartnerLifeTimeState.Mutual) {
                state.notifications.forEach((item: NotificationCenterEntry, index) => {
                    if (item.id === action.payload.partner.partnerUserId) {
                        state.notifications.splice(index, 1)
                    }
                })
            }
        },
        partnerDeleted: (state: NotificationCenterState, action: PayloadAction<PartnerlistNotificationPayload>) => {            
            if (action.payload.type === PartnerlistNotificationType.DeletePartnerlistPartner) {
                state.notifications.forEach((item: NotificationCenterEntry, index) => {
                    if (item.id === action.payload.partner.partnerUserId) {
                        state.notifications.splice(index, 1)
                    }
                })
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    partnerAdded,
    partnersLoaded,
    partnerDeleted,
    partnerEdited,
} = notificationCenterReducer.actions

// Other code such as selectors can use the imported `RootState` type
//do we need this?
export const notificationReducerState = (globalState: RootState) => globalState.notificationCenterState

export default notificationCenterReducer.reducer