import React, { useMemo } from "react"

import { useAppSelector } from "../../../app/hooks"
import { ICellRendererParams } from "@ag-grid-community/core"
import { getCategoryNameFromId } from "../AskMeSenpaiHelper"
import { QuestionCategoryModel } from "../../../@types/QuestionCategoryModel"
import { useTranslation } from "react-i18next"

export const QuestionCategoryGridCellRenderer = (props: ICellRendererParams) => {
    const categories = useAppSelector((state) => state.askMeSenpaiState.categories)
    const { t } = useTranslation()
    const noneCategory: QuestionCategoryModel = {
        categoryDescription: "",
        categoryName: t("GENERIC_None"),
        id: 0
    }
    const categoriesData = React.useMemo(() => {
        return [noneCategory, ...categories].sort((x, y) => x.id - y.id)
    }, [categories])

    const valueToRender = useMemo(() => getCategoryNameFromId(props.value, categoriesData), [props.value])
    return (
        <div>{valueToRender}</div >
    )
}