import * as React from "react"
import { NavDropdown } from "react-bootstrap"
import { useAppSelector } from "../../app/hooks"
import { useTranslation } from "react-i18next"
import { Badge, mapOnlineStateToBadgeColor } from "./sidebar/Badge"
import { PartnerlistServiceContext } from "../partnerlist/reducer/PartnerlistService"
import { OnlineState } from "../../@types/OnlineState"
import { OnlineStateModel } from "../../@types/OnlineStateModel"

export const OnlineStatePanel = () => {
    const isLoggedIn = useAppSelector((state) => state.appState.isLoggedIn)
    const currentOnlineState = useAppSelector((state) => state.partnerlistState.ownOnlineState)
    const partnerlistService = React.useContext(PartnerlistServiceContext)

    const setOnlineState = (onlineState: OnlineState) => {
        const model: OnlineStateModel = {
            userId: "",
            onlineState: onlineState,
            lastSeen: ""
        }
        partnerlistService.setOnlineState(model)
        localStorage.setItem("lastonlinestate", JSON.stringify(model.onlineState))
    }

    const { t } = useTranslation()

    const controlPanelLabel = () => {
        return (
            <div className="d-inline-flex align-items-center">
                <Badge variant={mapOnlineStateToBadgeColor(currentOnlineState)}>{t("NAV_State")}</Badge>
            </div >
        )
    }

    return (
        <div>
            <NavDropdown title={controlPanelLabel()} id="collasible-nav-dropdown">
                <If condition={isLoggedIn}>
                    <NavDropdown.Item active={currentOnlineState === OnlineState.Online} onClick={() => { setOnlineState(OnlineState.Online) }}>
                        <div className="d-inline-flex align-items-center">
                            <Badge variant={mapOnlineStateToBadgeColor(OnlineState.Online)}></Badge>
                            {t("ONLINESTATE_Online")}
                        </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item active={currentOnlineState === OnlineState.Away} onClick={() => { setOnlineState(OnlineState.Away) }}>
                        <div className="d-inline-flex align-items-center">
                            <Badge variant={mapOnlineStateToBadgeColor(OnlineState.Away)}></Badge>
                            {t("ONLINESTATE_Away")}
                        </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item active={currentOnlineState === OnlineState.DoNotDisturb} onClick={() => { setOnlineState(OnlineState.DoNotDisturb) }}>
                        <div className="d-inline-flex align-items-center">
                            <Badge variant={mapOnlineStateToBadgeColor(OnlineState.DoNotDisturb)}></Badge>
                            {t("ONLINESTATE_DoNotDisturb")}
                        </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item active={currentOnlineState === OnlineState.Offline} onClick={() => { setOnlineState(OnlineState.Offline) }}>
                        <div className="d-inline-flex align-items-center">
                            <Badge variant={mapOnlineStateToBadgeColor(OnlineState.Offline)}></Badge>
                            {t("ONLINESTATE_Offline")}
                        </div>
                    </NavDropdown.Item>
                </If>
            </NavDropdown>
        </div >
    )
}