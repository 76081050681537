import * as React from "react"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { useSearchParams } from "react-router-dom"
import { QueryParameterNames } from "../../../app/AppConstants"
import { useAppSelector } from "../../../app/hooks"
import { TextField, TextFieldType } from "../../../elements/TextField"
import { ProfileServiceContext } from "./reducer/ProfileService"

export const ChangeEmailFragment = () => {
    const profileState = useAppSelector((state) => state.profileState)
    const profileService = React.useContext(ProfileServiceContext)

    const [hasInputErrorNewEmail, setHasInputErrorNewEmail] = useState(true)
    const [newEmail, setNewEmail] = useState("")

    const onSave = () => {
        if (hasInputErrorNewEmail) {
            return
        }
        profileService.requestChangeEmail(newEmail).then(setNewEmail(""))
    }

    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)

    const code = urlParams.get(QueryParameterNames.Code)
    const userId = urlParams.get(QueryParameterNames.UserId)
    const emailToConfirm = urlParams.get(QueryParameterNames.Email)
    const [searchParams, setSearchParams] = useSearchParams()

    React.useEffect(() => {
        if (code && userId && emailToConfirm) {
            profileService.confirmChangeEmail(code, userId, emailToConfirm).then(() => {
                setSearchParams("")
            })
        }
    }, [code, userId, emailToConfirm, searchParams])
    const { t } = useTranslation()
    return (
        <div>
            <h3>{t("errors:SPA_MANAGEPROFILE_ChangeEmail")}</h3>
            <div className="form-floating">
                <TextField disabled={true} label={t("GENERIC_Email")} type={TextFieldType.Email} value={profileState.email} submitCallback={onSave} doNotClearOnSubmit={true} />
            </div>
            <div className="form-floating">
                <TextField label={t("PROFILE_NewEmail")} onChange={setNewEmail} type={TextFieldType.Email} callback={setHasInputErrorNewEmail} value={newEmail} submitCallback={onSave} doNotClearOnSubmit={true} />
            </div>
            <Button variant="primary" onClick={onSave} disabled={hasInputErrorNewEmail} >{t("GENERIC_Ok")}</Button>

        </div>
    )
}