import * as React from "react"
import { Container } from "react-bootstrap"
import { useTranslation } from "react-i18next"

export const Home = () => {
    const { t } = useTranslation()
    return (
        <Container>
            <h1>{t("HOME_Welcome")}</h1>
            <p><strong>{t("HOME_TechStack")}</strong></p>
            <div>
                <ul>
                    <li><strong>{t("HOME_TECHSTACK_BODY_1")}</strong></li>
                    <li>{t("HOME_TECHSTACK_BODY_2")}</li>
                    <li>{t("HOME_TECHSTACK_BODY_3")}</li>
                    <li>{t("HOME_TECHSTACK_BODY_4")}</li>
                    <li>{t("HOME_TECHSTACK_BODY_5")}</li>
                    <li>{t("HOME_TECHSTACK_BODY_6")}</li>
                    <li>{t("HOME_TECHSTACK_BODY_7")}</li>
                    <li>{t("HOME_TECHSTACK_BODY_8")}</li>
                    <li>{t("HOME_TECHSTACK_BODY_9")}</li>
                    <li>{t("HOME_TECHSTACK_BODY_10")}</li>
                    <li>{t("HOME_TECHSTACK_BODY_11")}</li>
                    <li>{t("HOME_TECHSTACK_BODY_12")}</li>
                </ul>
            </div>
            <p><strong>{t("HOME_Features")}</strong></p>
            <div>
                <ul>
                    <li>{t("HOME_FEATURES_BODY_1")}</li>
                    <li>{t("HOME_FEATURES_BODY_2")}</li>
                    <li>{t("HOME_FEATURES_BODY_3")}</li>
                    <li>{t("HOME_FEATURES_BODY_4")}</li>
                    <li>{t("HOME_FEATURES_BODY_5")}</li>
                </ul>
            </div>
            <p><strong>{t("HOME_SecurityDataProtection")}</strong></p>
            <div>
                <ul>
                    <li>{t("HOME_SECURITYDATAPROTECTION_BODY_1")}</li>
                    <li>{t("HOME_SECURITYDATAPROTECTION_BODY_2")}</li>
                    <li>{t("HOME_SECURITYDATAPROTECTION_BODY_3")}</li>
                </ul>
            </div>
            <p><strong>{t("HOME_Development")}</strong></p>
            <div>
                <ul>
                    <li>{t("HOME_DEVELOPMENT_BODY_1")}</li>
                    <li>{t("HOME_DEVELOPMENT_BODY_2")}</li>
                    <li>{t("HOME_DEVELOPMENT_BODY_3")}</li>
                    <li>{t("HOME_DEVELOPMENT_BODY_4")}</li>
                    <li>{t("HOME_DEVELOPMENT_BODY_5")}</li>
                </ul>
            </div>
            <p><strong>{t("HOME_Upcomming")}</strong></p>
            (untranslated)
            <ul>
                <li>side panel / notification center</li>         
                <li>Web3 Wallet connect</li>
            </ul>
        </Container>
    )
}