import * as React from "react"
import { IdentitySpaPaths, ProfileActions } from "../../../app/AppConstants"
import { Link } from "react-router-dom"
import { ProfileFragment } from "./ProfileFragment"
import { ConnectedLoginsFragment } from "./ConnectedLoginsFragment"
import { DataPrivacyFragment } from "./DataPrivacyFragment"
import { ManageTfaFragment } from "./ManageTfaFragment"
import { ChangePasswordFragment } from "./ChangePasswordFragment"
import { ChangeEmailFragment } from "./ChangeEmailFragment"
import { Col, Container, Nav, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"

export interface IProfileProps {
    action: ProfileActions
}
export const ProfilePage = (props: IProfileProps) => {
    const { t } = useTranslation()

    return (
        <Container>
            <h1>{t("PROFILEPAGE_Title")}</h1>
            <h2>{t("PROFILEPAGE_Subtitle")}</h2>
            <hr />
            <Row>
                <Col md="auto">
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link active={props.action === ProfileActions.Change} to={IdentitySpaPaths.Profile} as={Link}>{t("PROFILEPAGE_ProfileNav")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link active={props.action === ProfileActions.ChangeEmail} to={IdentitySpaPaths.ChangeEmail} as={Link}>{t("GENERIC_Email")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link active={props.action === ProfileActions.ChangePassword} to={IdentitySpaPaths.ChangePassword} as={Link}>{t("GENERIC_Password")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link active={props.action === ProfileActions.ExternalLogins} to={IdentitySpaPaths.ExternalLogins} as={Link}>{t("PROFILEPAGE_ExternalLoginsNav")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link active={props.action === ProfileActions.ManageTfa} to={IdentitySpaPaths.ManageTfa} as={Link}>{t("PROFILEPAGE_TFANav")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link active={props.action === ProfileActions.DataPrivacy} to={IdentitySpaPaths.DataPrivacy} as={Link}>{t("PROFILEPAGE_PrivacyNav")}</Nav.Link>
                        </Nav.Item>

                    </Nav>
                </Col>
                <Col>
                    <If condition={props.action === ProfileActions.Change}>
                        <ProfileFragment />
                    </If>
                    <If condition={props.action === ProfileActions.ChangeEmail}>
                        <ChangeEmailFragment />
                    </If>
                    <If condition={props.action === ProfileActions.ChangePassword}>
                        <ChangePasswordFragment />
                    </If>
                    <If condition={props.action === ProfileActions.ExternalLogins}>
                        <ConnectedLoginsFragment />
                    </If>
                    <If condition={props.action === ProfileActions.ManageTfa}>
                        <ManageTfaFragment />
                    </If>
                    <If condition={props.action === ProfileActions.DataPrivacy}>
                        <DataPrivacyFragment />
                    </If>
                </Col>
            </Row>
        </Container >

    )
}