export const ApplicationName = "Tiphy.Main.Web"

//It is only allowed to read query parameters in loginpage.tsx
//urlParams.get is CASE SENSITIVE !!!
export const enum QueryParameterNames {
    Message = "message",
    Code = "code",
    UserId = "userid",
    MessageType = "type",
    Email = "email",
    //Swagger Ui Sends this in CamelCase so we have to fix this in loginpage.tsx
    ReturnUrl = "ReturnUrl"
}

//loginflow.tsx
export const enum LogoutActions {
    LogoutCallback = "logout-callback",
    Logout = "logout",
    LoggedOut = "logged-out",
    LogoutFailed = "logout-failed"
}
//logoutflow.tsx
export const enum LoginActions {
    Login = "login",
    LoginCallback = "login-callback",
    LoginFailed = "login-failed",
    LoginSuccess = "login-success",
}

//App.tsx
const authenticationPrefix = "/authentication"
export const AuthorizationFlowPaths = {
    ApiAuthorizationPrefix: authenticationPrefix,
    Login: `${authenticationPrefix}/${LoginActions.Login}`,
    LoginFailed: `${authenticationPrefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${authenticationPrefix}/${LoginActions.LoginCallback}`,
    LogOut: `${authenticationPrefix}/${LogoutActions.Logout}`,
    LoggedOut: `${authenticationPrefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${authenticationPrefix}/${LogoutActions.LogoutCallback}`,
    LogOutFailed: `${authenticationPrefix}/${LogoutActions.LogoutFailed}`,
}

export const enum ProfileActions {
    ChangeEmail = "changeemail",
    Change = "change",
    ChangePassword = "changepassword",
    ExternalLogins = "externallogins",
    ManageTfa = "managetfa",
    DataPrivacy = "dataprivacy"
}

export const enum LoginPageActions {
    Login = "login",
    SignUp = "signup",
    ForgotPassword = "forgotpassword",
    ResendActivationMail = "resendactivationmail",
    ResetPassword = "resetpassword",
    RegistrationSucces = "registrationsuccess"
}
//SPA Routes für Identity
const identitySpaPrefix = "/usr"
export const IdentitySpaPaths = {
    Home: "/",
    LoginPage: `${identitySpaPrefix}/login`,
    Logout: `${identitySpaPrefix}/logout`,
    SignUp: `${identitySpaPrefix}/signup`,
    SignUpSuccess: `${identitySpaPrefix}/signup/success`,
    ForgotPassword: `${identitySpaPrefix}/password/forgot`,
    ResetPassword: `${identitySpaPrefix}/password/reset`,
    ResendActivationMail: `${identitySpaPrefix}/email/resendactivationmail`,
    Profile: `${identitySpaPrefix}/profile/${ProfileActions.Change}`,
    ChangeEmail: `${identitySpaPrefix}/profile/${ProfileActions.ChangeEmail}`,
    ChangePassword: `${identitySpaPrefix}/profile/${ProfileActions.ChangePassword}`,
    ConfirmChangePassword: `${identitySpaPrefix}/profile/${ProfileActions.ChangePassword}/confirm`,
    ExternalLogins: `${identitySpaPrefix}/profile/${ProfileActions.ExternalLogins}`,
    ManageTfa: `${identitySpaPrefix}/profile/${ProfileActions.ManageTfa}`,
    DataPrivacy: `${identitySpaPrefix}/profile/${ProfileActions.DataPrivacy}`,
}

//Backend Routes for Identity
const identityApiPrefix = "user"
export const IdentityApiPaths = {
    Login: `${identityApiPrefix}/${LoginActions.Login}/local`,
    Logout: `${identityApiPrefix}/${LogoutActions.Logout}`,
    ReadExternalLogins: `${identityApiPrefix}/${LoginActions.Login}/external/supported`,
    LoginWithExternalProviderChallenge: `${identityApiPrefix}/${LoginActions.Login}/external/challenge`,
    LoginTFA: `${identityApiPrefix}/${LoginActions.Login}/local/tfa`,
    LoginTFARecovery: `${identityApiPrefix}/${LoginActions.Login}/local/tfarecovery`,
    RequestResetPassword: `${identityApiPrefix}/password/reset/request`,
    ResetPassword: `${identityApiPrefix}/password/reset`,
    SignUpLocal: `${identityApiPrefix}/signup/local`,
    ConfirmEmail: `${identityApiPrefix}/signup/email/confirm`,
    ResendActivationEmail: `${identityApiPrefix}/signup/email/resendverification`,
    CookieConsentStatus: `${identityApiPrefix}/features/cookies/status`,
    GiveCookieConsent: `${identityApiPrefix}/features/cookies/consent`,
    SetLanguage: `${identityApiPrefix}/features/language/set`,
}

export const IdentityProfileApiPaths = {
    ReadProfile: `${identityApiPrefix}/profile/general/read`,
    UpdateProfile: `${identityApiPrefix}/profile/general/update`,
    RequestChangeEmail: `${identityApiPrefix}/profile/email/change`,
    ConfirmChangeEmail: `${identityApiPrefix}/profile/email/change/confirm`,
    ChangePassword: `${identityApiPrefix}/profile/password/change`,
    SetPassword: `${identityApiPrefix}/profile/password/set`,
    ReadConnectedLogins: `${identityApiPrefix}/profile/external/connected`,
    RemoveConnectedLogins: `${identityApiPrefix}/profile/external/remove`,
    DeleteAccount: `${identityApiPrefix}/profile/dataprivacy/delete`,
    DataSubjectRequest: `${identityApiPrefix}/profile/dataprivacy/download`,
    ReadTFA: `${identityApiPrefix}/profile/tfa/read`,
    RemoveCurrentBrowserFromTFA: `${identityApiPrefix}/profile/tfa/removebrowser`,
    RemoveTFA: `${identityApiPrefix}/profile/tfa/remove`,
    ToggleTFA: `${identityApiPrefix}/profile/tfa/toggle`,
    ReadTFAActivationData: `${identityApiPrefix}/profile/tfa/activationdata`,
    ActivateTFA: `${identityApiPrefix}/profile/tfa/activate`,
    GenerateRecoveryCode: `${identityApiPrefix}/profile/tfa/generaterecoverycode`,
}

//Features
export const enum FeatureName {
    AskMeSenpai = "askmesenpai",
    ToDoList = "todolist",
    SocketTest = "sockettest",
    ApiDocs = "docs",
    Partnerlist = "partnerlist",
}

//SPA Routes for Features
export const featurePathPrefix = "app"
export const FeatureSpaPaths = {
    AskMeSenpai: `${featurePathPrefix}/${FeatureName.AskMeSenpai}`,
    ToDoList: `${featurePathPrefix}/${FeatureName.ToDoList}`,
    SocketTest: `${featurePathPrefix}/${FeatureName.SocketTest}`,
    ApiDocs: `/${FeatureName.ApiDocs}`,
}

//Backend Routes for Features
const apiPrefix = "api"
export const FeatureApiPaths = {
    Questions: {
        ReadQuestions: `${apiPrefix}/${FeatureName.AskMeSenpai}/questions/read`,
        AddQuestion: `${apiPrefix}/${FeatureName.AskMeSenpai}/questions/add`,
        UpdateQuestion: `${apiPrefix}/${FeatureName.AskMeSenpai}/questions/update`,
        DeleteQuestion: `${apiPrefix}/${FeatureName.AskMeSenpai}/questions/delete`,
        SetResult: `${apiPrefix}/${FeatureName.AskMeSenpai}/questions/setresult`,
        Import: `${apiPrefix}/${FeatureName.AskMeSenpai}/questions/import`,
        ReadCategories: `${apiPrefix}/${FeatureName.AskMeSenpai}/categories/read`,
        AddCategories: `${apiPrefix}/${FeatureName.AskMeSenpai}/categories/add`,
        DeleteCategories: `${apiPrefix}/${FeatureName.AskMeSenpai}/categories/delete`,
        UpdateCategories: `${apiPrefix}/${FeatureName.AskMeSenpai}/categories/update`,
    },
    ToDoList: {
        Read: `${apiPrefix}/${FeatureName.ToDoList}/read`,
        Add: `${apiPrefix}/${FeatureName.ToDoList}/add`,
        Edit: `${apiPrefix}/${FeatureName.ToDoList}/edit`,
        Delete: `${apiPrefix}/${FeatureName.ToDoList}/delete`,
    },
    Partnerlist: {
        ReadPartners: `${apiPrefix}/${FeatureName.Partnerlist}/partners/read`,
        AddPartner: `${apiPrefix}/${FeatureName.Partnerlist}/partners/add`,
        ConfirmPartner: `${apiPrefix}/${FeatureName.Partnerlist}/partners/confirm`,
        EditPartner: `${apiPrefix}/${FeatureName.Partnerlist}/partners/update`,
        DeletePartner: `${apiPrefix}/${FeatureName.Partnerlist}/partners/delete`,
        ReadGroups: `${apiPrefix}/${FeatureName.Partnerlist}/groups/read`,
        AddGroup: `${apiPrefix}/${FeatureName.Partnerlist}/groups/add`,
        EditGroup: `${apiPrefix}/${FeatureName.Partnerlist}/groups/update`,
        DeleteGroup: `${apiPrefix}/${FeatureName.Partnerlist}/groups/delete`,
        SetOnlineState: `${apiPrefix}/${FeatureName.Partnerlist}/setonlinestate`,
    },
    ProfilePictures: {
        UploadProfilePicture: `${identityApiPrefix}/profile/picture/upload`,
        DeleteProfilePicture: `${identityApiPrefix}/profile/picture/delete`,
    }
}