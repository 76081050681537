import React, {
    Fragment,
    useEffect,
    useImperativeHandle,
    useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../../app/hooks"

export const QuestionsGridCategoryFilter = React.forwardRef((props: any, ref) => {
    const [filterText, setFilterText] = useState(null)
    const categories = useAppSelector((state) => state.askMeSenpaiState.categories)
    // expose AG Grid Filter Lifecycle callbacks
    const { t } = useTranslation()

    useImperativeHandle(ref, () => {
        return {
            doesFilterPass(params) {
                const { api, colDef, column, columnApi, context } = props
                const { node } = params

                // make sure each word passes separately, ie search for firstname, lastname
                let passed = true
                filterText
                    .toLowerCase()
                    .split(" ")
                    .forEach((filterWord) => {
                        const value = props.valueGetter({
                            api,
                            colDef,
                            column,
                            columnApi,
                            context,
                            data: node.data,
                            getValue: (field) => node.data[field],
                            node,
                        })

                        if (value && categories.find(x => x.id === value).categoryName.toLowerCase().indexOf(filterWord) < 0) {
                            passed = false
                        }
                        if (!value && t("GENERIC_None").toLowerCase().indexOf(filterWord) < 0) {
                            passed = false
                        }
                    })

                return passed
            },

            isFilterActive() {
                return filterText != null && filterText !== ""
            },

            getModel() {
                if (!this.isFilterActive()) {
                    return null
                }

                return { value: filterText }
            },

            setModel(model) {
                setFilterText(model == null ? null : model.value)
            },
            setFilter(value: string) {
                setFilterText(value)
            }
        }
    })

    const onChange = (event) => {
        setFilterText(event.target.value)
    }

    useEffect(() => {
        props.filterChangedCallback()
    }, [filterText])

    return (
        <div>
            <input
                type="text"
                value={filterText ?? ""}
                onChange={onChange}
                placeholder=""
            />
        </div>
    )
})
QuestionsGridCategoryFilter.displayName = "QuestionsGridCategoryFilter"

export const QuestionsGridCategoryFloatingFilter = React.forwardRef((props: any, ref) => {
    const onChange = (event) => {
        if (event.target.value === "") {
            // Remove the filter
            props.parentFilterInstance((instance) => {
                instance.setFilter(event.target.value)
            })
            return
        }
        props.parentFilterInstance((instance) => {
            instance.setFilter(event.target.value)
        })
    }

    return (
        <Fragment>
            <div style={{ padding: 5 }}>
                <input
                    className="ag-input-field-input ag-text-field-input"
                    type="text"
                    onChange={onChange}
                    placeholder=""
                />
            </div>
        </Fragment>
    )
})

QuestionsGridCategoryFloatingFilter.displayName = "QuestionsGridCategoryFilter"