import * as React from "react"
import { useState } from "react"
import { Button, Container, Form, ThemeProvider } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { FeatureApiPaths } from "../../../app/AppConstants"
import { useAppSelector } from "../../../app/hooks"
import { UploadFile } from "../../../core/network/DataRequest"
import { TextField, TextFieldType } from "../../../elements/TextField"
import { ProfileServiceContext } from "./reducer/ProfileService"
import bgImage from "./../../../images/sidebarbg.jpg"
import { BadgeForgeContextProvider } from "../../profilepictureeditor/contexts/BadgeForgeContext"
import { BadgeForge } from "../../profilepictureeditor/components/BadgeForge"
import Avatar from "../../profilepictureeditor/components/Avatar"

export const ProfileFragment = () => {
    const profileState = useAppSelector((state) => state.profileState)
    const profileService = React.useContext(ProfileServiceContext)
    const [hasInputErrorPhoneNumber, setHasInputErrorPhoneNumber] = useState(false)
    const [hasInputErrorUserName, setHasInputErrorUserName] = useState(false)
    const [hasInputError, setHasInputError] = useState(false)

    const [userName, setUserName] = useState(profileState.userName)
    const [phoneNumber, setPhoneNumber] = useState(profileState.phoneNumber)

    const [selectedFile, setSelectedFile] = useState<null | File>(null)
    const [preview, setPreview] = useState("")

    React.useEffect(() => {
        setHasInputError(hasInputErrorUserName || hasInputErrorPhoneNumber)
    }, [hasInputErrorUserName, hasInputErrorPhoneNumber])

    const onSave = () => {
        if (hasInputError) {
            return
        }
        profileService.sendUpdateProfileRequest(userName, phoneNumber)
    }
    const onUploadProfilePicture = async (e) => {
        e.preventDefault()
        await UploadFile(FeatureApiPaths.ProfilePictures.UploadProfilePicture, selectedFile).then(() => {
            setSelectedFile(undefined)
        })
    }
    const onFileChanged = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }
        setSelectedFile(e.target.files[0])
    }

    React.useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        // create the preview
        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const { t } = useTranslation()

    const [previewAvatar, setPreviewAvatar] = React.useState()
    const onClose = () => {
        setPreviewAvatar(null)
    }

    const onCrop = (preview) => {
        setPreviewAvatar(preview)
    }

    const onBeforeFileLoad = (elem) => {
        if (elem.target.files[0].size > 71680000) {
            alert("File is too big!")
            elem.target.value = ""
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <h3>{t("PROFILE_ChangeProfile")}</h3>
            <div className="form-floating">
                <TextField label={t("GENERIC_Username")} onChange={setUserName} type={TextFieldType.Required} callback={setHasInputErrorUserName} value={userName} submitCallback={onSave} />
            </div>
            <div className="form-floating">
                <TextField label={t("GENERIC_Phonenumber")} onChange={setPhoneNumber} type={TextFieldType.Default} callback={setHasInputErrorPhoneNumber} value={phoneNumber} submitCallback={onSave} />
            </div>

            <Button variant="primary" onClick={onSave} disabled={hasInputError}>{t("GENERIC_Ok")}</Button>
            <Form onSubmit={onUploadProfilePicture}>
                <Form.Group controlId="formFile" >
                    <Form.Label>{t("PROFILEPICTURES_HEADLINE")}</Form.Label>
                    <Form.Control type="file" accept=".jpg" onChange={onFileChanged} />
                    <Button className="btn btn-primary btn-large centerButton"
                        type="submit">Send</Button>
                </Form.Group>
            </Form>
            <If condition={selectedFile}>
                <h4>Preview</h4>
                <div>{selectedFile && <img width={"400px"} src={preview} />}</div>
            </If>

            <If condition={!selectedFile}>
                <h4>Avatar</h4>
                <img width={"400px"} src={`https://suitamoistorage.blob.core.windows.net/avatars/${profileState.userId}-raw.jpg`} onError={(e: any) => {
                    e.target.onError = null
                    e.target.src = bgImage
                }} />
                <img width={"200px"} src={`https://suitamoistorage.blob.core.windows.net/avatars/${profileState.userId}-200.jpg`} onError={(e: any) => {
                    e.target.onError = null
                    e.target.src = bgImage
                }} />
                < img width={"50px"} src={`https://suitamoistorage.blob.core.windows.net/avatars/${profileState.userId}-050.jpg`} onError={(e: any) => {
                    e.target.onError = null
                    e.target.src = bgImage
                }} />
            </If>
            <Container>
                <Avatar
                    width={390}
                    height={295}
                    onCrop={onCrop}
                    onClose={onClose}
                    onBeforeFileLoad={onBeforeFileLoad}
                    src={""}
                />
                <img src={previewAvatar} alt="Preview" />
            </Container>
            <hr />

            <BadgeForgeContextProvider>
                <BadgeForge />
            </BadgeForgeContextProvider>
            <hr />

        </div>
    )
}