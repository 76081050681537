import * as React from "react"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { SignUpModel } from "../../../@types/SignUpModel"
import { TextField, TextFieldType } from "../../../elements/TextField"
import { AccountServiceContext } from "./reducer/AccountService"

export enum SignUpPageState {
    Default,
    RegistrationSucces,
}
export interface ISignUpPageProps {
    pageState: SignUpPageState
}

export const SignUpPage = (props: ISignUpPageProps) => {
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [hasInputErrorEmail, setHasInputErrorEmail] = useState(true)
    const [hasInputErrorPassword, setHasInputErrorPassword] = useState(true)
    const [hasInputErrorConfirmPassword, setHasInputErrorConfirmPassword] = useState(true)

    const [hasInputError, setHasInputError] = useState(true)
    const [registerSuccess, setRegisterSuccess] = useState(false)
    const [fromExternalLogin, setFromExternalLogin] = useState(false)

    const accountService = React.useContext(AccountServiceContext)
    React.useEffect(() => {
        setHasInputError(hasInputErrorEmail || hasInputErrorPassword || hasInputErrorConfirmPassword || (confirmPassword !== password))
    }, [hasInputErrorEmail, hasInputErrorPassword, hasInputErrorConfirmPassword, confirmPassword, password])

    React.useEffect(() => {
        if (props.pageState == SignUpPageState.RegistrationSucces) {
            setRegisterSuccess(true)
            setFromExternalLogin(true)
        }
    }, [props.pageState])

    const sendSignUpRequest = async () => {
        if (hasInputError) {
            return
        }
        const model: SignUpModel = {
            confirmPassword: confirmPassword,
            email: userName,
            password: password,
            returnUrl: ""
        }
        accountService.signUp(model, setRegisterSuccess)
    }
    const { t } = useTranslation()
    return (
        <div>
            <If condition={registerSuccess}>
                <div>
                    <h2>{t("SIGNUPPAGE_Title_Confirmation")}</h2>
                    <If condition={fromExternalLogin}>
                        <p>{t("SIGNUPPAGE_Title_ExternalExplanation")}</p>
                    </If>
                    <p>{t("SIGNUPPAGE_ActivationMailDescription")}</p>
                </div>
            </If>
            <If condition={!registerSuccess}>
                <div>
                    <h2>{t("SIGNUPPAGE_Title")}</h2>
                    <hr />
                    <div className="form-floating">
                        <TextField label={t("GENERIC_Email")} onChange={setUserName} type={TextFieldType.Email} callback={setHasInputErrorEmail} value={userName} submitCallback={sendSignUpRequest} />
                    </div>
                    <div className="form-floating">
                        <TextField label={t("GENERIC_Password")} onChange={setPassword} type={TextFieldType.Password} callback={setHasInputErrorPassword} value={password} submitCallback={sendSignUpRequest} />
                    </div>
                    <div className="form-floating">
                        <TextField label={t("PROFILE_ConfirmPassword")} onChange={setConfirmPassword} type={TextFieldType.Password} callback={setHasInputErrorConfirmPassword} value={confirmPassword} submitCallback={sendSignUpRequest} />
                    </div>
                    <If condition={confirmPassword !== password}>
                        <span className="text-danger">{t("errors:SPA_VALIDATION_PasswordsDoNotMatch")}</span>
                    </If>
                    <Button variant="primary" onClick={sendSignUpRequest} disabled={hasInputError}>{t("GENERIC_Register")}</Button>
                </div>
            </If>
        </div >
    )
}