import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import { FrontendNotification } from "../../../@types/FrontendNotification"

export interface SocketState {
    notificationsDebug: FrontendNotification[]
    messagesDebug: FrontendNotification[],
    isEstablishingConnection: boolean;
    isConnected: boolean;
    messagesSend: number;
    messagesReceived: number;
}

const initialState: SocketState = {
    notificationsDebug: [],
    messagesDebug:[],
    isEstablishingConnection: false,
    isConnected: false,
    messagesSend: 0,
    messagesReceived: 0
}

const socketReducer = createSlice({
    name: "socketReducer",
    initialState,
    reducers: {
        startConnecting: (state: SocketState) => {
            state.isEstablishingConnection = true
        },
        connectionEstablished: (state: SocketState) => {
            state.isConnected = true
            state.isEstablishingConnection = false
        },
        receiveAllMessages: (state: SocketState, action: PayloadAction<FrontendNotification[]>) => {
            state.notificationsDebug = action.payload
        },
        receiveMessage: (state: SocketState, action: PayloadAction<FrontendNotification>) => {
            if (state.notificationsDebug.length > 10) {
                state.notificationsDebug = []
            }
            state.notificationsDebug.push(action.payload)
            state.messagesReceived += 1
        },
        submitMessage: (state, action: PayloadAction<FrontendNotification>) => {
            if (state.messagesDebug.length > 10) {
                state.messagesDebug = []
            }
            state.messagesDebug.push(action.payload)
            state.messagesSend += 1
        },
        resetSocket: (state: SocketState) => {
            state.isEstablishingConnection = false
            state.isConnected = false
        },
    },
})

export const { resetSocket, startConnecting, connectionEstablished, receiveAllMessages, receiveMessage, submitMessage } = socketReducer.actions
export const socketState = (globalState: RootState) => globalState.socketState
export default socketReducer.reducer