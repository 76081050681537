import * as React from "react"
import { QuestionCategoryModel } from "../../../@types/QuestionCategoryModel"
import { QuestionModel } from "../../../@types/QuestionModel"
import { SetQuestionResultModel } from "../../../@types/SetQuestionResultModel"
import { SuitamoiApiStatus } from "../../../@types/SuitamoiApiStatus"
import { FeatureApiPaths } from "../../../app/AppConstants"
import { useAppDispatch } from "../../../app/hooks"
import { ApiRequest, RequestVerb } from "../../../core/network/DataRequest"
import { reset } from "./askMeSenpaiReducer"

// This service works socket only - no response Data of the API is used. instead the socketmiddleware handles the socket notifications
// this is a very intentional design decision - we want an API to with http data. but we also want to use sockets.
// For big requests like the readquestions and readcategories the backend will only send an empty list response when a session has a socket open
export interface IAskMeSenpaiService {
    uploadQuestion(questionToUpload: string, selectedCategoryId: number)
    modifyQuestion(model: QuestionModel)
    readQuestions()
    deleteQuestion(model: QuestionModel)
    setResult(isSuccess: boolean, id: number)
    importData()
    readCategories()
    modifyQuestionCategory(model: QuestionCategoryModel)
    addCategory(description: string, name: string)
    deleteCategory(model: QuestionCategoryModel)
}

export const AskMeSenpaiServiceContext = React.createContext<IAskMeSenpaiService | undefined>(undefined)

export const useAskMeSenpaiService = () => {
    return React.useContext<IAskMeSenpaiService | undefined>(undefined)
}

const AskMeSenpaiService = ({ children }: any) => {
    const dispatch = useAppDispatch() as any

    const askMeSenpaiService = {
        async uploadQuestion(questionToUpload: string, selectedCategoryId: number) {
            const model: QuestionModel = {
                text: questionToUpload,
                questionId: 0,
                createdAt: new Date().toDateString(),
                numberOfTimesShown: 0,
                numberOfTimesAnsweredCorrectly: 0,
                categoryId: selectedCategoryId,
            }

            return await ApiRequest<QuestionModel, QuestionModel>({
                payload: model,
                route: FeatureApiPaths.Questions.AddQuestion,
                verb: RequestVerb.Post,
            })
        },
        async modifyQuestion(model: QuestionModel) {
            return await ApiRequest<QuestionModel, QuestionModel>({
                payload: model,
                route: FeatureApiPaths.Questions.UpdateQuestion,
                verb: RequestVerb.Post,
            })
        },
        async deleteQuestion(model: QuestionModel) {
            return await ApiRequest<QuestionModel, null>({
                route: FeatureApiPaths.Questions.DeleteQuestion,
                verb: RequestVerb.Post,
                payload: model,
            })
        },
        async readQuestions() {
            return await ApiRequest<null, QuestionModel[]>({
                route: FeatureApiPaths.Questions.ReadQuestions,
                verb: RequestVerb.Get
            })
        },
        async setResult(isSuccess: boolean, id: number) {
            const model: SetQuestionResultModel =
            {
                id: id,
                isCorrect: isSuccess
            }
            return await ApiRequest({
                payload: model,
                route: FeatureApiPaths.Questions.SetResult,
                verb: RequestVerb.Post,
            })
        },
        async importData() {
            return await ApiRequest<null, QuestionModel[]>({
                route: FeatureApiPaths.Questions.Import,
                verb: RequestVerb.Get
            })
                .then(response => {
                    if (response.statusCode === SuitamoiApiStatus.Error) {
                        return
                    }
                    dispatch(reset())
                })
        },
        async readCategories() {
            return await ApiRequest<null, QuestionCategoryModel[]>({
                route: FeatureApiPaths.Questions.ReadCategories,
                verb: RequestVerb.Get
            })
        },
        async addCategory(description: string, name: string) {
            const model: QuestionCategoryModel = {
                categoryDescription: description,
                categoryName: name,
                questionCategoryId: 0
            }
            return await ApiRequest<QuestionCategoryModel, QuestionCategoryModel>({
                route: FeatureApiPaths.Questions.AddCategories,
                verb: RequestVerb.Post,
                payload: model,
            })
        },
        async deleteCategory(model: QuestionCategoryModel) {
            return await ApiRequest<QuestionCategoryModel, null>({
                route: FeatureApiPaths.Questions.DeleteCategories,
                verb: RequestVerb.Post,
                payload: model
            })
        },
        async modifyQuestionCategory(model: QuestionCategoryModel) {
            return await ApiRequest<QuestionCategoryModel, QuestionCategoryModel>({
                payload: model,
                route: FeatureApiPaths.Questions.UpdateCategories,
                verb: RequestVerb.Post,
            })
        }
    }
    return (
        <div>
            <AskMeSenpaiServiceContext.Provider value={askMeSenpaiService}>
                {children}
            </AskMeSenpaiServiceContext.Provider>
        </div>
    )
}

export default AskMeSenpaiService