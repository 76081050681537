import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../../app/store"
import { SupportedLanguages } from "../../../../@types/SupportedLanguages"
import Cookies from "universal-cookie"
import { LoginManagementModel } from "../../../../@types/LoginManagementModel"
import { ManageTfaModel } from "../../../../@types/ManageTfaModel"

export interface ProfileReducerState {
    userId: string
    email: string
    userName: string
    language: SupportedLanguages
    phoneNumber: string
    hasPassword: boolean
    loginManagementData?: LoginManagementModel
    tfaData?: ManageTfaModel
}

export const parseSupportedLanguageToCultureInfo = (language: SupportedLanguages) => {
    switch (language) {
        case SupportedLanguages.German: {
            return "de-DE"
        }
        case SupportedLanguages.English: {
            return "en-US"
        }
        default: {
            return "en-US"
        }
    }
}

export const parseCultureInfoToSupportedLanguage = (cultureInfo: string) => {
    switch (cultureInfo) {
        case "de-DE": {
            return SupportedLanguages.German
        }
        case "en-US": {
            return SupportedLanguages.English
        }
        default: {
            return SupportedLanguages.English
        }
    }
}

const getLanguageFromCookie = () => {
    const cookies = new Cookies()
    const uiCulture = cookies.get(".AspNetCore.Culture")?.split("=").pop()
    return parseCultureInfoToSupportedLanguage(uiCulture)
}

const initialState: ProfileReducerState = {
    userId: "",
    email: "",
    userName: "",
    language: getLanguageFromCookie(),
    phoneNumber: "",
    hasPassword: false,
    loginManagementData: null,
    tfaData: null
}

export const profileReducer = createSlice({
    name: "profileReducer",
    initialState,
    reducers: {
        setUserId: (state: ProfileReducerState, action: PayloadAction<string>) => {
            state.userId = action.payload
        },
        setEmail: (state: ProfileReducerState, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        setUserName: (state: ProfileReducerState, action: PayloadAction<string>) => {
            state.userName = action.payload
        },
        setLanguage: (state: ProfileReducerState, action: PayloadAction<SupportedLanguages>) => {
            state.language = action.payload
        },
        setPhoneNumber: (state: ProfileReducerState, action: PayloadAction<string>) => {
            state.phoneNumber = action.payload
        },
        setHasPassword: (state: ProfileReducerState, action: PayloadAction<boolean>) => {
            state.hasPassword = action.payload
        },
        setLoginManagementData: (state: ProfileReducerState, action: PayloadAction<LoginManagementModel>) => {
            state.loginManagementData = action.payload
        },
        setTfaData: (state: ProfileReducerState, action: PayloadAction<ManageTfaModel>) => {
            state.tfaData = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { setUserId, setEmail, setUserName, setLanguage, setPhoneNumber, setHasPassword, setLoginManagementData, setTfaData } = profileReducer.actions

// Other code such as selectors can use the imported `RootState` type
//do we need this?
export const profileState = (globalState: RootState) => globalState.profileState

export default profileReducer.reducer