import * as React from "react"
import { Form } from "react-bootstrap"
import { GearFill, Person } from "react-bootstrap-icons"
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses, MenuItemStyles } from "react-pro-sidebar"
import { SwipeEventData, useSwipeable } from "react-swipeable"
import { Partnerlist } from "../../partnerlist/Partnerlist"
import { Badge } from "./Badge"
import { SidebarFooter } from "./SidebarFooter"
import { SidebarHeader } from "./SidebarHeader"
import { Typography } from "./Typography"
import bgImage from "./../../../images/sidebarbg.jpg"
import { Themes, ThemeServiceContext } from "../../../core/Themes"
import { useTranslation } from "react-i18next"

export interface ISideBarState {
    toggled: boolean,
    collapsed: boolean,
    partnerlistOpen: boolean
}

export const SideBar = () => {

    const [collapsed, setCollapsed] = React.useState(false)
    const [toggled, setToggled] = React.useState(false)
    const sidebarWidth = 250


    const [savedSidebarState] = React.useState<ISideBarState>(JSON.parse(localStorage.getItem("sidebarstate")))
    const [partnerlistOpen, setPartnerlistOpen] = React.useState<boolean>(savedSidebarState?.partnerlistOpen ?? false)
    const themeService = React.useContext(ThemeServiceContext)
    const { t } = useTranslation()
    //Persist State in local storage
    React.useEffect(() => {
        if (savedSidebarState) {
            setToggled(savedSidebarState.toggled)
            setCollapsed(savedSidebarState.collapsed)
        }
    }, [savedSidebarState])

    React.useEffect(() => {
        const data: ISideBarState = {
            toggled: toggled,
            collapsed: collapsed,
            partnerlistOpen: partnerlistOpen
        }
        localStorage.setItem("sidebarstate", JSON.stringify(data))
    }, [collapsed, toggled, partnerlistOpen])

    //Touch Handler
    const { ref: handlersSideBar } = useSwipeable({
        onSwipedRight: (eventData: SwipeEventData) => {
            if (eventData.initial[0] < sidebarWidth) {
                if (!toggled) {
                    setToggled(true)
                }                
            }
        },
        onSwipedLeft: (eventData: SwipeEventData) => {
            if (eventData.initial[0] < sidebarWidth) {
                if (toggled) {
                    setToggled(false)
                }               
            }
        }
    })

    // attach swipeable to document
    React.useEffect(() => {
        handlersSideBar(document as any)
    })

    const theme = Themes[themeService.theme]
    const [hasImage, setHasImage] = React.useState<boolean>(true)

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHasImage(e.target.checked)
    }

    const rgbToHex = (r: string, g: string, b: string) => {
        return "#" +
            ("0" + parseInt(r, 10).toString(16)).slice(-2) +
            ("0" + parseInt(g, 10).toString(16)).slice(-2) +
            ("0" + parseInt(b, 10).toString(16)).slice(-2)
    }
    const hexToRgba = (hex: string, alpha: number) => {
        let r = parseInt(hex.slice(1, 3), 16)
        let g = parseInt(hex.slice(3, 5), 16)
        let b = parseInt(hex.slice(5, 7), 16)

        if (hex === "accentVar") {
            r = themeService.accentColor.r
            g = themeService.accentColor.g
            b = themeService.accentColor.b
        }

        return `rgba(${r}, ${g}, ${b}, ${alpha})`
    }

    const colorTheme = rgbToHex(themeService.accentColor?.r, themeService.accentColor?.g, themeService.accentColor?.b)
    const bgColor = getComputedStyle(document.documentElement).getPropertyValue(`--bs-${theme}`).trim()
    const textColor = getComputedStyle(document.documentElement).getPropertyValue("--bs-body-color").trim()

    const themes = {
        light: {
            sidebar: {
                backgroundColor: bgColor,
                color: textColor,
            },
            menu: {
                menuContent: bgColor,
                icon: colorTheme,
                hover: {
                    backgroundColor: colorTheme,
                    color: textColor,
                },
                disabled: {
                    color: textColor,
                },
            },
        },
        dark: {
            sidebar: {
                backgroundColor: bgColor,
                color: textColor,
            },
            menu: {
                menuContent: bgColor,
                icon: colorTheme,
                hover: {
                    backgroundColor: colorTheme,
                    color: textColor,
                },
                disabled: {
                    color: textColor,
                },
            },
        },
    }

    const menuItemStyles: MenuItemStyles = {
        root: {
            fontSize: "13px",
            fontWeight: 400,
        },
        icon: {
            color: themes[theme].menu.icon,
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color,
            },
        },
        SubMenuExpandIcon: {
            color: "#b6b7b9",
        },
        subMenuContent: ({ level }) => ({
            backgroundColor:
                level === 0
                    ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
                    : "transparent",
        }),
        button: {
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color,
            },
            "&:hover": {
                backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
                color: themes[theme].menu.hover.color,
            },
        },
        label: ({ open }) => ({
            fontWeight: open ? 600 : undefined,
        }),
    }

    return (

        <Sidebar
            collapsed={collapsed}
            toggled={toggled}
            className="sticky-sidebar"
            image={bgImage}
            rtl={false}
            breakPoint="lg"
            backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
            rootStyles={{
                color: themes[theme].sidebar.color,
            }}
        >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <SidebarHeader style={{ marginBottom: "24px", marginTop: "16px" }} />
                <div style={{ flex: 1, marginBottom: "32px" }}>
                    <div style={{ padding: "0 24px", marginBottom: "8px" }}>
                        <Typography
                            variant="body2"
                            fontWeight={600}
                            style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px" }}
                        >
                            {t("SIDEBAR_General")}
                        </Typography>
                    </div>
                    <Menu menuItemStyles={menuItemStyles}>
                        <SubMenu open={partnerlistOpen} onOpenChange={(e) => {
                            setPartnerlistOpen(e)
                        }} label={t("SIDEBAR_Partnerlist")} icon={<Person />}>
                            <Partnerlist />
                        </SubMenu>
                        <SubMenu label="Debug" icon={<GearFill />}>
                            <Form.Switch
                                defaultChecked={hasImage}
                                onClick={(e: any) => handleImageChange(e)}
                                type="switch"
                                id="bg-switch"
                                label="Show Image"
                            />
                        </SubMenu>

                    </Menu>

                    <div style={{ padding: "0 24px", marginBottom: "8px", marginTop: "32px" }}>
                        <Typography
                            variant="body2"
                            fontWeight={600}
                            style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px" }}
                        >
                            Examples
                        </Typography>
                    </div>

                    <Menu menuItemStyles={menuItemStyles}>
                        <MenuItem icon={<GearFill />} suffix={<Badge variant="success">New</Badge>}>
                            ...Calendar
                        </MenuItem>
                        <MenuItem icon={<GearFill />}>Documentation</MenuItem>
                        <MenuItem disabled icon={<GearFill />}>
                            ...Examples
                        </MenuItem>
                        <SubMenu
                            label="Charts"
                            icon={<GearFill />}
                            suffix={
                                <Badge variant="danger" shape="circle">
                                    6
                                </Badge>
                            }
                        >
                            <MenuItem> Pie charts</MenuItem>
                            <MenuItem> Line charts</MenuItem>
                            <MenuItem> Bar charts</MenuItem>
                        </SubMenu>
                        <SubMenu label="...Components" icon={<GearFill />}>
                            <MenuItem>...Grid</MenuItem>
                            <MenuItem> ...Layout</MenuItem>
                            <SubMenu label="Forms">
                                <MenuItem> ...Input</MenuItem>
                                <MenuItem> ...Select</MenuItem>
                                <SubMenu label="More">
                                    <MenuItem> ...CheckBox</MenuItem>
                                    <MenuItem> ...Radio</MenuItem>
                                </SubMenu>
                            </SubMenu>
                        </SubMenu>
                        <SubMenu label="...E-commerce" icon={<GearFill />}>
                            <MenuItem> ...Product</MenuItem>
                            <MenuItem> ...Orders</MenuItem>
                            <MenuItem> ...Credit card</MenuItem>
                        </SubMenu>
                    </Menu>
                </div>

                <SidebarFooter collapsed={collapsed} setCollapsed={setCollapsed} />

            </div>

        </Sidebar >

    )
}