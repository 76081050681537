import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import { QuestionModel } from "../../../@types/QuestionModel"
import { QuestionsStatisticsModel } from "../../../@types/QuestionsStatisticsModel"
import { QuestionCategoryModel } from "../../../@types/QuestionCategoryModel"

export type AskMeSenpaiState = {
    questions: Array<QuestionModel>
    categories: Array<QuestionCategoryModel>
    isInitialized: boolean
    statistics: QuestionsStatisticsModel,
    skipDeleteNotifications: boolean,
}

const initialState: AskMeSenpaiState = {
    questions: [],
    categories: [],
    isInitialized: false,
    skipDeleteNotifications: false,
    statistics: {
        questionsAnswered: 0,
        totalQuestions: 0,
    },
}

export const askMeSenpaiReducer = createSlice({
    name: "askMeSenpaiReducer",
    initialState,
    reducers: {
        addQuestion: (state: AskMeSenpaiState, action: PayloadAction<QuestionModel>) => {
            if (action.payload === undefined) {
                return
            }
            state.questions.push(action.payload)
            state.statistics.totalQuestions += 1
        },
        removeQuestion: (state: AskMeSenpaiState, action: PayloadAction<number>) => {
            state.questions.forEach((item: QuestionModel, index) => {
                if (item.questionId === action.payload) state.questions.splice(index, 1)
            })
            state.statistics.totalQuestions -= 1
            state.statistics.questionsAnswered = state.questions.filter(x => x.numberOfTimesAnsweredCorrectly > 0).length
        },
        editQuestion: (state: AskMeSenpaiState, action: PayloadAction<QuestionModel>) => {
            if (action.payload === undefined) {
                return
            }
            state.questions.forEach((item: QuestionModel, index) => {
                if (item.questionId === action.payload.questionId) {
                    state.questions[index] = action.payload
                    return
                }
            })
            state.statistics.questionsAnswered = state.questions.filter(x => x.numberOfTimesAnsweredCorrectly > 0).length
        },
        loadQuestions: (state: AskMeSenpaiState, action: PayloadAction<QuestionModel[]>) => {
            state.questions = action.payload
            state.statistics.totalQuestions = state.questions.length
            state.statistics.questionsAnswered = state.questions.filter(x => x.numberOfTimesAnsweredCorrectly > 0).length
        },
        reset: (state: AskMeSenpaiState) => {
            state.isInitialized = false
        },
        setInitialized: (state: AskMeSenpaiState) => {
            state.isInitialized = true
        },
        loadCategories: (state: AskMeSenpaiState, action: PayloadAction<QuestionCategoryModel[]>) => {
            if (action.payload === undefined || action.payload.length === 0) {
                return
            }
            state.categories = action.payload
        },
        addCategory: (state: AskMeSenpaiState, action: PayloadAction<QuestionCategoryModel>) => {
            if (action.payload === undefined) {
                return
            }
            state.categories.push(action.payload)
        },
        removeCategory: (state: AskMeSenpaiState, action: PayloadAction<number>) => {
            state.categories.forEach((item: QuestionCategoryModel, index) => {
                if (item.questionCategoryId === action.payload) state.categories.splice(index, 1)
            })
        },
        editCategory: (state: AskMeSenpaiState, action: PayloadAction<QuestionCategoryModel>) => {
            if (action.payload === undefined) {
                return
            }
            state.categories.forEach((item: QuestionCategoryModel, index) => {
                if (item.questionCategoryId === action.payload.questionCategoryId) {
                    state.categories[index] = action.payload
                    return
                }
            })
        },
        setSkipDeleteConfirmation: (state: AskMeSenpaiState, action: PayloadAction<boolean>) => {
            state.skipDeleteNotifications = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    removeCategory,
    addQuestion,
    removeQuestion,
    editQuestion,
    loadQuestions,
    loadCategories,
    reset,
    setInitialized,
    addCategory,
    editCategory,
    setSkipDeleteConfirmation
} = askMeSenpaiReducer.actions

// Other code such as selectors can use the imported `RootState` type
//do we need this?
export const askMeSenpaiState = (globalState: RootState) => globalState.askMeSenpaiState

export default askMeSenpaiReducer.reducer