import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"
import { ToDoItemModel } from "../../../@types/ToDoItemModel"
import { BasicPriority } from "../../../@types/BasicPriority"
import { BasicStatus } from "../../../@types/BasicStatus"

export type State = {
    toDoItems: Array<ToDoItemModel>
    loading: boolean
    toDoItemToUpload: string
    initialized: boolean
}

const initialState: State = {
    toDoItems: [],
    loading: false,
    toDoItemToUpload: "",
    initialized: false
}

export const toDoItemsReducer = createSlice({
    name: "todoitemsReducer",
    initialState,
    reducers: {
        add: (state: State, action: PayloadAction<ToDoItemModel>) => {
            if (action.payload === undefined) {
                return
            }
            state.toDoItems.push(action.payload)
        },
        remove: (state: State, action: PayloadAction<ToDoItemModel>) => {
            state.toDoItems.forEach((item: ToDoItemModel, index) => {
                if (item.toDoItemId === action.payload.toDoItemId) state.toDoItems.splice(index, 1)
            })
        },
        edit: (state: State, action: PayloadAction<ToDoItemModel>) => {
            const index = state.toDoItems.findIndex(item => item.toDoItemId === action.payload.toDoItemId)
            state.toDoItems.splice(index, 1, action.payload)
        },
        load: (state: State, action: PayloadAction<ToDoItemModel[]>) => {
            if (!state.initialized) {
                state.toDoItems = action.payload
                state.initialized = true
            }
        },
        save: (state: State) => {
            const test = state.initialized
        }
    },
})

// Action creators are generated for each case reducer function
export const { add, remove, edit, load, save } = toDoItemsReducer.actions

// Other code such as selectors can use the imported `RootState` type
//do we need this?
export const toDoListState = (globalState: RootState) => globalState.toDoListState

export default toDoItemsReducer.reducer