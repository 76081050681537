import * as React from "react"
import { useState } from "react"
import { AuthorizationFlowPaths, IdentitySpaPaths, LoginPageActions, QueryParameterNames } from "../../../app/AppConstants"
import CheckBox from "../../../elements/CheckBox"
import { TextField, TextFieldType } from "../../../elements/TextField"
import { ExternalLoginFragment } from "./ExternalLoginFragment"
import { useNavigate, useLocation, useSearchParams } from "react-router-dom"
import { useAppDispatch } from "../../../app/hooks"
import { ConfirmEmailModel } from "../../../@types/ConfirmEmailModel"
import { SuitamoiApiStatus } from "../../../@types/SuitamoiApiStatus"
import { PopupState, PopupType, show } from "../../popupmessage/reducer/popupReducer"
import { Button, Col, Container, Nav, Row } from "react-bootstrap"
import { AccountServiceContext } from "./reducer/AccountService"
import { Link } from "react-router-dom"
import { SuitamoiApiSubStatusCode } from "../../../@types/SuitamoiApiSubStatusCode"
import { TfaLoginModel } from "../../../@types/TfaLoginModel"
import { useTranslation } from "react-i18next"
import { SignUpPage, SignUpPageState } from "./SignUpPage"
import { ForgotPassword, ForgotPasswordPageState } from "./ForgotPassword"
import { ResendActivationMail } from "./ResendActivationMail"

interface ILoginPageProps {
    action?: LoginPageActions
}

export const LoginPage = (props: ILoginPageProps) => {
    const navigate = useNavigate()

    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")

    const [rememberMe, setRememberMe] = useState(false)

    const [hasInputErrorEmail, setHasInputErrorEmail] = useState(true)
    const [hasInputErrorPassword, setHasInputErrorPassword] = useState(true)

    const dispatch = useAppDispatch() as any

    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const code = urlParams.get(QueryParameterNames.Code)
    const userId = urlParams.get(QueryParameterNames.UserId)
    const message = urlParams.get(QueryParameterNames.Message)
    const popupType = urlParams.get(QueryParameterNames.MessageType)

    const returnUrl = urlParams.get(QueryParameterNames.ReturnUrl) ?? urlParams.get(QueryParameterNames.ReturnUrl.toLowerCase())

    const [searchParams, setSearchParams] = useSearchParams()

    const [showTfaPrompt, setShowTfaPrompt] = useState(false)
    const accountService = React.useContext(AccountServiceContext)
    const [hasTfaCodeInputError, setHasTfaCodeInputError] = useState(false)
    const [tfaCode, setTfaCode] = useState("")
    const hasInputError = hasInputErrorEmail || hasInputErrorPassword
    const [hasTfaRecoveryCodeInputError, setHasTfaRecoveryCodeInputError] = useState(false)
    const [tfaRecoveryCode, setTfaRecoveryCode] = useState("")
    const { t } = useTranslation()

    const [showRecoveryCodeLogin, setShowRecoveryCodeLogin] = React.useState(false)
    const toggleShowRecoveryLogin = () => {
        setShowRecoveryCodeLogin(!showRecoveryCodeLogin)
    }

    const handleKeyDown = () => {
        if (!showTfaPrompt && !hasInputError) {
            login()
        }
        if (showTfaPrompt && !hasTfaCodeInputError) {
            loginTfa()
        }
        if (showRecoveryCodeLogin && !hasTfaRecoveryCodeInputError) {
            loginTfaRecoveryCode()
        }
    }

    //shows errors comming from redirects via query string - instead of fetch
    React.useEffect(() => {
        if (message) {
            const popup: PopupState = {
                showPopup: true,
                content: message,
                popupType: isNaN(Number(popupType)) ? PopupType.Normal : Number(popupType),
                headline: !isNaN(Number(popupType)) ? t("GENERIC_Success") : t("GENERIC_Error")
            }
            dispatch(show(popup))
            setSearchParams("")
        }
    }, [message, searchParams])

    // Account Activation handler from query string route /login?code=&userId=
    React.useEffect(() => {
        if (userId && code && props.action !== LoginPageActions.ResetPassword) {
            const model: ConfirmEmailModel = {
                code: code,
                userId: userId
            }
            accountService.confirmEmail(model)
        }
    }, [userId, code])

    const login = () => {
        accountService.login(userName, password, rememberMe)
            .then(response => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    if (response.subStatusCode === SuitamoiApiSubStatusCode.AccountState_RequiresTfa) {
                        setShowTfaPrompt(true)
                    } else if (response.subStatusCode === SuitamoiApiSubStatusCode.AccountState_Locked) {
                        const popup: PopupState = {
                            showPopup: true,
                            content: t("errors:BACKEND_LOGIN_ERROR_AccountLocked"),
                            popupType: PopupType.Error,
                            headline: t("GENERIC_Error")
                        }
                        dispatch(show(popup))
                    } else {
                        // we also act as LoginPage for external Open ID Requests redirect after successfull login to continue the OPEN Id auth
                        // remove this will break external authentication
                        if (returnUrl) {
                            window.location.replace(returnUrl)
                        } else {                            
                            navigate(AuthorizationFlowPaths.Login + location.search)                            
                        }
                    }
                }
            })
    }

    const loginTfa = () => {
        if (hasTfaCodeInputError) {
            return
        }
        const model: TfaLoginModel = {
            twoFactorCode: tfaCode,
            rememberMachine: rememberMe,
            rememberMe: rememberMe
        }

        accountService.loginTfa(model)
            .then(response => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    navigate(AuthorizationFlowPaths.Login + location.search)
                }
            })
    }

    const loginTfaRecoveryCode = () => {
        if (hasTfaRecoveryCodeInputError) {
            return
        }
        accountService.loginTfaRecoveryCode(tfaRecoveryCode)
            .then(response => {
                if (response.statusCode === SuitamoiApiStatus.Success) {
                    navigate(AuthorizationFlowPaths.Login + location.search)
                }
            })
    }

    return (
        <Container>            
            <div>
                <h1>{t("LOGINPAGE_MAIN_Title")}</h1>
                <h2>{t("LOGINPAGE_MAIN_Subtitle")}</h2>
                <hr />
                <Row>
                    <Col md="auto">
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link active={props.action === LoginPageActions.Login} to={IdentitySpaPaths.LoginPage} as={Link}>{t("LOGINPAGE_NAV_Login")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link active={props.action === LoginPageActions.SignUp} to={IdentitySpaPaths.SignUp} as={Link}>{t("LOGINPAGE_NAV_NewAccount")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link active={props.action === LoginPageActions.ForgotPassword} to={IdentitySpaPaths.ForgotPassword} as={Link}>{t("LOGINPAGE_NAV_ForgotPassword")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link active={props.action === LoginPageActions.ResendActivationMail} to={IdentitySpaPaths.ResendActivationMail} as={Link}>{t("LOGINPAGE_NAV_ActivationMail")}</Nav.Link>
                            </Nav.Item>

                        </Nav>
                    </Col>
                    <Col>
                        <If condition={props.action === LoginPageActions.Login}>
                            <h2>{t("LOGINPAGE_Title")}</h2>
                            <hr />
                            <If condition={showTfaPrompt}>
                                <If condition={!showRecoveryCodeLogin}>
                                    <p>{t("LOGINPAGE_TfaPrompt")}</p>
                                    <div className="form-floating">
                                        <TextField label={t("PROFILE_TFA_Code")} onChange={setTfaCode} callback={setHasTfaCodeInputError} type={TextFieldType.Code} value={tfaCode} submitCallback={handleKeyDown} />
                                    </div>
                                    <Button variant="primary" onClick={loginTfa} disabled={hasTfaCodeInputError}>{t("Generic_Ok")}</Button>
                                    <Nav.Link onClick={toggleShowRecoveryLogin}>{t("LOGINPAGE_LostAuthenticator")}</Nav.Link>
                                </If>
                                <If condition={showRecoveryCodeLogin}>
                                    <p>{t("LOGINPAGE_UseRecoveryCode")}</p>
                                    <div className="form-floating">
                                        <TextField label={t("PROFILE_TFA_RecoveryCodes")} onChange={setTfaRecoveryCode} callback={setHasTfaRecoveryCodeInputError} type={TextFieldType.RecoveryCode} value={tfaRecoveryCode} submitCallback={handleKeyDown} />
                                    </div>
                                    <Button variant="primary" onClick={loginTfaRecoveryCode} disabled={hasTfaRecoveryCodeInputError} >{t("Generic_Ok")}</Button>
                                    <Nav.Link onClick={toggleShowRecoveryLogin}>{t("LOGINPAGE_BackToTfa")}</Nav.Link>
                                </If>
                            </If>
                            <If condition={!showTfaPrompt}>
                                <div className="form-floating">
                                    <TextField label={t("GENERIC_Username")} onChange={setUserName} callback={setHasInputErrorEmail} type={TextFieldType.Email} value={userName} submitCallback={handleKeyDown} doNotClearOnSubmit={true} autoFocus={true} />
                                </div>
                                <div className="form-floating">
                                    <TextField label={t("GENERIC_Password")} onChange={setPassword} callback={setHasInputErrorPassword} type={TextFieldType.Password} value={password} submitCallback={handleKeyDown} />
                                </div>

                                <CheckBox
                                    isChecked={rememberMe}
                                    label={t("LOGINPAGE_RememberMe")}
                                    handleChange={() => {
                                        setRememberMe(!rememberMe)
                                    }} />
                                <Button variant="primary" onClick={login} disabled={hasInputError}>{t("GENERIC_Ok")}</Button>
                            </If>
                        </If>
                        <If condition={props.action === LoginPageActions.SignUp}>
                            <SignUpPage pageState={SignUpPageState.Default} />
                        </If>
                        <If condition={props.action === LoginPageActions.ForgotPassword}>
                            <ForgotPassword pageState={ForgotPasswordPageState.Request} />
                        </If>
                        <If condition={props.action === LoginPageActions.ResendActivationMail}>
                            <ResendActivationMail />
                        </If>
                        <If condition={props.action === LoginPageActions.ResetPassword}>
                            <ForgotPassword pageState={ForgotPasswordPageState.Reset} />
                        </If>
                        <If condition={props.action === LoginPageActions.RegistrationSucces}>
                            <SignUpPage pageState={SignUpPageState.RegistrationSucces} />
                        </If>
                    </Col>
                    <Col>
                        <ExternalLoginFragment />
                    </Col>
                </Row>
            </div>
        </Container>

    )
}