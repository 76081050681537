import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store"

export interface AppReducerState {
    isLoggedIn?: boolean
    isInitialized?: boolean
    externalLoginProviders?: any[]
    accessToken?: string
    isLoading: boolean
    isNotificationCenterShown: boolean
    logout: boolean
    shouldRedirect:boolean
}

const initialState: AppReducerState = {
    isLoggedIn: false,
    isInitialized: false,
    externalLoginProviders: [],
    accessToken: null,
    isLoading: false,
    isNotificationCenterShown: false,
    logout: false,
    shouldRedirect:false
}

export const appStateReducer = createSlice({
    name: "appStateReducer",
    initialState,
    reducers: {
        setInitialized: (state: AppReducerState, action: PayloadAction<any>) => {
            state.isInitialized = true
            state.externalLoginProviders = action.payload
        },
        setIsLoggedIn: (state: AppReducerState, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload
        },
        setToken: (state: AppReducerState, action: PayloadAction<string>) => {
            state.accessToken = action.payload
        },       
        setIsLoading: (state: AppReducerState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        toggleNotificationCenter: (state: AppReducerState) => {
            state.isNotificationCenterShown = !state.isNotificationCenterShown
        },
        logout: (state: AppReducerState) => {
            state.logout = true
        }
    },
})

// Action creators are generated for each case reducer function
export const { logout, setInitialized, setIsLoggedIn, setToken, setIsLoading, toggleNotificationCenter } = appStateReducer.actions

// Other code such as selectors can use the imported `RootState` type
//do we need this?
export const appState = (globalState: RootState) => globalState.appState

export default appStateReducer.reducer