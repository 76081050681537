import * as React from "react"
import { useState } from "react"
import { useAppSelector, useAppDispatch } from "../../app/hooks"
import { submitMessage } from "../../core/network/reducer/socketReducer"
import { TextField, TextFieldType } from "../../elements/TextField"
import { FrontendNotification } from "../../@types/FrontendNotification"
import { useTranslation } from "react-i18next"
import { Button, Col, Container, Row, Table } from "react-bootstrap"
import { ArrowDown, ArrowUp } from "react-bootstrap-icons"
import { NotificationTarget } from "../../@types/NotificationTarget"

export const SocketTest = () => {
    const dispatch = useAppDispatch() as any
    const state = useAppSelector((state) => state.socketState)
    const [textField, setTextField] = useState("")

    const isEstablishingConnection = state.isEstablishingConnection.toString()
    const isConnected = state.isConnected.toString()
    const messagesSendCount = state.messagesSend.toString()
    const messagesSend = state.messagesDebug
    const messagesReceived = state.messagesReceived.toString()
    const receivedMessages = state.notificationsDebug
    const { t } = useTranslation()

    const onSend = () => {
        if (state.isConnected) {         
            const message: FrontendNotification = {
                content: JSON.stringify(textField),
                id: NotificationTarget.Message,
                type: "System.String"
            }
            dispatch(submitMessage(message))
        }
    }
    return (
        <Container >
            <div>{t("SOCKET_Establishing")}: {isEstablishingConnection}</div>
            <div>{t("SOCKET_Connected")}: {isConnected}</div>
            <div>{t("SOCKET_SendNo")}: {messagesSendCount}</div>
            <div>{t("SOCKET_ReceivedNo")}: {messagesReceived}</div>

            <h1 id="tabelLabel" >{t("SOCKET_Upload")}</h1>
            <TextField
                type={TextFieldType.Required}
                label={t("GENERIC_Message")}
                onChange={setTextField}
                value={textField}
                submitCallback={onSend} />
            <p> <Button variant="primary" disabled={!state.isConnected || !textField} onClick={onSend} >{t("GENERIC_Ok")}</Button></p>


            <Container>
                <Row>
                    <Col>
                        <Table className="" striped bordered hover variant="secondary">
                            <thead>
                                <tr>
                                    <th><ArrowDown></ArrowDown></th>
                                    <th>Id</th>
                                    <th>{t("SOCKET_History")}</th>
                                    <th>{t("GENERIC_Type")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {receivedMessages.map((item: FrontendNotification, index: number) => (
                                    <tr key={index}>
                                        <td></td>
                                        <td>{item?.id}</td>
                                        <td>{item?.content}</td>
                                        <td>{item?.type}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table >
                    </Col>
                    <Col>
                        <Table className="" striped bordered hover variant="secondary">
                            <thead>
                                <tr>
                                    <th><ArrowUp></ArrowUp></th>
                                    <th>Id</th>
                                    <th>{t("SOCKET_History")}</th>
                                    <th>{t("GENERIC_Type")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {messagesSend.map((item: FrontendNotification, index: number) => (
                                    <tr key={index}>
                                        <td></td>
                                        <td>{item?.id}</td>
                                        <td>{item?.content}</td>
                                        <td>{item?.type}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table >
                    </Col>
                </Row>
            </Container>

        </Container>
    )
}