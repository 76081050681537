import React from "react"

import Select, { StylesConfig } from "react-select"

const colourStyles: StylesConfig<any, true> = {
    // main element
    control: (styles, { isDisabled, isFocused }) => ({
        ...styles,
        backgroundColor: isDisabled ? "var(--color-disabled-bg)" : "var(--bs-gray-background-0)",
        border: isFocused ? "1px solid RGBA(var(--color-theme-accent-rgb),1)" : "1px solid var(--bs-border-color)",
        boxShadow: isFocused ? "0 0 0 0.25rem rgba(var(--color-theme-accent-rgb), 0.25)" : undefined,
        ":hover": {
            border: "1px solid RGBA(var(--color-theme-accent-rgb),1)"
        },
    }),
    // the dropdown elements (options)
    option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            padding: "5px",
            cursor: isDisabled ? "not-allowed" : "default",
            backgroundColor: isDisabled
                ? "var(--bs-gray-background-0)"
                : isSelected
                    ? "var(--color-theme-accentactive-rgba)"
                    : isFocused
                        ? "RGBA(var(--color-theme-accent-rgb),1)"
                        : "var(--bs-gray-background-0)",
            color: "var(--bs-body-color)",
            ":active": {
                backgroundColor: "RGBA(var(--color-theme-accent-rgb),1)",
                color: "var(--bs-body-color)",
            },
        }
    },
    // the actual selected element (text only it seems) as child of the control
    singleValue: (styles, { isDisabled }) => ({
        ...styles,

        color: isDisabled ? "var(--color-disabled-text)" : "var(--bs-body-color)",
    }),
    //Multiselected values
    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: "RGBA(var(--color-theme-accent-rgb),0.50)",
        }
    },
    // and their "content" the text
    multiValueLabel: (styles) => ({
        ...styles,
        color: "var(--bs-body-color)"
    }),
    //style of the close button
    multiValueRemove: (styles) => ({
        ...styles,
        color: "var(--bs-body-color)",
        ":hover": {
            backgroundColor: "RGBA(var(--color-theme-accent-rgb),1)",
            color: "var(--bs-body-color)",
        },
    }),
    //the text box text
    input: (styles) => ({
        ...styles,
        color: "var(--bs-body-color)"
    }),
    //the container for the options
    menu: (styles) => ({
        ...styles,
        backgroundColor: "var(--bs-gray-background-0)",
    }),
    valueContainer: (styles) => ({
        ...styles,
        backgroundColor: "var(--bs-gray-background-0)",
    })
}

export interface ISelectProps {
    onChange: any,
    options: any,
    selectedItem?: any
}

export const SingleSelect = (props: ISelectProps) => (
    <Select
        className="w-100"
        classNamePrefix="form-control"
        defaultValue={props.selectedItem}
        options={props.options}
        styles={colourStyles}
        onChange={props.onChange}

    />
)

export const MultiSelect = (props: ISelectProps) => (
    <Select
        className="w-100"
        classNamePrefix="form-control"
        defaultValue={props.selectedItem}
        options={props.options}
        styles={colourStyles}
        isMulti
        onChange={props.onChange}
    />
)