import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../app/store"

export type PopupState = {
    content: string,
    popupType: PopupType
    headline: string,
    showPopup: boolean,
}

export enum PopupType {
    Normal = 0,
    Error = 1,
    Warning = 2,
}

const initialState: PopupState = {
    showPopup: false,
    content: "",
    headline: "",
    popupType: PopupType.Normal
}

export const popupReducer = createSlice({
    name: "popupReducer",
    initialState,
    reducers: {
        showError: (state: PopupState, action: PayloadAction<string>) => {
            state.content = action.payload
            state.popupType = PopupType.Error
            state.headline = ""
            state.showPopup = true
        },
        show: (state: PopupState, action: PayloadAction<PopupState>) => {            
            state.content = action.payload.content
            state.popupType = action.payload.popupType
            state.headline = action.payload.headline
            state.showPopup = action.payload.showPopup
        },
        close: (state: PopupState) => {
            state.showPopup = false
        },
    },
})

// Action creators are generated for each case reducer function
export const { showError, show, close } = popupReducer.actions

// Other code such as selectors can use the imported `RootState` type
//do we need this?
export const popupState = (globalState: RootState) => globalState.popupState

export default popupReducer.reducer