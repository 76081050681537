import * as React from "react"
import { useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { formatString } from "../core/GenericHelper"

export enum TextFieldType {
    "Default" = 0,
    "Email" = 1,
    "Password" = 2,
    "Code" = 3,
    "RecoveryCode" = 4,
    "Required" = 5,
}

interface ITextFieldProps {
    onChange?: any
    label: string
    type?: TextFieldType
    callback?: any,
    submitCallback?: any,
    value: string
    disabled?: boolean,
    doNotClearOnSubmit?: boolean,
    autoFocus?: boolean,
    hideValidation?: boolean,
}

export const TextField = (props: ITextFieldProps) => {
    const { t } = useTranslation()

    const [errors, setErrors] = useState<string[]>([])

    const requiredValidator = (value?: string) =>
        !value ? t("errors:SPA_VALIDATION_REQUIRED") : ""

    const minLengthValidator = (value?: string) =>
        value && value.length < 8 ? t("errors:SPA_VALIDATION_LENGTH") : ""

    const difficultyValidator = (value?: string) => {
        return value && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).*$/.test(value)
            ? t("errors:SPA_VALIDATION_STRENGTH")
            : ""
    }
    const emailValidator = (value?: string) => {
        return !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value)
            ? t("errors:SPA_VALIDATION_EMAIL")
            : ""
    }
    const codeValidator = (length: number, value?: string) => {
        const message = formatString(t("errors:SPA_VALIDATION_EXACTLENGTH"), `${length}`)

        return value && value.length !== length ? message : ""
    }

    const isNumber = (value?: string) => isNaN(Number(value)) ? t("errors:SPA_VALIDATION_NaN") : ""

    useEffect(() => {
        const currentErrors: string[] = []
        if (props.type === TextFieldType.Password) {
            const passwordError = difficultyValidator(props.value)
            if (passwordError != "") {
                currentErrors.push(passwordError)
            }
            const requiredError = requiredValidator(props.value)
            if (requiredError !== "") {
                currentErrors.push(requiredError)
            }
        }
        if (props.type === TextFieldType.Email) {
            const emailError = emailValidator(props.value)
            if (emailError !== "") {
                currentErrors.push(emailError)
            }
        }
        if (props.type === TextFieldType.Code) {
            const codeError = codeValidator(6, props.value)
            if (codeError !== "") {
                currentErrors.push(codeError)
            }
            const notANumberError = isNumber(props.value)
            if (notANumberError != "") {
                currentErrors.push(notANumberError)
            }
        }
        if (props.type === TextFieldType.RecoveryCode) {
            const recCodeError = codeValidator(8, props.value)
            if (recCodeError !== "") {
                currentErrors.push(recCodeError)
            }
        }
        if (props.type === TextFieldType.Default) {
            const minLengthError = minLengthValidator(props.value)
            if (minLengthError !== "") {
                currentErrors.push(minLengthError)
            }
        }
        if (props.type === TextFieldType.Required) {
            const requiredError = requiredValidator(props.value)
            if (requiredError !== "") {
                currentErrors.push(requiredError)
            }
        }

        setErrors(currentErrors)
        props.callback && props.callback(currentErrors.length > 0)
    }, [props.value])

    const error = errors.length === 1 ? errors[0] : errors.join("; ")
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault()
            if (errors.length === 0) {
                props.submitCallback && props.submitCallback()
                if (!props.doNotClearOnSubmit) {
                    props?.onChange("")
                }
            }
        }
    }
    return (
        <div >
            <Form onSubmit={handleKeyDown} onKeyDown={handleKeyDown}>
                <Form.Group>
                    <Form.Control
                        isValid={errors.length === 0}
                        value={props.value}
                        className="my-2 py-2"
                        onChange={(e: any) => {
                            props?.onChange(e.target.value)
                        }}
                        disabled={props.disabled}
                        type={props.type === TextFieldType.Password ? "password" : props.type === TextFieldType.Email ? "email" : "text"}
                        placeholder={props.label}
                        autoFocus={props?.autoFocus}
                    />
                </Form.Group>
                <If condition={props.hideValidation !== true}>
                    <If condition={!props.disabled}>
                        <span className="text-danger">{error}</span>
                    </If>
                    <If condition={props.disabled}>
                        <p></p>
                    </If>
                </If>
            </Form>
        </div>
    )
}