import * as React from "react"
import { FeatureSpaPaths, IdentitySpaPaths } from "../../app/AppConstants"
import { Form, Nav, Navbar } from "react-bootstrap"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { useTranslation } from "react-i18next"
import { Themes, ThemeServiceContext } from "../../core/Themes"
import { ColorThemePicker } from "../../core/ColorThemePicker"

export const FooterMenu = () => {
    const isLoggedIn = useAppSelector((state) => state.appState.isLoggedIn)
    const { t } = useTranslation()
    const themeService = React.useContext(ThemeServiceContext)

    const dispatch = useAppDispatch() as any

    return (
        <Navbar className="navbarBottomCustom px-3" bg="dark" fixed="bottom" variant="dark" >
            <Nav className="me-auto">
                <Nav.Link href="mailto:admin@suitamoi.de">Contact</Nav.Link>
            </Nav>
            <Nav>
                <Nav.Item className="nav-link">
                    <Form.Switch
                        defaultChecked={themeService.theme === Themes.dark}
                        onClick={themeService.toggleTheme}
                        type="switch"
                        id="theme-switch"
                        label="DarkMode"
                    />
                </Nav.Item>
                <ColorThemePicker />
                <Nav.Link href={FeatureSpaPaths.ApiDocs} >{t("NAV_ApiDocs")}</Nav.Link>
            </Nav>
        </Navbar>
    )
}