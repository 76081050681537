import React from "react"
import { BadgeForgeContext } from "../contexts/BadgeForgeContext"
import styled from "styled-components"
import { Theme } from "../../profilepictureeditor/theme/theme"
type Props = {
    children?: React.ReactElement
}

export const Canvas = ({ children }: Props) => {
    const { canvasRef, canvasWidth, canvasHeight } =
        React.useContext(BadgeForgeContext)
    return (
        <CanvasWrapper>
            {children}
            <CircleCanvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />
        </CanvasWrapper>
    )
}

const CanvasWrapper = styled.div`
  position: relative;
  grid-area: canvas;
  box-sizing: border-box;
  width: 235px;
  height: 235px;
`

const CircleCanvas = styled.canvas`
  position: relative;
  border: solid 5px white;
  border-radius: 50%;
  box-shadow: ${Theme.effects.canvasShadow};
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  z-index: 0;
  font-family: ${Theme.font.family.cocoGothic};
`