import React from "react"
import { ChangeEvent, MouseEvent, useContext, useState } from "react"
import styled from "styled-components"
import { BadgeForgeContext } from "../contexts/BadgeForgeContext"
import { labelPresets } from "../lib/presets/labelPresets"
import { ReactComponent as CaretRoundedDown } from "../static/images/icons/caret-rounded-down.svg"
import { ParamLabelWrapper, RowDiv } from "./Containers"
import { Theme } from "../../profilepictureeditor/theme/theme"
interface OptionProps {
    option: string
    onClick?: () => void
}

const DropdownOption = ({ option, onClick }: OptionProps) => {
    return <OptionDiv onClick={onClick && onClick}>{option}</OptionDiv>
}

export const TextLabel = () => {
    const { label, setLabel } = useContext(BadgeForgeContext)
    const [isDropdownOpen, setDropdownOpen] = useState(false)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const validLength = e?.target.value.length <= 15
        if (validLength) {
            setLabel(e?.target.value)
        }
    }

    const toggleDropdown = (e: MouseEvent) => {
        e.stopPropagation()
        setDropdownOpen((isOpen) => !isOpen)
    }

    return (
        <DropdownWrapper>
            <ParamLabelWrapper>Enter your label text:</ParamLabelWrapper>
            <DropdownBox
                onClick={toggleDropdown}
                title="type your own text or select from the presets"
                $showAlert={label.length >= 15}
            >
                <LabelInput
                    type="text"
                    value={label}
                    placeholder="15 characters max."
                    onChange={handleChange}
                    maxLength={15}
                />
                <ArrowDiv
                    onClick={toggleDropdown}
                    $direction={isDropdownOpen ? "down" : "up"}
                >
                    <CaretRoundedDown />
                </ArrowDiv>
            </DropdownBox>
            <DropdownOptions onClick={toggleDropdown} $isOpen={isDropdownOpen}>
                {labelPresets.map((preset) => (
                    <DropdownOption
                        key={preset}
                        option={preset}
                        onClick={() => setLabel(preset)}
                    />
                ))}
                <ModalBlocker onClick={toggleDropdown} />
            </DropdownOptions>
        </DropdownWrapper>
    )
}

const DropdownWrapper = styled(RowDiv)`
  grid-area: label;
  grid-template-areas: "info" "input" "options";
`

const ModalBlocker = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 98;
`
interface DropdownOptionsProps {
    $isOpen?: boolean
}
const DropdownOptions = styled.div<DropdownOptionsProps>`
  display: ${({ $isOpen }) => ($isOpen ? "grid" : "none")};
  position: absolute;
  overflow: auto;
  grid-area: options;
  grid-template-rows: repeat(
    auto-fit,
    ${Theme.dimensions.boxHeight};
  )
  align-items: center;
  width: 100%;
  height: auto;
  max-height: 200px;
  z-index: 98;
  background-color: ${Theme.colors.gray50};
  margin-top: ${`-${Theme.spacing.xxxs}`};
  box-shadow: ${Theme.effects.boxShadow};
`

const OptionDiv = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: ${Theme.dimensions.boxHeight};
  font-size: ${Theme.font.size.h3};
  font-weight: ${Theme.font.weight.heavy};
  padding-left: ${Theme.spacing.s};
  user-select: none;
  z-index: 100;
  &:hover {
    background-color: ${Theme.colors.gray60};
  }
`

interface ArrowDivProps {
    $direction?: "down" | "up"
}
const ArrowDiv = styled.div<ArrowDivProps>`
  box-sizing: content-box;
  grid-area: arrow;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &:hover {
    background-color: ${Theme.colors.gray60};
  }
  transform: ${({ $direction }) =>
        $direction === "up" ? "rotate(0deg)" : "rotate(180deg)"};
`

interface DropdownBoxProps {
    $showAlert?: boolean
}
const DropdownBox = styled.div<DropdownBoxProps>`
  display: grid;
  grid-template-columns: 90% 10%;
  grid-template-areas: "label arrow";
  align-items: center;
  height: ${Theme.dimensions.boxHeight};
  background-color: ${Theme.colors.gray50};
  z-index: 100;
  box-sizing: content-box;
  cursor: pointer;
  border: ${({ $showAlert }) =>
        $showAlert ? Theme.borders.alert : Theme.borders.regular};
  &:focus-within {
    border: ${Theme.borders.focus};
  }
`

const LabelInput = styled.input`
  height: ${Theme.dimensions.boxHeight};
  padding: ${Theme.spacing.s};
  font-size: ${Theme.font.size.h3};
  font-weight: ${Theme.font.weight.regular};
  box-sizing: border-box;
  border: none;
  grid-area: label;
  width: 100%;
  margin: ${`-${Theme.spacing.xxxxs}`};
  background-color: transparent;
  &:focus {
    outline-width: 0;
  }
  &::placeholder {
    color: ${Theme.colors.gray200};
  }
`