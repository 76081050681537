import React from "react"
import { Form } from "react-bootstrap"

interface CheckBoxProps {
    isChecked: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    label: string
}

const CheckBox = (props: CheckBoxProps) => {
    return (
        <div>
            <Form.Check
                type="checkbox"
                id={props.label}
                checked={props.isChecked}
                onChange={props.handleChange}
                className="me-1"
                label={props.label}
            />  
        </div>
    )
}

export default CheckBox
